import "@google/model-viewer/dist/model-viewer";
import { Suspense, useEffect, useRef, useState } from "react";
import { useDriveDownloadLink } from "../../hooks/common.hooks";
import { handleDriveLinks } from "../../services";
import styles from "../../styles/Sites/FileViewer.module.scss";
import { CrossIcon, DownloadIcon, FileIcon, FullScreenIcon } from "../Icons";

export default function FileViewer({
  type,
  file,
  download = true,
  className,
}: {
  type:
    | "HTML"
    | "DOCX"
    | "JPG"
    | "PNG"
    | "MP3"
    | "MP4"
    | "PDF"
    | "APK"
    | "GLB"
    | "ZIP";
  file: string;
  download?: boolean;
  className?: string;
}): JSX.Element {
  const fileRef = useRef<HTMLDivElement>(null);
  const iframeRef = useRef<HTMLIFrameElement[]>([]);
  const anchorRef = useRef<HTMLAnchorElement>(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const { data: downloadLink, isLoading: isLinkLoading } = useDriveDownloadLink(
    file,
    type === "GLB"
  );

  const handleFullScreen = () => {
    const file = fileRef.current;
    if (file) {
      if (isFullScreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
          setIsFullScreen(false);
          file.classList.remove(styles.fullscreen_file);
        }
      } else {
        if (file.requestFullscreen) {
          file.requestFullscreen();
          setIsFullScreen(true);
          file.classList.add(styles.fullscreen_file);
          console.log(file.classList);
        }
      }
    }
  };

  const handleHTMLDownload = (html: string) => {
    const anchor = anchorRef.current;
    if (anchor) {
      const blob = new Blob([html], { type: "text/html" });
      const url = URL.createObjectURL(blob);
      anchor.href = url;
      anchor.download = "index.html";
    }
  };

  useEffect(() => {
    let _iframeRef = iframeRef;

    const iframes = _iframeRef.current;
    iframes?.forEach((iframe) => {
      iframe?.addEventListener("load", () => {
        if (iframe.contentWindow) {
          iframe.style.height =
            iframe.contentWindow.document.body?.scrollHeight + 35 + "px";
        }
      });
    });

    return () => {
      const iframes = _iframeRef.current;
      iframes?.forEach((iframe) => {
        iframe?.removeEventListener("load", () => {
          if (iframe.contentWindow) {
            iframe.style.height =
              iframe.contentWindow.document.body?.scrollHeight + 35 + "px";
          }
        });
      });
    };
  }, [iframeRef]);

  useEffect(() => {
    const file = fileRef.current;

    function onFullscreenChange() {
      setIsFullScreen(Boolean(document.fullscreenElement));
      if (file) {
        if (document.fullscreenElement) {
          file.classList.add(styles.fullscreen_file);
        } else {
          file.classList.remove(styles.fullscreen_file);
        }
      }
    }

    if (file) {
      file.addEventListener("fullscreenchange", onFullscreenChange);
    }

    document.addEventListener("fullscreenchange", onFullscreenChange);

    return () => {
      if (file) {
        file.removeEventListener("fullscreenchange", onFullscreenChange);
      }

      document.removeEventListener("fullscreenchange", onFullscreenChange);
    };
  }, []);

  const viewer = () => {
    switch (type) {
      case "HTML":
        return (
          <iframe
            ref={(el) => (iframeRef.current[0] = el as HTMLIFrameElement)}
            srcDoc={file}
            title="HTML Attachment"
            allow="allowfullscreen"
          />
        );
      case "DOCX":
        return (
          <a
            href={handleDriveLinks(file)}
            download={handleDriveLinks(file)}
            className={styles.anchor}
            target="_blank"
            rel="noreferrer"
          >
            <div>
              <FileIcon />
              <span>Download DOC</span>
            </div>
            <DownloadIcon />
          </a>
        );
      case "JPG":
      case "PNG":
        return <img src={handleDriveLinks(file)} alt="Attachment" />;
      case "MP3":
        return (
          <audio controls controlsList="nodownload">
            <source src={handleDriveLinks(file)} type="audio/mpeg" />
          </audio>
        );
      case "MP4":
        if (file.includes("youtube.com")) {
          const id = file.split("v=")[1];
          return (
            <iframe
              className={styles.video_iframe}
              src={`https://www.youtube.com/embed/${id}`}
              title="YouTube video player"
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture; allowfullscreen"
            ></iframe>
          );
        }
        if (file.includes("drive.google.com")) {
          const driveUrl = new URL(file);
          const isFile = driveUrl.pathname.split("/")[1] === "file";
          if (isFile) {
            const driveId = driveUrl.pathname.split("/")[3];
            return (
              <iframe
                className={styles.video_iframe}
                src={`https://drive.google.com/file/d/${driveId}/preview`}
                title="Google Drive video player"
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture; allowfullscreen"
              ></iframe>
            );
          }
        }
        return (
          <video controls controlsList="nodownload">
            <source src={file} type="video/mp4" />
          </video>
        );
      case "PDF":
        return (
          <embed
            src={`${handleDriveLinks(file)}${"#toolbar=0"}`}
            type="application/pdf"
          />
        );
      case "APK":
      case "ZIP":
        return (
          <a
            href={handleDriveLinks(file)}
            download={handleDriveLinks(file)}
            className={styles.anchor}
            target="_blank"
            rel="noreferrer"
          >
            <div>
              <FileIcon />
              <span>Download {type === "APK" ? "APK File" : "ZIP File"}</span>
            </div>
            <DownloadIcon />
          </a>
        );
      case "GLB":
        return (
          <Suspense fallback={<p>Loading...</p>}>
            <model-viewer
              src={downloadLink?.data || file}
              auto-rotate
              id="model"
              align-model="origin"
              auto-play
              camera-controls
              touch-action="pan-y"
              shadow-intensity="1"
              alt="3D Model Viewer"
            ></model-viewer>
          </Suspense>
        );
      default:
        return <p>Unsupported file format: {type}</p>;
    }
  };

  return (
    <div
      className={`${styles.file} ${type === "GLB" ? styles.bg_black : ""} ${
        className ? className : ""
      }`}
      ref={fileRef}
    >
      {isLinkLoading ? (
        <p>Loading Preview...</p>
      ) : (
        <>
          <div className={styles.controls}>
            {type === "APK" || type === "ZIP" || type === "DOCX" ? (
              <></>
            ) : (
              <i
                className={styles.icon}
                onClick={handleFullScreen}
                title={isFullScreen ? "Exit Fullscreen" : "Fullscreen"}
              >
                {isFullScreen ? <CrossIcon /> : <FullScreenIcon />}
              </i>
            )}
            {download && type !== "APK" && type !== "ZIP" && type !== "DOCX" ? (
              <a
                title="Download"
                href={handleDriveLinks(file, true)}
                download={handleDriveLinks(file, true)}
                onClick={
                  type === "HTML" ? () => handleHTMLDownload(file) : () => {}
                }
                className={styles.icon}
                ref={anchorRef}
              >
                <DownloadIcon />
              </a>
            ) : (
              <></>
            )}
          </div>
          {viewer()}
        </>
      )}
    </div>
  );
}
