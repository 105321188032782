import { BigNumber, ContractTransaction, Signer } from "ethers";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { UseMutationResult, useMutation } from "react-query";
import { toast } from "react-toastify";
import {
  useAccount,
  useContract,
  useNetwork,
  useSigner,
  useSwitchNetwork,
} from "wagmi";
import ERC20_abi from "../config/abi/ERC20.json";
import GateRegistry_abi from "../config/abi/GateRegistry.json";
import { Contracts } from "../config/data";
import {
  getBalance,
  getUserTierData,
  mintGate,
  promoteGate,
} from "../services";
import {
  GatePaymentArgs,
  NODE_ENV,
  PAYMENT_TYPE,
  PromoteArgs,
  Props,
  TYPES,
  UserTierData,
  WalletContext,
} from "../types";
import { ERC20, GateRegistry } from "../types/contracts";

const gateRegistryContractData =
  Contracts[process.env.REACT_APP_NODE_ENV as NODE_ENV][TYPES.GATE_REGISTRY][
    "eth"
  ];

const gatrTokenContractData =
  Contracts[process.env.REACT_APP_NODE_ENV as NODE_ENV][TYPES.GATR_TOKEN][
    "eth"
  ];

const initialContext = {
  isConnected: false,
  isConnecting: false,
  address: "",
  chainId: 0,
  userTierData: undefined,
  gatrBalance: BigNumber.from(0),
  signer: undefined,
  useGate: undefined,
  usePromoteGate: undefined,
  gateRegistryContract: undefined,
  gatrContract: undefined,
};

const walletContext = createContext<WalletContext>(initialContext);

const WalletProvider = ({ children }: Props): JSX.Element => {
  const { Provider } = walletContext;
  const { address, isConnected, isConnecting } = useAccount();
  const { chain } = useNetwork();
  const { data: signer, isSuccess } = useSigner({
    chainId: gateRegistryContractData.id,
  });
  const { switchNetwork } = useSwitchNetwork({
    chainId: gateRegistryContractData.id,
  });

  const gateRegistryContract = useContract({
    address: gateRegistryContractData.contract,
    abi: GateRegistry_abi,
    signerOrProvider: isSuccess ? signer : undefined,
  }) as GateRegistry;

  const gatrContract = useContract({
    address: gatrTokenContractData.contract,
    abi: ERC20_abi,
    signerOrProvider: isSuccess ? signer : undefined,
  }) as ERC20;

  const [gatrBalance, setGatrBalance] = useState<BigNumber>(BigNumber.from(0));

  const [userTierData, setUserTierData] = useState<UserTierData | undefined>({
    tier: "Ineligible",
    gatePrice: "0",
    gateLimit: 0,
    promotionPrice: "0",
  });

  const useGate: UseMutationResult<
    ContractTransaction | undefined,
    any,
    { payload: GatePaymentArgs; type: PAYMENT_TYPE },
    any
  > = useMutation(
    async ({ type, payload }) => {
      try {
        if (!signer) throw new Error("Wallet is not connected");
        if (!gateRegistryContract)
          throw new Error("Gate Registry is not available");
        if (!gatrContract) throw new Error("ERC20 is not available");
        if (!address) throw new Error("Address is not available");
        if (!userTierData) throw new Error("User Data is not available");

        const checkAllowance = await gatrContract.callStatic.allowance(
          address,
          gateRegistryContract.address
        );

        const gatePrice = userTierData.gatePrice.toString();

        if (checkAllowance.lt(gatePrice)) {
          const approveTx = await gatrContract.approve(
            gateRegistryContract.address,
            gatePrice
          );
          await approveTx.wait(2);
        }

        return await mintGate(type, payload, gateRegistryContract);
      } catch (error: any) {
        throw error;
      }
    },
    {
      onError(error: any) {
        if (error?.reason) toast.error(error.reason, { autoClose: 5000 });
        else if (error?.response?.data)
          toast.error(error.response.data, { autoClose: 5000 });
        else if (error instanceof Error)
          toast.error(error?.message, { autoClose: 5000 });
        else {
          toast.error("Errors while registering. Please check and try again.", {
            autoClose: 5000,
          });
        }
        return error;
      },
    }
  );

  const usePromoteGate: UseMutationResult<
    ContractTransaction | undefined,
    any,
    { payload: PromoteArgs; type: PAYMENT_TYPE }
  > = useMutation(
    async (data) => {
      try {
        if (!signer) throw new Error("Wallet is not connected");
        if (!gateRegistryContract)
          throw new Error("Gate Registry is not available");
        if (!gatrContract) throw new Error("ERC20 is not available");
        if (!address) throw new Error("Address is not available");
        if (!userTierData) throw new Error("User Data is not available");

        const checkAllowance = await gatrContract.callStatic.allowance(
          address,
          gateRegistryContract.address
        );

        const promotionPrice = userTierData.promotionPrice.toString();

        if (checkAllowance.lt(promotionPrice)) {
          const approveTx = await gatrContract.approve(
            gateRegistryContract.address,
            promotionPrice
          );
          await approveTx.wait(2);
        }

        const { type, payload } = data;
        return await promoteGate(type, payload, gateRegistryContract);
      } catch (error: any) {
        throw error;
      }
    },
    {
      onError(error: any) {
        if (error?.reason) toast.error(error.reason, { autoClose: 5000 });
        else if (error?.response?.data)
          toast.error(error.response.data, { autoClose: 5000 });
        else if (error instanceof Error)
          toast.error(error?.message, { autoClose: 5000 });
        else {
          toast.error(
            "Errors while promoting gate. Please check and try again.",
            {
              autoClose: 5000,
            }
          );
        }
        return error;
      },
    }
  );

  const toastId: any = useRef(null);

  useEffect(() => {
    if (
      isConnected &&
      chain?.id !== gateRegistryContractData.id &&
      switchNetwork
    ) {
      toastId.current = toast.error("Please switch to the correct network", {
        autoClose: false,
      });
      switchNetwork();
    }
    if (chain?.id === gateRegistryContractData.id)
      toast.dismiss(toastId.current);
  }, [chain, isConnected, switchNetwork]);

  useEffect(() => {
    if (
      address &&
      signer &&
      gatrContract &&
      chain?.id === gateRegistryContractData.id
    ) {
      const getGatrBalance = async () => {
        const balance = await getBalance(`${address}`, gatrContract);
        setGatrBalance(balance);
      };

      getGatrBalance();
    }
  }, [signer, address, chain, gatrContract, useGate, usePromoteGate]);

  useEffect(() => {
    if (
      address &&
      signer &&
      gateRegistryContract &&
      chain?.id === gateRegistryContractData.id
    ) {
      const getTierData = async () => {
        const tierData = await getUserTierData(
          `${address}`,
          gateRegistryContract
        );
        setUserTierData(tierData);
      };
      getTierData();
    }
  }, [
    chain,
    address,
    gateRegistryContract,
    signer,
    useGate.status,
    usePromoteGate.status,
  ]);

  return (
    <Provider
      value={{
        isConnected,
        isConnecting,
        address,
        chainId: chain?.id,
        userTierData,
        gatrBalance,
        signer: signer ? (signer as Signer) : undefined,
        useGate,
        usePromoteGate,
        gateRegistryContract,
        gatrContract,
      }}
    >
      {children}
    </Provider>
  );
};

export default WalletProvider;

export const useWallet = () => useContext<WalletContext>(walletContext);
