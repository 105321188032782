import { useWeb3Modal } from "@web3modal/react";
import { useEffect, useState } from "react";
import { Link, Navigate, redirect, useNavigate } from "react-router-dom";
import { useAccount, useSignMessage } from "wagmi";
import { Button } from "../../components";
import { WalletIcon } from "../../components/Icons";
import { useAuth } from "../../context/user.context";
import {
  useGetRegisterMessage,
  useVerifyRegisterMessage,
} from "../../hooks/auth.hooks";
import styles from "../../styles/Auth/_Auth.module.scss";
import { useWallet } from "../../context/web3.context";

export default function Login(): JSX.Element {
  const { isConnected } = useWallet();
  const { isAuthenticated } = useAuth();
  const [loading, setLoading] = useState(false);
  const { address } = useAccount();
  const { isLoading, signMessageAsync } = useSignMessage();
  const { getMessage } = useGetRegisterMessage();
  const { verifyMessage } = useVerifyRegisterMessage();
  const { open } = useWeb3Modal();

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (!address) {
        await open();
      }
      if (isConnected) {
        const message = await getMessage(address as string);
        const signature = await signMessageAsync({ message: message?.data });
        await verifyMessage({
          signature: signature,
          address: address,
        });
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.box}>
      <div className={styles.body}>
        <h1>SIGN IN</h1>
        <Button
          type="submit"
          onClick={handleSubmit}
          disabled={isLoading || loading || (isAuthenticated ? true : false)}
          className={styles.login_button}
        >

          {isConnected ?
            isAuthenticated ? (
              <Navigate to="/gates" />
            ) : (
              <>
                <WalletIcon /> {loading ? "Verifying..." : "VERIFY WALLET"}
              </>
            ) : "CONNECT WALLET"}
        </Button>
      </div>
      <div className={styles.sign_link}>
        {!isAuthenticated && (
          <>
            Not a member yet? <Link to="/auth/sign-up">Sign Up!</Link>
          </>
        )}
      </div>
    </div>
  );
}
