import styles from "../../styles/Forms/Forms.module.scss";

export default function RadioInput(props: any): JSX.Element {
  const { className, loading, disabled, innerRef, error, children, ...rest } =
    props;
  return (
    <div className={`${styles.multi_input_group} ${className || ""}`}>
      <label htmlFor={rest?.name} aria-disabled={disabled || loading}>
        <div className={styles.label}>
          {rest?.label && <p>{rest.label}</p>}
          {rest?.icon && <i>{rest?.icon}</i>}
        </div>
      </label>
      <div>{children}</div>
    </div>
  );
}
