import { Outlet } from "react-router-dom";
import Gatr from "../../assets/images/0xgatr.png";
import styles from "../../styles/Auth/Auth.module.scss";
import Login from "./Login";
import SignUp from "./SignUp";

export default function Auth(): JSX.Element {
  return (
    <section className={styles.section}>
      <div className={styles.auth_gradient}></div>
      <div className={styles.container}>
        <div className={styles.auth_box}>
          <Outlet />
        </div>
        <img src={Gatr} alt="0xGATR" className={styles.auth_image} />
      </div>
    </section>
  );
}

export { Login, SignUp };
