import styles from "../../styles/Forms/Forms.module.scss";

export function FormWrapper(props: any): JSX.Element {
  const { children, className, innerRef, ...rest } = props;

  return (
    <form
      className={`${styles.form} ${className || ""}`}
      {...rest}
      ref={innerRef && innerRef}
    >
      {children}
    </form>
  );
}

export { default as Button } from "./Button";
export { default as Dropdown } from "./Dropdown";
export { default as Input } from "./Input";
export { default as InputWithChildren } from "./InputWithChildren";
export { default as InputWithPreview } from "./InputWithPreview";
export { default as NoteBox } from "./NoteBox";
export { default as RadioInput } from "./RadioInput";
