import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getRegisterMessage, verifyRegisterMessage } from "../api";
import { useAuth } from "../context/user.context";

export function useGetRegisterMessage(query?: string) {
    const { mutateAsync } = useMutation((address: string) => getRegisterMessage(address, query), {
        onError(error: any) {
            if (error?.response?.data) toast.error(error.response.data, { autoClose: 5000 })
            else if (error instanceof Error) toast.error(error.message, { autoClose: 5000 })
            else {
                toast.error('Errors while registering. Please check and try again.', { autoClose: 5000 })
            }
        },
    });
    return { getMessage: mutateAsync };
};

export function useVerifyRegisterMessage() {
    const navigate = useNavigate();

    const { login } = useAuth();
    const { mutateAsync } = useMutation(verifyRegisterMessage, {
        onSuccess(data) {
            login({
                token: data.data.token,
                role: data.data.role,
            })
            toast.success('Registered Successfully!', { autoClose: 5000 })
            navigate(-1)
        },
        onError(error: any) {
            if (error?.response?.data) toast.error(error.response.data, { autoClose: 5000 })
            else if (error instanceof Error) toast.error(error.message, { autoClose: 5000 })
            else {
                toast.error('Errors while verifying request. Please check and try again.', { autoClose: 5000 })
            }
        },
    });
    return { verifyMessage: mutateAsync };
};
