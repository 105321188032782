import { AxiosResponse } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { getGateById, getGates, getMyGates, updateGateById } from "../api";
import { GateResponse, GateUpdateRequest, GatesResponse } from "../types";

export function useGetGateById(gate_id: string) {
    const query = useQuery<AxiosResponse<GateResponse>, Error>(['gate', gate_id], () => getGateById(gate_id), {
        onError(error: any) {
            if (error?.response?.data) toast.error(error.response.data, { autoClose: 5000 })
            else if (error instanceof Error) toast.error(error?.message, { autoClose: 5000 })
            else {
                toast.error('Errors while fetching gate. Please check and try again.', { autoClose: 5000 })
            }
            return error;
        },
        enabled: !!gate_id,
        retry: 3,
        refetchOnWindowFocus: false,
    })

    return query;
}

export function useUpdateGateById() {
    const queryClient = useQueryClient();

    const query = useMutation<AxiosResponse<GateUpdateRequest | Error>, Error, GateUpdateRequest & { gate_id: string; }, any>(async (data) => {
        try {
            const gate_id = data.gate_id;
            return await updateGateById(gate_id, data);
        } catch (error) {
            throw error;
        }
    }, {
        onSuccess() {
            queryClient.invalidateQueries('gates');
        },
        onError(error: any) {
            if (error?.response?.data) toast.error(error.response.data, { autoClose: 5000 })
            else if (error instanceof Error) toast.error(error?.message, { autoClose: 5000 })
            else {
                toast.error('Errors while updating gate. Please check and try again.', { autoClose: 5000 })
            }
            return error;
        },
        retry: false,
    },);

    return query;
}

export function useGetMyGates(params:any, isLoading:any) {
    const query = useQuery<AxiosResponse<GatesResponse>, Error>(['gates',params, isLoading], () => getMyGates(params), {
        onError(error: any) {
            if (error?.response?.data) toast.error(error.response.data, { autoClose: 5000 })
            else if (error instanceof Error) toast.error(error?.message, { autoClose: 5000 })
            else {
                toast.error('Errors while fetching gates. Please check and try again.', { autoClose: 5000 })
            }
            return error;
        },
        staleTime: 1000 * 10,
        retry: 3,
        refetchOnWindowFocus: false,
    })

    return query;
}

export function useGetGates(params:any, isLoading:any) {
    const query = useQuery<AxiosResponse<GatesResponse>, Error>(['gates',params, isLoading], () => getGates(params), {
        onError(error: any) {
            if (error?.response?.data) toast.error(error.response.data, { autoClose: 5000 })
            else if (error instanceof Error) toast.error(error?.message, { autoClose: 5000 })
            else {
                toast.error('Errors while fetching gates. Please check and try again.', { autoClose: 5000 })
            }
            return error;
        },
        staleTime: 1000 * 60,
        retry: 3,
        refetchOnWindowFocus: false,
    })

    return query;
}
