import { useWeb3Modal } from "@web3modal/react";
import { FormEvent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAccount, useSignMessage } from "wagmi";
import { Button, Input } from "../../components";
import { EmailIcon, WalletIcon } from "../../components/Icons";
import {
  useGetRegisterMessage,
  useVerifyRegisterMessage,
} from "../../hooks/auth.hooks";
import styles from "../../styles/Auth/_Auth.module.scss";

export default function Login(): JSX.Element {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { address } = useAccount();
  const { isLoading, signMessageAsync } = useSignMessage();
  const { getMessage } = useGetRegisterMessage("sign-up");
  const { verifyMessage } = useVerifyRegisterMessage();
  const { open } = useWeb3Modal();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      setLoading(true);
      const { email } = event.currentTarget.elements as any;
      const message = await getMessage(address as string);
      const signature = await signMessageAsync({ message: message?.data });
      await verifyMessage({
        email: email.value,
        signature: signature,
        address: address,
      });
      navigate("/gates");
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const connectWallet = async () => {
    try {
      await open();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={styles.box}>
      <form className={styles.body} onSubmit={handleSubmit}>
        <h1>SIGN UP</h1>
        <Input
          id="email"
          type="email"
          name="email"
          placeholder="EMAIL"
          icon={<EmailIcon />}
          className={styles.email_input}
          required
        />
        <div className={styles.connect_wallet}>
          <label htmlFor="wallet_connect">
            <WalletIcon />
          </label>
          <button type="button" onClick={connectWallet}>
            {address ? address : "CONNECT"}
          </button>
        </div>
        <div className={styles.agreement}>
          <input
            type="checkbox"
            name="agreement"
            id="agreement"
            required
          ></input>
          <label htmlFor="agreement">
            I have read and agree to 0xgatr{" "}
            <Link
              target="_blank"
              rel="noreferrer"
              to={`${process.env.REACT_APP_HOME_URL}/terms-and-use`}
            >
              Terms of Use
            </Link>
            .
          </label>
        </div>
        <Button type="submit" disabled={isLoading}>
          {loading ? "Sending..." : "Register"}
        </Button>
      </form>
      <div className={styles.sign_link}>
        Already a member? <Link to="/auth">Sign In!</Link>
      </div>
    </div>
  );
}
