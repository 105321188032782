import { AxiosResponse } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createSite, getSiteByGate } from "../api";
import { SiteRequest, SiteResponse } from "../types";

export function useSiteByGate(gate_id: string) {
    const queryClient = useQueryClient();
    const redirect = useNavigate();

    const query = useQuery<AxiosResponse<SiteResponse>, Error>(['site', gate_id], () => getSiteByGate(gate_id), {
        onSuccess() {
            queryClient.invalidateQueries('gates');
        },
        onError(error: any) {
            if (error?.reason) toast.error(error.reason, { autoClose: 5000 })
            else if (error?.response?.status === 404) return;
            else if (error?.response?.data) {
                toast.error(error.response.data, { autoClose: 5000 })
                if (error.response.status === 403) redirect('/gates');
            }
            else if (error instanceof Error) toast.error(error?.message, { autoClose: 5000 })
            else {
                toast.error('Errors while fetching site data. Please check and try again.', { autoClose: 5000 })
            }
            return error;
        },
        enabled: !!gate_id,
        staleTime: 1000 * 10,
        refetchOnWindowFocus: false,
        retry: 3,
    })

    return query;
}

export function useUpdateSite(gate_id: string) {
    const queryClient = useQueryClient();

    const query = useMutation<AxiosResponse<any | Error>, Error, SiteRequest & { action?: "publish" | "unpublish" }, any>(async (data) => {
        try {
            return await createSite(gate_id, data);
        } catch (error) {
            throw error;
        }
    }, {
        onSuccess() {
            queryClient.invalidateQueries(['site', gate_id]);
        },
        onError(error: any) {
            if (error?.response?.data) toast.error(error.response.data, { autoClose: 5000 })
            else if (error instanceof Error) toast.error(error?.message, { autoClose: 5000 })
            else {
                toast.error('Errors while updating site. Please check and try again.', { autoClose: 5000 })
            }
            return error;
        },
        retry: false,
    });
    return query;
}
