import { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import Logo from "../assets/images/navbarLogo.png";
import { useWallet } from "../context/web3.context";
import styles from "../styles/Navbar.module.scss";
import { CrossIcon, DollarIcon, GatrIcon, MenuIcon } from "./Icons";
import ButtonWithTier from "./ButtonNTier";
import axios from 'axios';
import { useAuth } from "../context/user.context";



export default function Navbar() {

  const coinGekkoApi = 'https://api.coingecko.com/api/v3/simple/price?ids=medicle&vs_currencies=usd';
  const [tokenPrice,setTokenPrice] = useState("0.00");
  const { pathname } = useLocation();
  const { userTierData,isConnected,address,gatrBalance } = useWallet();
  const { isAuthenticated,logout } = useAuth();

  const role = pathname.includes("admin")?"admin":"user";

  useEffect(() => {
    const mobile_nav = document.getElementById("mobile_nav");
    const open_button = document.getElementById("open_button");
    const close_button = document.getElementById("close_button");

    open_button?.addEventListener("click", () => {
      openNav(mobile_nav, open_button, close_button);
    });

    close_button?.addEventListener("click", () => {
      closeNav(mobile_nav, open_button, close_button);
    });

    async function getTokenPrice() {
      try{
          const response = await axios.get(coinGekkoApi);
          setTokenPrice((BigInt(response?.data.medicle.usd*10**18)).toString())
        } catch(err){
          console.log(err);
      }
  }
    getTokenPrice();

    return () => {
      open_button?.removeEventListener("click", () => {
        openNav(mobile_nav, open_button, close_button);
      });

      close_button?.removeEventListener("click", () => {
        closeNav(mobile_nav, open_button, close_button);
      });
    };
  }, []);

  const closeNavOnScroll = () => {
    const mobile_nav = document.getElementById("mobile_nav");
    const open_button = document.getElementById("open_button");
    const close_button = document.getElementById("close_button");

    closeNav(mobile_nav, open_button, close_button);
  };

  const openNav = (mobile_nav: any, open_button: any, close_button: any) => {
    mobile_nav.classList.remove("hide");
    open_button.classList.add("hide");
    close_button.classList.remove("hide");
  };

  const closeNav = (mobile_nav: any, open_button: any, close_button: any) => {
    mobile_nav.classList.add("hide");
    open_button.classList.remove("hide");
    close_button.classList.add("hide");
  };

  return (
    <nav className={`${styles.header}`}>
      <div className={styles.container}>
        <Link to={`${process.env.REACT_APP_HOME_URL}/#hero`}>
          <img src={Logo} alt="0xgator" className={styles.navbar_logo} />
        </Link>
       {isAuthenticated===false ?  <ul className={`${styles.list}`}>
          <li>
            <Link to={`${process.env.REACT_APP_HOME_URL}/#about`}>ABOUT</Link>
          </li>
          <li>
            <Link to={`${process.env.REACT_APP_HOME_URL}/featured`}>FEATURED GATES</Link>
          </li>
          <li>
            <Link to="https://n4cz4diqeujmu4opnvwcrqe32lanaw2p72rgfqirkjjfgnbidl2q.arweave.net/bwWeDRAlEspxz21sKMCb0sDQW0_-omLBEVJSUzQoGvU" target='_blank' rel='noopener noreferrer'>SWAMP PAPER</Link>
          </li>
          <li>
            <Link to="https://medium.com/@Support_11472" target='_blank' rel='noopener noreferrer'>NEWS</Link>
          </li>
        </ul> :
          role==='user'?
          <ul className={`${styles.list}`}>
          <li>
            <Link to="/gates">GATES</Link>
          </li>
          <li>
          {/* <Link to="/promotions">PROMOTIONS</Link> */}
          <Link to="/promotions">PROMOTIONS</Link>
          </li>
          <li>
          <Link to={`${process.env.REACT_APP_HOME_URL}/featured`}>FEATURED GATES</Link>
          </li>
        </ul>:
        <ul className={`${styles.list}`}>
          <li>
            <Link to="/admin/contract">CONTRACT</Link>
          </li>
          <li>
            <Link to="/admin/gates">GATES</Link>
          </li>
          <li>
          <Link to="/admin/users">USERS</Link>
          </li>
          <li>
          <Link to="/admin/promotions">PROMOTIONS</Link>
          </li>
          <li>
          <Link to="/admin/complaints">COMPLAINTS</Link>
          </li>
        </ul>
        }

        { isAuthenticated===true? <div className={styles.walletDetails}>
          <div className={styles.block}>
            <GatrIcon />
            <div>
              <p className={styles.key}>Balance</p>
              <p className={styles.value}>{(parseInt(gatrBalance._hex)/(10**18))}</p>
            </div>
          </div>
          <div className={styles.block}>
            <DollarIcon />
            <div>
              <p className={styles.key}>Price</p>
              <p className={styles.value}>${((parseFloat(tokenPrice)*parseInt(gatrBalance._hex))/10**36).toFixed(2)}</p>
            </div>
          </div>
            <ButtonWithTier tier={userTierData?.tier} disabled={!isConnected} onClick={()=> logout()} className={styles.tierButton} value={address?.substring(0,4)+'...'+address?.substring(address.length-3)} />
        </div> :

        <button className={styles.connect}>{isConnected? `${address?.substring(0,4)+'...'+address?.substring(address.length-3)}`: `DAPP`}</button>}
        <button id="open_button" className={styles.open}>
          <MenuIcon />
        </button>
        <div id="mobile_nav" className={`${styles.mobile_navbar} hide`}>
          <button id="close_button" className={`${styles.close} hide`}>
            <CrossIcon />
          </button>

          {isAuthenticated===false ?  <ul className={`${styles.mobile_list}`}>
          <li>
            <Link to={`${process.env.REACT_APP_HOME_URL}/#about`}>ABOUT</Link>
          </li>
          <li>
            <Link to={`${process.env.REACT_APP_HOME_URL}/featured`}>FEATURED GATES</Link>
          </li>
          <li>
            <Link to="https://n4cz4diqeujmu4opnvwcrqe32lanaw2p72rgfqirkjjfgnbidl2q.arweave.net/bwWeDRAlEspxz21sKMCb0sDQW0_-omLBEVJSUzQoGvU" target='_blank' rel='noopener noreferrer'>SWAMP PAPER</Link>
          </li>
          <li>
            <Link to="https://medium.com/@Support_11472" target='_blank' rel='noopener noreferrer'>NEWS</Link>
          </li>
        </ul> :
        role==="user"?
        <ul className={`${styles.mobile_list}`}>
        <li>
            <Link to="/gates">GATES</Link>
          </li>
          <li>
          <Link to="/promotions">PROMOTIONS</Link>
          </li>
          <li>
          <Link to={`${process.env.REACT_APP_HOME_URL}/featured`}>FEATURED GATES</Link>
          </li>
      </ul>:
      <ul className={`${styles.mobile_list}`}>
      <li>
            <Link to="/admin/contract">CONTRACT</Link>
          </li>
          <li>
            <Link to="/admin/gates">GATES</Link>
          </li>
          <li>
          <Link to="/admin/users">USERS</Link>
          </li>
          <li>
          <Link to="/admin/promotions">PROMOTIONS</Link>
          </li>
          <li>
          <Link to="/admin/complaints">COMPLAINTS</Link>
          </li>
          <li>
          <Link to="/admin/promotions">PROMOTIONS</Link>
          </li>
      </ul>}

        { isAuthenticated===true ? <div className={styles.walletDetailsMobile}>
          <div className={styles.block}>
            <GatrIcon />
            <div>
              <p className={styles.key}>Balance</p>
              <p className={styles.value}>{parseInt(gatrBalance._hex.toString())/(10**18)}</p>
            </div>
          </div>
          <div className={styles.block}>
            <DollarIcon />
            <div>
              <p className={styles.key}>Price</p>
              <p className={styles.value}>${((parseFloat(tokenPrice)*parseInt(gatrBalance._hex))/10**36).toFixed(2)}</p>
              </div>
          </div>
          <ButtonWithTier tier={userTierData?.tier} disabled={!isConnected} onClick={()=> logout()} className={styles.tierButton} value={address?.substring(0,4)+'...'+address?.substring(address.length-3)} />
        </div>:

        <button className={styles.mobile_connect}>DAPP</button>}
        </div>
      </div>
    </nav>
  );
}
