import { StarIcon } from "./Icons";

export default function ButtonWithTier(props: any): JSX.Element {
  const { tier, className, loading, disabled, ...rest } = props;
  return (
    <button
      className={`btnWithTier ${className || ""}`}
      {...rest}
      disabled={disabled || loading}
    >
      {props.tier === "Ineligible" && (
        <div className="tier ineligible">
          <span>Ineligible</span>
        </div>
      )}
      {props.tier === "Basic" && (
        <div className="tier basic">
          <span>Basic</span>
        </div>
      )}
      {props.tier === "Premium" && (
        <div className="tier premium">
          <StarIcon />
          <span>Premium</span>
          <StarIcon />
        </div>
      )}
      {props.tier === "Advanced" && (
        <div className="tier advanced">
          <StarIcon />
          <span>Advanced</span>
          <StarIcon />
        </div>
      )}
      {props.value}
    </button>
  );
}
