import { BigNumberish, ethers } from "ethers";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useWallet } from "../context/web3.context";
import { pause as _pause, unpause as _unpause, launch as _launch, setAdmin as _setAdmin, setAdminToken as _setAdminToken, setBlacklist as _setBlacklist, setBlacklistToken as _setBlacklistToken, setBuyTax as _setBuyTax, setExcludedFromFees as _setExcludedFromFees, setGatrUsd as _setGatrUsd, setMarketingWallet as _setMarketingWallet, setPrice as _setPrice, setSellTax as _setSellTax, setSwapThreshold as _setSwapThreshold, setTier as _setTier, setTiersGateLimit as _setTiersGateLimit, setViolatorToken as _setViolatorToken, swapFee as _swapFee, withdraw as _withdraw, withdrawLockedEth as _withdrawLockedEth, withdrawLockedToken as _withdrawLockedToken } from "../services";
import { SetAdminArgs, SetPriceArgs, SetTierArgs, SetTiersGateLimitArgs, WithdrawArgs } from "../types";

export function useGateRegistryContract() {
    const [isContractAvailable, setIsContractAvailable] = useState(false);
    const { gateRegistryContract } = useWallet();

    let { isLoading: pauseLoading, mutateAsync: pause } = useMutation(async () => {
        if (!gateRegistryContract) throw new Error('Gate Registry is not available');
        return await _pause(gateRegistryContract);
    }, {
        onError(error: any) {
            if (error.message) {
                toast.error("Setting Pause failed: " + error.message)
            }
            else {
                toast.error("Setting Pause failed, please try again!");
            }
            return error;
        }
    });

    let { isLoading: unpauseLoading, mutateAsync: unpause } = useMutation(async () => {
        if (!gateRegistryContract) throw new Error('Gate Registry is not available');
        return await _unpause(gateRegistryContract);
    }, {
        onError(error: any) {
            if (error.message) {
                toast.error("Setting Unpause failed: " + error.message)
            }
            else {
                toast.error("Setting Unpause failed, please try again!");
            }
            return error;
        }
    });

    let { isLoading: setAdminLoading, mutateAsync: setAdmin } = useMutation(async (payload: SetAdminArgs) => {
        if (!gateRegistryContract) throw new Error('Gate Registry is not available');

        const { address, isEnabled } = payload;

        if (!ethers.utils.isAddress(address)) throw new Error('Invalid address');

        return await _setAdmin(address, isEnabled, gateRegistryContract);
    }, {
        onError(error: any) {
            if (error.message) {
                toast.error("Setting Admin failed: " + error.message)
            }
            else {
                toast.error("Setting Admin failed, please try again!");
            }
            return error;
        }
    });

    let { isLoading: setBlacklistLoading, mutateAsync: setBlacklist } = useMutation(async (payload: SetAdminArgs) => {
        if (!gateRegistryContract) throw new Error('Gate Registry is not available');

        const { address, isEnabled } = payload;

        if (!ethers.utils.isAddress(address)) throw new Error('Invalid address');

        return await _setBlacklist(address, isEnabled, gateRegistryContract);
    }, {
        onError(error: any) {
            if (error.message) {
                toast.error("Blacklisting failed: " + error.message)
            }
            else {
                toast.error("Blacklisting failed, please try again!");
            }
            return error;
        }
    });

    let { isLoading: setGatrUsdLoading, mutateAsync: setGatrUsd } = useMutation(async (payload: { gatrUsd: BigNumberish }) => {
        if (!gateRegistryContract) throw new Error('Gate Registry is not available');

        const { gatrUsd } = payload;

        if (!gatrUsd || parseFloat(ethers.utils.formatEther(gatrUsd)) <= 0) throw new Error('Invalid amount');

        return await _setGatrUsd(gatrUsd, gateRegistryContract);
    }, {
        onError(error: any) {
            if (error.message) {
                toast.error("Setting GatrUsd failed: " + error.message)
            }
            else {
                toast.error("Setting GatrUsd failed, please try again!");
            }
            return error;
        }
    });

    let { isLoading: setTierLoading, mutateAsync: setTier } = useMutation(async (payload: SetTierArgs) => {
        if (!gateRegistryContract) throw new Error('Gate Registry is not available');

        const [basic, advanced, premium] = payload;

        return await _setTier([basic, advanced, premium], gateRegistryContract);
    }, {
        onError(error: any) {
            if (error.message) {
                toast.error("Setting Tier failed: " + error.message)
            }
            else {
                toast.error("Setting Tier failed, please try again!");
            }
            return error;
        }
    });

    let { isLoading: setTiersGateLimitLoading, mutateAsync: setTiersGateLimit } = useMutation(async (payload: SetTiersGateLimitArgs) => {
        if (!gateRegistryContract) throw new Error('Gate Registry is not available');

        const [basic, advanced, premium] = payload;

        return await _setTiersGateLimit([basic, advanced, premium], gateRegistryContract);
    }, {
        onError(error: any) {
            if (error.message) {
                toast.error("Setting Tiers Gate Limit failed: " + error.message)
            }
            else {
                toast.error("Setting Tiers Gate Limit failed, please try again!");
            }
            return error;
        }
    });

    let { isLoading: setPriceLoading, mutateAsync: setPrice } = useMutation(async (payload: SetPriceArgs) => {
        if (!gateRegistryContract) throw new Error('Gate Registry is not available');

        const [basic, advanced, premium, promotion] = payload;

        return await _setPrice([basic, advanced, premium], promotion, gateRegistryContract);
    }, {
        onError(error: any) {
            if (error.message) {
                toast.error("Setting Price failed: " + error.message)
            }
            else {
                toast.error("Setting Price failed, please try again!");
            }
            return error;
        }
    });

    let { isLoading: withdrawLoading, mutateAsync: withdraw } = useMutation(async (payload: WithdrawArgs) => {
        if (!gateRegistryContract) throw new Error('Gate Registry is not available');

        const [token, amount, wallet] = payload;

        return await _withdraw([token, amount, wallet], gateRegistryContract);
    }, {
        onError(error: any) {
            if (error.message) {
                toast.error("Withdraw failed: " + error.message)
            }
            else {
                toast.error("Withdraw failed, please try again!");
            }
            return error;
        }
    });

    useEffect(() => {
        if (gateRegistryContract) {
            setIsContractAvailable(true);
        }
    }, [gateRegistryContract]);

    return { isLoading: pauseLoading || unpauseLoading || setAdminLoading || setBlacklistLoading || setGatrUsdLoading || setPriceLoading || setTierLoading || setTiersGateLimitLoading || withdrawLoading, pause, unpause, setAdmin, setBlacklist, setGatrUsd, setTier, setTiersGateLimit, setPrice, withdraw, isContractAvailable };
}

export function useGatrTokenContract() {
    const [isTokenContractAvailable, setIsTokenContractAvailable] = useState(false);
    const { gatrContract } = useWallet();

    let { isLoading: setAdminLoading, mutateAsync: setAdminToken } = useMutation(async (payload: SetAdminArgs) => {
        if (!gatrContract) throw new Error('GatrToken contract is not available');

        const { address, isEnabled } = payload;

        if (!ethers.utils.isAddress(address)) throw new Error('Invalid address');

        return await _setAdminToken(address, isEnabled, gatrContract);
    }, {
        onError(error: any) {
            if (error.message) {
                toast.error("Setting Admin failed: " + error.message)
            }
            else {
                toast.error("Setting Admin failed, please try again!");
            }
            return error;
        }
    });

    let { isLoading: setBlacklistLoading, mutateAsync: setBlacklistToken } = useMutation(async (payload: SetAdminArgs) => {
        if (!gatrContract) throw new Error('GatrToken Contract is not available');

        const { address, isEnabled } = payload;

        if (!ethers.utils.isAddress(address)) throw new Error('Invalid address');

        return await _setBlacklistToken(address, isEnabled, gatrContract);
    }, {
        onError(error: any) {
            if (error.message) {
                toast.error("Blacklisting failed: " + error.message)
            }
            else {
                toast.error("Blacklisting failed, please try again!");
            }
            return error;
        }
    });

    let { isLoading: setViolationLoading, mutateAsync: setViolatorToken } = useMutation(async (payload: SetAdminArgs) => {
        if (!gatrContract) throw new Error('GatrToken contract is not available');

        const { address, isEnabled } = payload;

        if (!ethers.utils.isAddress(address)) throw new Error('Invalid address');

        return await _setViolatorToken(address, isEnabled, gatrContract);
    }, {
        onError(error: any) {
            if (error.message) {
                toast.error("Setting Violator failed: " + error.message)
            }
            else {
                toast.error("Setting Violator failed, please try again!");
            }
            return error;
        }
    });

    let { isLoading: setSwapThresholdLoading, mutateAsync: setSwapThreshold } = useMutation(async (payload: { swapThreshold: BigNumberish }) => {
        if (!gatrContract) throw new Error('GatrToken contract is not available');

        const { swapThreshold } = payload;

        return await _setSwapThreshold(swapThreshold, gatrContract);
    }, {
        onError(error: any) {
            if (error.message) {
                toast.error("Setting Swap Threshold failed: " + error.message)
            }
            else {
                toast.error("Setting Swap Threshold failed, please try again!");
            }
            return error;
        }
    });

    let { isLoading: setBuyTaxLoading, mutateAsync: setBuyTax } = useMutation(async (payload: { buyTax: number }) => {
        if (!gatrContract) throw new Error('GatrToken contract is not available');

        const { buyTax } = payload;

        return await _setBuyTax(buyTax, gatrContract);
    }, {
        onError(error: any) {
            if (error.message) {
                toast.error("Setting Buy Tax failed: " + error.message)
            }
            else {
                toast.error("Setting Buy Tax failed, please try again!");
            }
            return error;
        }
    });

    let { isLoading: setSellTaxLoading, mutateAsync: setSellTax } = useMutation(async (payload: { sellTax: number }) => {
        if (!gatrContract) throw new Error('GatrToken contract is not available');

        const { sellTax } = payload;

        return await _setSellTax(sellTax, gatrContract);
    }, {
        onError(error: any) {
            if (error.message) {
                toast.error("Setting Sell Tax failed: " + error.message)
            }
            else {
                toast.error("Setting Sell Tax failed, please try again!");
            }
            return error;
        }
    });

    let { isLoading: setMarketingWalletLoading, mutateAsync: setMarketingWallet } = useMutation(async (payload: { marketingWallet: string }) => {
        if (!gatrContract) throw new Error('GatrToken contract is not available');

        const { marketingWallet } = payload;

        return await _setMarketingWallet(marketingWallet, gatrContract);
    }, {
        onError(error: any) {
            if (error.message) {
                toast.error("Setting Marketing Wallet failed: " + error.message)
            }
            else {
                toast.error("Setting Marketing Wallet failed, please try again!");
            }
            return error;
        }
    });

    let { isLoading: setExcludedFromFeesLoading, mutateAsync: setExcludedFromFees } = useMutation(async (payload: { wallet: string, isExcluded: boolean }) => {
        if (!gatrContract) throw new Error('GatrToken contract is not available');

        const { wallet, isExcluded } = payload;

        return await _setExcludedFromFees(wallet, isExcluded, gatrContract);
    }, {
        onError(error: any) {
            if (error.message) {
                toast.error("Excluding from Fees failed: " + error.message)
            }
            else {
                toast.error("Excluding from Fees failed, please try again!");
            }
            return error;
        }
    });

    let { isLoading: withdrawLokedTokenLoading, mutateAsync: withdrawLockedToken } = useMutation(async (payload: { token: string, to: string }) => {
        if (!gatrContract) throw new Error('GatrToken contract is not available');

        const { token, to } = payload;

        return await _withdrawLockedToken(token, to, gatrContract);
    }, {
        onError(error: any) {
            if (error.message) {
                toast.error("Withdrawing Locked Token failed: " + error.message)
            }
            else {
                toast.error("Withdrawing Locked Token failed, please try again!");
            }
            return error;
        }
    });

    let { isLoading: withdrawLokedEthLoading, mutateAsync: withdrawLockedEth } = useMutation(async (payload: { to: string }) => {
        if (!gatrContract) throw new Error('GatrToken contract is not available');

        const { to } = payload;

        return await _withdrawLockedEth(to, gatrContract);
    }, {
        onError(error: any) {
            if (error.message) {
                toast.error("Withdrawing Locked Eth failed: " + error.message)
            }
            else {
                toast.error("Withdrawing Locked Eth failed, please try again!");
            }
            return error;
        }
    });

    let { isLoading: launchLoading, mutateAsync: launch } = useMutation(async () => {
        if (!gatrContract) throw new Error('GatrToken contract is not available');
        return await _launch(gatrContract);
    }, {
        onError(error: any) {
            if (error.message) {
                toast.error('Launch failed: ' + error.message);
            }
            else {
                toast.error('Launch failed, please try again! ');
            }
            return error;
        }
    });

    let { isLoading: swapFeeLoading, mutateAsync: swapFee } = useMutation(async () => {
        if (!gatrContract) throw new Error('GatrToken contract is not available');
        return await _swapFee(gatrContract);
    }, {
        onError(error: any) {
            if (error.message) {
                toast.error('Swapping Fee failed: ' + error.message);
            }
            else {
                toast.error('Swapping Fee failed, please try again! ');
            }
            return error;
        }
    });

    useEffect(() => {
        if (gatrContract) {
            setIsTokenContractAvailable(true);
        }
    }, [gatrContract]);

    return { isLoadingToken: setAdminLoading || setBlacklistLoading || setViolationLoading || setExcludedFromFeesLoading || setMarketingWalletLoading || setSellTaxLoading || setBuyTaxLoading || setSwapThresholdLoading || launchLoading || swapFeeLoading || withdrawLokedEthLoading || withdrawLokedTokenLoading, setAdminToken, setBlacklistToken, setViolatorToken, setBuyTax, setSellTax, setExcludedFromFees, setMarketingWallet, setSwapThreshold, withdrawLockedEth, withdrawLockedToken, launch, swapFee, isTokenContractAvailable };
}
