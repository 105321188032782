import { Contracts as _Contracts } from "../types";

export const Contracts: _Contracts = {
    development: {
        // @review - Why is "bsc" forced here?
        // @review - We don't need to include `gasLimit` here
        GateRegistry: {
            "bsc": {
                id: 97,
                contract: "0xfbEA0B8B35c2e98C08119AC660dc1Afee8C64fFa",
                decimal: 18,
                gasLimit: 3000000,
            },
            "eth": {
                id: 11155111,
                contract: "0xfbEA0B8B35c2e98C08119AC660dc1Afee8C64fFa",
                decimal: 18,
                gasLimit: 3000000,
            },
        },
        GatrToken: {
            "bsc": {
                id: 97,
                contract: "0x2D53afB2EA7dB16300b783CCA0F10B553eD0Ad39",
                decimal: 18,
                gasLimit: 3000000,
            },
            "eth": {
                id: 11155111,
                contract: "0x2D53afB2EA7dB16300b783CCA0F10B553eD0Ad39",
                decimal: 18,
                gasLimit: 3000000,
            },
        },
    },
    production: {
        GateRegistry: {
            "bsc": {
                id: 56,
                contract: "0xa651CAced771D09B051D71BfeC008669931De8AF",
                decimal: 18,
                gasLimit: 3000000,
            },
            "eth": {
                id: 1,
                contract: "0xa651CAced771D09B051D71BfeC008669931De8AF",
                decimal: 18,
                gasLimit: 3000000,
            },
        },
        GatrToken: {
            "bsc": {
                id: 56,
                contract: "0xf30eE0fEa9e398f7B44659a774d008d8165e41Cc",
                decimal: 18,
                gasLimit: 3000000,
            },
            "eth": {
                id: 1,
                contract: "0xf30eE0fEa9e398f7B44659a774d008d8165e41Cc",
                decimal: 18,
                gasLimit: 3000000,
            },
        },
    },
};
