import jwt from "jwt-decode";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetMe } from "../hooks/users.hooks";
import { Auth, Props, AuthContext as _AuthContext } from "../types";
import { useWallet } from "./web3.context";

const AuthContext = createContext<_AuthContext>({
  isAuthenticating: false,
  isAuthenticated: false,
  role: undefined,
  login: (data: Auth) => {},
  logout: () => {},
});

const getAuthState = (): string => {
  try {
    const token = localStorage.getItem("token");
    if (token) {
      return token;
    } else {
      return "";
    }
  } catch (err) {
    return "";
  }
};

const AuthProvider = (props: Props) => {
  const navigate = useNavigate();
  const { isConnected,address } = useWallet();
  const [token, setToken] = useState<string>(getAuthState());
  const [role, setRole] = useState<"admin" | "user" | undefined>(undefined);
  const { data, isFetching, refetch, isError } = useGetMe(!!token);

  const login = (data: Auth) => {
    try {
      if (data) {
        localStorage.setItem("token", data.token);
        setToken(data.token);
        setRole(data.role);
      } else {
        setToken("");
      }
    } catch (error) {
      setToken("");
    }
  };

  const logout = useCallback(() => {
    localStorage.removeItem("token");
    setToken("");
    navigate("/auth");
  },[navigate]);

  useEffect(() => {
    if (isConnected) {
      const auth = getAuthState();
      if (auth){
        setToken(auth);
        const _token = localStorage.getItem("token");
        const user:any = jwt(_token as string);
        if(address !== user._address){
          logout();
        }
      }
    } else {
      logout();
    }
  }, [isConnected,logout,address]);

  useEffect(() => {
    if (token) {
      refetch();
    }
  }, [token, refetch]);

  useEffect(() => {
    if (data?.data) {
      setRole(data.data.role);
    }
  }, [data?.data]);

  useEffect(() => {
    if (isError) {
      logout();
    }
  }, [isError, logout]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticating: isFetching,
        isAuthenticated: !!token,
        role: role || data?.data.role,
        login,
        logout,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext<_AuthContext>(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};

export { useAuth };
export default AuthProvider;
