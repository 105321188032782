import styles from "../../styles/Forms/Forms.module.scss";

export default function NoteBox(props: any): JSX.Element {
  const { className, notes, children, ...rest } = props;
  return (
    <div className={`${styles.note_box} ${className || ""}`} {...rest}>
      {notes ? (
        <ul>
          {notes.map((note: any, index: number) => (
            <li key={index}>{note}</li>
          ))}
        </ul>
      ) : (
        <></>
      )}
      {children}
    </div>
  );
}
