import axios from 'axios';
import { ComplaintResponse, ComplaintsResponse, ComplaintsUpdateRequest, GateResponse, GateUpdateRequest, GatesResponse, PromotionResponse, PromotionUpdateRequest, PromotionsResponse, QueryParams, SiteRequest, SiteResponse, UserResponse, UserUpdateRequest, UsersResponse } from '../types';

const gateService = axios.create({
    baseURL: process.env.REACT_APP_GATE_API_URL || 'http://localhost:5000/v1',
    validateStatus: function (status) {
        return status >= 200 && status <= 302
    }
})

const proxyService = axios.create({
    baseURL: process.env.REACT_APP_PROXY_URL || 'http://localhost:4000/',
    validateStatus: function (status) {
        return status >= 200 && status <= 302
    }
})

gateService.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['authorization'] = `Bearer ${token}`;
        }
        return config;
    },
);

export const getRegisterMessage = async (address: string, q?: string) => gateService.get(`/auth/get-hash/${address}`, { params: { q } });
export const verifyRegisterMessage = async (data: any) => gateService.post('/auth', data);
export const getMe = async () => gateService.get<UserResponse>("/users/me");
export const getUsers = async (params: QueryParams) => gateService.get<UsersResponse>('/users', { params });
export const getUserById = async (user_id: string) => gateService.get<UserResponse>(`/users/${user_id}`);
export const updateUserById = async (user_id: string, data: UserUpdateRequest) => gateService.patch<UserResponse>(`/users/${user_id}`, data);
export const postComplaint = async (data: ComplaintResponse) => gateService.post('/complaints', data);
export const checkTokenAddress = async (address: string, token: "ERC20" | "ERC721") => gateService.get(`/tokens/check/${address}`, { params: { token } });
export const getNFTAttributes = async (address: string, trait_value?: string) => gateService.get(`/tokens/nft_attributes/${address}`, { params: { trait_value } });
export const getMyGates = async (params: any) => gateService.get<GatesResponse>(`/gates/me?page=${params.page}&limit=${params.limit}`);
export const getGates = async (params: any) => gateService.get<GatesResponse>(`/gates?page=${params.page}&limit=${params.limit}`);
export const getGateById = async (gate_id: string) => gateService.get<GateResponse>(`/gates/${gate_id}`);
export const updateGateById = async (gate_id: string, data: GateUpdateRequest) => gateService.patch<GateResponse>(`/gates/${gate_id}`, data);
export const createSite = async (gate_id: string, data: SiteRequest & { action?: "publish" | "unpublish" }) => gateService.post(`/sites/${gate_id}`, data, { params: { action: data.action } });
export const getSiteByGate = async (gate_id: string) => gateService.get<SiteResponse>(`/sites/${gate_id}`);
export const getFeaturedByGate = async (gate_id: string) => gateService.get<PromotionResponse>(`/featured/gate/${gate_id}`);
export const getUserFeatureds = async (params: any) => gateService.get<PromotionsResponse>(`/featured/me?page=${params.page}&limit=${params.limit}`);
export const updateFeaturedById = async (featured_id: string, data: PromotionUpdateRequest) => gateService.patch<PromotionResponse>(`/featured/${featured_id}`, data);
export const getFeaturedsAdmin = async (params: any) => gateService.get<PromotionsResponse>(`/featured/admin?page=${params.page}&limit=${params.limit}`);
export const updateComplaintsById = async (complaint_id: string, data: ComplaintsUpdateRequest) => gateService.patch<ComplaintResponse>(`/complaints/${complaint_id}`, data);
export const getComplaints = async (params: any) => gateService.get<ComplaintsResponse>(`/complaints?page=${params.page}&limit=${params.limit}`)

export const getDriveDownloadLink = async (drive_link: string) => proxyService.get(`/links/${encodeURIComponent(drive_link)}`);
