import { AxiosResponse } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { getFeaturedByGate, getFeaturedsAdmin, getUserFeatureds, updateFeaturedById } from "../api";
import { PromotionResponse, PromotionUpdateRequest, PromotionsResponse } from "../types";

export function useGetPromotionByGate(gate_id: string) {
    const query = useQuery<AxiosResponse<PromotionResponse>, any>(['gates', gate_id], () => getFeaturedByGate(gate_id), {
        onError(error:any) {
            if(error?.response?.data)toast.error(error?.response?.data as string, { autoClose: 5000 })
            else if (error instanceof Error) toast.error(error?.message, { autoClose: 5000 })
            else {
                toast.error('Errors while fetching promotion data. Please check and try again.', { autoClose: 5000 })
            }
            return error;
        },
        enabled: !!gate_id,
        retry: 3,
        refetchOnWindowFocus: false,
    })

    return query;
}

export function useGetPromotions(params: any, isLoading:any) {
    const query = useQuery<AxiosResponse<PromotionsResponse>, Error>(['featured', params, isLoading], () => getFeaturedsAdmin(params), {
        onError(error: any) {
            if (error?.response?.data) toast.error(error.response.data, { autoClose: 5000 })
            else if (error instanceof Error) toast.error(error?.message, { autoClose: 5000 })
            else {
                toast.error('Errors while fetching promotion data. Please check and try again.', { autoClose: 5000 })
            }
            console.log(error);
            return error;
        },
        // enabled: !!gate_id,
        retry: 3,
        refetchOnWindowFocus: false,
    })

    return query;
}

export function useGetUserPromotions(params: any, isLoading:any) {
    const query = useQuery<AxiosResponse<PromotionsResponse>, Error>(['featured', params, isLoading], () => getUserFeatureds(params), {
        onError(error: any) {
            if (error?.response?.data) toast.error(error.response.data, { autoClose: 5000 })
            else if (error instanceof Error) toast.error(error?.message, { autoClose: 5000 })
            else {
                toast.error('Errors while fetching promotion data. Please check and try again.', { autoClose: 5000 })
            }
            console.log(error);
            return error;
        },
        // enabled: !!gate_id,
        retry: 3,
        refetchOnWindowFocus: false,
    })

    return query;
}

export function useUpdateFeaturedById() {
    const queryClient = useQueryClient();

    const query = useMutation<AxiosResponse<PromotionUpdateRequest | Error>, Error, PromotionUpdateRequest & { featured_id: string; }, any>(async (data) => {
        const featured_id = data.featured_id;
        return await updateFeaturedById(featured_id, data);
    }, {
        onSuccess() {
            queryClient.invalidateQueries('featured');
        },
        onError(error: any) {
            if (error?.response?.data) toast.error(error.response.data, { autoClose: 5000 })
            else if (error instanceof Error) toast.error(error?.message, { autoClose: 5000 })
            else {
                toast.error('Errors while updating featured. Please check and try again.', { autoClose: 5000 })
            }
            return error;
        },
        retry: false,
    });

    return query;
}
