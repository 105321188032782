import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Cart, FormWrapper, Input } from "../../components";
import { useWallet } from "../../context/web3.context";
import { useGetPromotionByGate } from "../../hooks/promotions.hooks";
import styles from "../../styles/Gates/MintGate.module.scss";
import { FUNCTIONS, PAYMENT_TYPE } from "../../types";
import { SampleFeatureDisplay } from "../../components/Gates";
import { useGetGateById } from "../../hooks/gates.hooks";

export default function UpdatePromoteGate(): JSX.Element {
  const navigate = useNavigate();
  const [formState, setFormState] = useState({
    title: "",
    description: "",
    image_url: "",
  });

  const { gate_id } = useParams();
  const { usePromoteGate } = useWallet();

  const { isLoading, status, data } = useGetPromotionByGate(gate_id || "");
  const gateQuery = useGetGateById(gate_id || "");


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormState((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      if (!gate_id) throw new Error("ID in the URL is missing");
      if (isLoading) throw new Error("Loading promotion data, please wait...");
      if (status !== "success") throw new Error("Promotion not found");

      let { title, description, image_url } = formState;

      if (!usePromoteGate) throw new Error("Wallet is not connected");
      const tx = await usePromoteGate?.mutateAsync({
        payload: [title, description, image_url, gate_id],
        type: PAYMENT_TYPE.UPDATE,
      });
      toast.success(`Updated Gate Promotion Successful: ${tx?.hash}`);
      navigate("/promotions");
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isLoading || status !== "success") return;
    const { title, description, image_url } = data?.data;
    setFormState({
      title,
      description,
      image_url,
    });
  }, [data?.data, isLoading, status]);

  return (
    <section className={styles.mint_gate}>
      <h1>UPDATE PROMOTE GATE</h1>
      <div className={styles.mint_body}>
        <div>
          <div className={`${styles.form_wrapper} radial_gradient`}>
          <FormWrapper
            id="promote_gate"
            name="promote_gate"
            aria-disabled={status !== "success" || usePromoteGate?.isLoading}
          >
            <Input
              type="text"
              label="Title:"
              name="title"
              value={formState.title}
              onChange={handleChange}
              required
            />
            <Input
              type="textarea"
              label="Description:"
              name="description"
              value={formState.description}
              onChange={handleChange}
              required
            />
            <Input
              type="url"
              label="Image URL:"
              name="image_url"
              value={formState.image_url}
              onChange={handleChange}
              required
            />
          </FormWrapper>

        </div>
        <div className={styles.sample}>
          <h3 className={styles.preview}>PREVIEW</h3>
          <SampleFeatureDisplay
            proxy_link={gateQuery?.data?.data.proxy_link}
            key={gateQuery?.data?.data._id}
            amount={gateQuery?.data?.data.conditions.amount}
            image={formState.image_url}
            symbol={gateQuery?.data?.data.symbol}
            token={gateQuery?.data?.data.token}
            attribute={gateQuery?.data?.data.conditions.attribute}
            title={formState.title}
            description={formState.description} />
        </div>
        </div>

        <div className={styles.cart_wrapper}>
          <Cart type={FUNCTIONS.PROMOTE} />
          <div>
            <Button onClick={handleSubmit} loading={usePromoteGate?.isLoading}>
              PROMOTE GATE
            </Button>
            <div className={styles.buy_link}>
              Don't have enough? <Link to="/buy">Buy $0XGATR Now!</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
