import styles from "../../styles/Forms/Forms.module.scss";
import Loader from "../Loader";

export default function InputWithChildren(props: any): JSX.Element {
  const { className, loading, disabled, innerRef, error, children, ...rest } =
    props;
  return (
    <div className={`${styles.multi_input_group} ${className || ""}`}>
      <label htmlFor={rest?.name} aria-disabled={disabled || loading}>
        <div className={styles.label}>
          {rest?.label && <p>{rest.label}</p>}
          {rest?.icon && <i>{rest?.icon}</i>}
        </div>
      </label>
      <div>
        {rest?.type === "textarea" ? (
          <textarea
            id={rest?.name}
            {...rest}
            ref={innerRef && innerRef}
            aria-errormessage={error}
            disabled={disabled || loading}
          />
        ) : (
          <input
            id={rest?.name}
            {...rest}
            ref={innerRef && innerRef}
            aria-errormessage={error}
            disabled={disabled || loading}
          />
        )}
        {loading && <Loader />}
        {children}
      </div>
    </div>
  );
}
