import { Suspense, useMemo, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { Link } from "react-router-dom";
import {
  Loader,
  Table,
} from "../../components";
import { BlockedIcon, EyeCloseIcon, EyeIcon, UnBlockedIcon } from "../../components/Icons";
import { useWallet } from "../../context/web3.context";
import { useGetPromotions, useUpdateFeaturedById } from "../../hooks/promotions.hooks";
import styles from "../../styles/Gates/ViewGates.module.scss";
import {PromotionResponse } from "../../types";

export default function PromotionsAdmin(): JSX.Element {
  const { userTierData } = useWallet();
  // const { data, isLoading } = useGetGates();
  const [params,setParams] = useState({page:1, limit:10})
  const { mutateAsync: updateLink, isLoading: isLinkEditing } =
  useUpdateFeaturedById();
  const {data, isLoading} = useGetPromotions(params,isLinkEditing)

    const handlePageChange = (page:any) => {
      const limit = params.limit;
      setParams({page:page,limit:limit});

    }

    const handlePerRowsChange = async (newPerPage:any, page:any) => {
      setParams({page:page,limit:newPerPage})
    };

  const column = useMemo(
    (): TableColumn<PromotionResponse>[] => [
      {
        name: "FEATURE ID",
        selector: (row) => row["_id"],
        maxWidth: "140px",
      },
      {
        name: "GATE ID",
        selector: (row) => row["gate"]["_id"],
        maxWidth: "140px",
      },
      {
        name: "Status",
        selector: (row) => row["status"],
        width: "120px",
        format: (row) =>
        row["status"] === "live" ? (
          <p className={`${styles.status} green`}>Live</p>
        ) : row["status"] === "blocked" ? (
          <p className={`${styles.status} ${styles.blocked}`}>Blocked</p>
        ) : <p className={`${styles.status} orange`}>Disabled</p>
    },
      {
        name: "Title",
        selector: (row) => row["title"],
        maxWidth: "160px",
        style: {
          fontWeight: "500",
        },
      },
      {
        name: "Description",
        selector: (row) => row["description"],
        maxWidth: "360px",
        style: {
          fontWeight: "300",
        },
      },
      {
        name: "Content URL",
        selector: (row) => row["gate"]["_id"],
        format: (row) => (
          <Link to={`/gates/${row["gate"]["_id"]}/site`} className={styles.link}>
            Content Site
          </Link>
        ),
        maxWidth: "180px",
        wrap: true,
        style: {
          fontSize: "12px",
          wordBreak: "break-all",
        },
      },

      {
        name: "Gated Link",
        selector: (row) => row["gate"]["proxy_link"],
        format: (row) => (
          <Link to={`${process.env.REACT_APP_PROXY_URL}/${row["gate"]["proxy_link"]}`} target="_blank" className={styles.link}>
            {row["gate"]["proxy_link"]}
          </Link>
        ),
        maxWidth: "160px",
        style: {
          fontWeight: "500",
        },
      },
      {
        selector: (row) => row["_id"],
        cell: (row) => (
          <div
            className={styles.actions}
            aria-disabled={!userTierData}
          >
            <button
              className={styles.edits}
              onClick={async () => {
                try{
                  await updateLink({ featured_id: row["_id"], status: row["status"]==="live"?"disable": "live" })
                } catch(err){
                  console.log(err);
                }
              }}
              disabled={!userTierData || isLinkEditing || row["status"] === "blocked"}
            >
              {row["status"]==="live" ? <EyeCloseIcon /> : <EyeIcon />}
              {isLinkEditing}
            </button>
            <button
              className={styles.edits}
              onClick={async () => {
                try{
                  await updateLink({ featured_id: row["_id"], status: row["status"]!=="blocked"?"blocked": "live" })
                } catch(err){
                  console.log(err)
                }              }}
              disabled={!userTierData || isLinkEditing}
            >
              {row["status"]!=="blocked" ? <UnBlockedIcon /> : <BlockedIcon />}
              {isLinkEditing}
            </button>
          </div>
        ),
        minWidth: "20px",
        maxWidth: "100px",
      },
    ],
    [isLinkEditing, updateLink, userTierData]
  );

  return (
    <section className={styles.section}>
      <div className={styles.table_page}>
        <h1>PROMOTIONS</h1>
        <div className={styles.table_wrapper}>
          <Suspense fallback={<h1>Loading...</h1>}>
          {(isLoading || isLinkEditing) && <div className={styles.loader}><Loader darken/></div>}
            <Table
              outerClassName={styles.table}
              loading={isLoading}
              columns={column}
              data={data?.data.featureds}
              keyField="_id"
              pagination
              paginationServer
              paginationPerPage={params.limit}
              paginationTotalRows={data?.data.totalFeatureds}
              paginationDefaultPage={params.page}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              paginationComponentOptions={{
                onPageChange: handlePageChange,
              }}
              disabled ={isLinkEditing}

            />
          </Suspense>
        </div>
      </div>
    </section>
  );
}
