import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Cart,
  Dropdown,
  FormWrapper,
  Input,
  Loader,
} from "../../components";
import { useWallet } from "../../context/web3.context";
import { useNFTAttributes, useTokenCheck } from "../../hooks/common.hooks";
import { useGetGateById } from "../../hooks/gates.hooks";
import styles from "../../styles/Gates/MintGate.module.scss";
import { FUNCTIONS, PAYMENT_TYPE } from "../../types";

export default function UpdateGate(): JSX.Element {
  const navigate = useNavigate();
  const { gate_id } = useParams();
  const { useGate } = useWallet();

  const { status, data } = useGetGateById(gate_id || "");

  const [formState, setFormState] = useState({
    token: "ERC20",
    contract: "",
    amount: 0,
    attribute_key: "",
    attribute_value: "",
  });

  const { isOk, isCheckLoading, isTokenError, check } = useTokenCheck(
    formState.contract || data?.data.contract || "",
    (formState.token as "ERC20" | "ERC721") || data?.data.token
  );

  const {
    data: traits,
    isLoading: traitLoading,
    refetch: getTraits,
  } = useNFTAttributes(formState.contract || data?.data?.contract || "");

  const {
    data: values,
    isLoading: valuesLoading,
    refetch: getValues,
  } = useNFTAttributes(formState.contract, formState.attribute_key);

  const parseAttribute = useMemo(() => {
    if (!data?.data?.conditions.attribute) return ["", ""];
    const [key, value] = data?.data?.conditions.attribute.split(":");
    return [key.trim(), value.trim()];
  }, [data?.data?.conditions.attribute]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      if (!gate_id) throw new Error("Gate ID is required");
      if (!isOk?.data || isCheckLoading || isTokenError) {
        toast.error("Invalid Token Address");
        return;
      }
      const { token, contract, attribute_key, attribute_value } = formState;
      let amount = Number(formState.amount);
      let attribute = "";

      if (amount <= 0) {
        toast.error("Invalid Amount");
        return;
      }

      if (formState.token === "ERC721") {
        attribute = `${attribute_key}:${attribute_value}`;
      }

      if (!useGate) throw new Error("Wallet is not connected");
      const tx = await useGate?.mutateAsync({
        payload: [token, contract, amount, attribute, gate_id],
        type: PAYMENT_TYPE.UPDATE,
      });
      toast.success(`Update Gate Successful: ${tx?.hash}`);
      navigate("/gates");
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (status === "success" && data?.data) {
      const state = {
        token: data?.data?.token,
        contract: data?.data?.contract,
        amount: data?.data?.conditions.amount,
        attribute_key: parseAttribute[0],
        attribute_value: parseAttribute[1],
        link: data?.data?.link,
      };

      if (state.token === "ERC721") {
        getTraits();
      }

      setFormState(state);
      check();
    }
  }, [status, parseAttribute, data?.data, getTraits, check]);

  useEffect(() => {
    if (formState.token === "ERC20") {
      setFormState((prevState) => ({
        ...prevState,
        attribute_key: "",
        attribute_value: "",
      }));
    }
    if (formState.contract !== "") check();
  }, [formState.token, check]);

  useEffect(() => {
    if (
      isOk?.data !== formState.contract &&
      !isCheckLoading &&
      formState.contract.length === 42
    ) {
      check();
    }
  }, [isOk?.data, check, formState.contract, isCheckLoading]);

  useEffect(() => {
    if (isTokenError && !isCheckLoading && formState.contract.length === 42) {
      toast.error("Invalid Token Address");
      setFormState((prevState) => ({
        token: "ERC20",
        contract: "",
        amount: 0,
        attribute_key: "",
        attribute_value: "",
      }));
    }
  }, [isTokenError, isCheckLoading]);

  useEffect(() => {
    if (
      formState.token === "ERC721" &&
      !traitLoading &&
      !valuesLoading &&
      !isCheckLoading
    ) {
      getTraits();
    }
  }, [traitLoading, valuesLoading, getTraits, isCheckLoading]);

  useEffect(() => {
    if (
      formState.token === "ERC721" &&
      !traitLoading &&
      !valuesLoading &&
      formState.attribute_key &&
      !isCheckLoading
    ) {
      getValues();
    }
  }, [
    traitLoading,
    valuesLoading,
    formState.attribute_key,
    getValues,
    isCheckLoading,
  ]);

  return (
    <section className={styles.mint_gate}>
      <div className="bg_gradient"></div>
      <h1>UPDATE TOKEN INFO</h1>
      <div className={styles.mint_body}>
        <div className={`${styles.form_wrapper} radial_gradient`}>
          <FormWrapper
            id="mint_form"
            name="mint_form"
            aria-disabled={status !== "success" || useGate?.isLoading}
          >
            <Dropdown
              label="Token Type:"
              name="token"
              onChange={handleChange}
              value={formState.token}
              disabled={traitLoading || valuesLoading}
              required
            >
              <option value="ERC20">ERC20</option>
              <option value="ERC721">ERC721</option>
            </Dropdown>
            <Input
              type="text"
              label="Token Address:"
              name="contract"
              maxLength="42"
              minLength="42"
              value={formState.contract}
              onChange={handleChange}
              loading={isCheckLoading}
              disabled={traitLoading || valuesLoading}
              error={isOk?.data !== formState.contract && !isCheckLoading}
              required
            />
            <Input
              type="number"
              label="Minimum Quantity:"
              name="amount"
              value={formState.amount}
              onChange={handleChange}
              required
            />
            {formState.token === "ERC20" ? (
              <></>
            ) : (
              <div className={styles.attribute_input}>
                <label htmlFor="attribute_key">Attribute:</label>
                <div className={styles.double_select}>
                  <div className={styles.attribute}>
                    <label htmlFor="attribute_key">Key:</label>
                    <select
                      id="attribute_key"
                      name="attribute_key"
                      defaultValue={formState.attribute_key}
                      onChange={handleChange}
                      disabled={
                        isCheckLoading ||
                        traitLoading ||
                        valuesLoading ||
                        formState.token === "ERC20"
                      }
                    >
                      <option value="" disabled></option>
                      {traits?.data?.map((trait: string) => (
                        <option key={trait} value={trait}>
                          {trait}
                        </option>
                      ))}
                    </select>
                    {(traitLoading || valuesLoading) && (
                      <Loader className={styles.loader_container} />
                    )}
                  </div>
                  <div className={styles.attribute}>
                    <label htmlFor="attribute_value">Value:</label>
                    <select
                      id="attribute_value"
                      name="attribute_value"
                      defaultValue={formState.attribute_value}
                      onChange={handleChange}
                      disabled={
                        isCheckLoading ||
                        traitLoading ||
                        valuesLoading ||
                        formState.token === "ERC20"
                      }
                    >
                      <option value="" disabled></option>
                      <option value={formState.attribute_value}>
                        {formState.attribute_value}
                      </option>
                      {values?.data?.map((value: string) => (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      ))}
                    </select>
                    {(traitLoading || valuesLoading) && (
                      <Loader className={styles.loader_container} />
                    )}
                  </div>
                </div>
              </div>
            )}
          </FormWrapper>
        </div>
        <div className={styles.cart_wrapper}>
          <Cart type={FUNCTIONS.GATE_PAYMENT} />
          <div>
            <Button
              onClick={handleSubmit}
              loading={useGate?.isLoading}
              disabled={status !== "success" || !useGate}
            >
              UPDATE GATE
            </Button>
            <div className={styles.buy_link}>
              Don't have enough? <Link to="/buy">Buy $0XGATR Now!</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
