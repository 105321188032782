import { handleDriveLinks } from "../../services";
import styles from "../../styles/Forms/Forms.module.scss";
import Loader from "../Loader";

export default function InputWithPreview(props: any): JSX.Element {
  const { className, loading, disabled, innerRef, error, ...rest } = props;
  return (
    <div className={`${styles.input_preview} ${className || ""}`}>
      <label htmlFor={rest?.name1} aria-disabled={disabled || loading}>
        <div className={styles.label}>
          {rest?.label1 && <p>{rest.label1}</p>}
          {rest?.icon && <i>{rest?.icon}</i>}
        </div>
      </label>
      {rest?.type === "textarea" ? (
        <textarea
          id={rest?.name1}
          ref={innerRef && innerRef}
          name={rest?.name1}
          value={rest?.value1 || ""}
          onChange={rest?.onChange}
          aria-errormessage={error}
          disabled={disabled || loading}
        />
      ) : (
        <input
          id={rest?.name1}
          ref={innerRef && innerRef}
          name={rest?.name1}
          value={rest?.value1 || ""}
          onChange={rest?.onChange}
          aria-errormessage={error}
          disabled={disabled || loading}
        />
      )}
      <div className={styles.row}>
        <label htmlFor={rest?.name2} aria-disabled={disabled || loading}>
          <div className={styles.label}>
            {rest?.label2 && <p>{rest.label2}</p>}
            {rest?.icon && <i>{rest?.icon}</i>}
          </div>
        </label>
        {rest?.type === "textarea" ? (
          <textarea
            id={rest?.name2}
            name={rest?.name2}
            value={rest?.value2 || ""}
            onChange={rest?.onChange}
            aria-errormessage={error}
            disabled={disabled || loading}
          />
        ) : (
          <input
            id={rest?.name2}
            name={rest?.name2}
            value={rest?.value2 || ""}
            type="number"
            max={rest?.max}
            onChange={rest?.onChange}
            aria-errormessage={error}
            disabled={disabled || loading}
          />
        )}
      </div>
      <div className={`${styles.row} ${styles.radios}`}>
        <label htmlFor={rest?.name3} aria-disabled={disabled || loading}>
          <div className={styles.label}>
            {rest?.label3 && <p>{rest.label3}</p>}
            {rest?.icon && <i>{rest?.icon}</i>}
          </div>
        </label>
        <input
          id={rest?.name3}
          ref={innerRef && innerRef}
          name={rest?.name3}
          value={true as any}
          type="radio"
          checked={rest?.value3 === "true"}
          onClick={rest?.onChange}
          onChange={rest?.onChange}
          disabled={disabled || loading}
        />
      </div>
      <label>
        <div className={styles.label}>Preview</div>
      </label>
      <img
        src={handleDriveLinks(rest.value1)}
        alt="Preview"
        onError={rest?.onError}
      />
      {loading && <Loader />}
    </div>
  );
}
