import { Suspense, useMemo, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import { EditableText, Loader, Table } from "../../components";
import {
  BlockedIcon,
  EditIcon,
  EyeCloseIcon,
  EyeIcon,
  UnBlockedIcon,
} from "../../components/Icons";
import { useWallet } from "../../context/web3.context";
import { useGetGates, useUpdateGateById } from "../../hooks/gates.hooks";
import styles from "../../styles/Gates/ViewGates.module.scss";
import { GateResponse } from "../../types";

export default function ViewGatesAdmin(): JSX.Element {
  const navigate = useNavigate();
  const { userTierData } = useWallet();
  const { mutateAsync: updateLink, isLoading: isLinkEditing } =
    useUpdateGateById();
  const [params, setParams] = useState({ page: 1, limit: 10 });
  const { data, isLoading } = useGetGates(params, isLinkEditing);

  const handlePageChange = (page: any) => {
    const limit = params.limit;
    setParams({ page: page, limit: limit });
  };

  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setParams({ page: page, limit: newPerPage });
  };

  const column = useMemo(
    (): TableColumn<GateResponse>[] => [
      {
        name: "Gate ID",
        selector: (row) => row["_id"],
        maxWidth: "140px",
      },
      {
        name: "Status",
        selector: (row) => row["status"],
        width: "140px",
        format: (row) =>
          row["status"] === "live" ? (
            <p className={`${styles.status} green`}>Live</p>
          ) : row["status"] === "blocked" ? (
            <p className={`${styles.status} ${styles.blocked}`}>Blocked</p>
          ) : (
            <p className={`${styles.status} orange`}>Unpublished</p>
          ),
      },
      {
        name: "Gated Link",
        selector: (row) => row["proxy_link"],
        maxWidth: "300px",
        style: {
          fontWeight: "500",
        },
        cell: (row) => (
          <EditableText
            className={styles.link_edit}
            text={row["proxy_link"]}
            onSave={async (text: string) => {
              await updateLink({ gate_id: row["_id"], proxy_link: text });
            }}
            label="Gated Link"
            prefix={`${process.env.REACT_APP_PROXY_URL}/`}
            loading={isLinkEditing}
          />
        ),
      },
      {
        name: "Token",
        selector: (row) => row["token"],
        maxWidth: "140px",
        format: (row) => (
          <p>
            <Link
              to={`https://sepolia.etherscan.io/address/${row.contract}`}
              target="_blank"
              className={styles.link}
            >
              {row.symbol}
            </Link>{" "}
            ({row.token})
          </p>
        ),
      },
      {
        name: "Minimum",
        selector: (row) =>
          row["conditions"]["amount"]?.toLocaleString("en", {
            useGrouping: true,
          }),
        maxWidth: "140px",
      },
      {
        name: "Attribute",
        selector: (row) => row["conditions"]["attribute"] || "NA",
        maxWidth: "140px",
        wrap: true,
        style: {
          textTransform: "capitalize",
        },
      },
      {
        name: "Content URL",
        selector: (row) => row["_id"],
        format: (row) => (
          <Link to={`/gates/${row._id}/site`} className={styles.link}>
            Content Site
          </Link>
        ),
        maxWidth: "180px",
        wrap: true,
        style: {
          fontSize: "12px",
          wordBreak: "break-all",
        },
      },
      {
        selector: (row) => row["_id"],
        cell: (row) => (
          <div className={styles.actions} aria-disabled={!userTierData}>
            <button
              className={styles.edits}
              onClick={() => navigate(`/gates/update/${row["_id"]}`)}
              disabled={
                !userTierData || isLinkEditing || row["status"] === "blocked"
              }
            >
              <EditIcon />
            </button>
            <button
              className={styles.edits}
              onClick={async () => {
                try {
                  await updateLink({
                    gate_id: row["_id"],
                    status: row["status"] === "live" ? "disabled" : "live",
                  });
                } catch (err) {
                  console.log(err);
                }
              }}
              disabled={
                !userTierData || isLinkEditing || row["status"] === "blocked"
              }
            >
              {row["status"] === "live" ? <EyeCloseIcon /> : <EyeIcon />}
              {isLinkEditing}
            </button>
            <button
              className={styles.edits}
              onClick={async () => {
                try {
                  await updateLink({
                    gate_id: row["_id"],
                    status: row["status"] !== "blocked" ? "blocked" : "live",
                  });
                } catch (err) {
                  console.log(err);
                }
              }}
              disabled={!userTierData || isLinkEditing}
            >
              {row["status"] !== "blocked" ? (
                <UnBlockedIcon />
              ) : (
                <BlockedIcon />
              )}
              {/* {isLinkEditing} */}
            </button>
          </div>
        ),
        minWidth: "140px",
        maxWidth: "340px",
      },
    ],
    [isLinkEditing, updateLink, navigate, userTierData]
  );

  return (
    <section className={styles.section}>
      <div className={styles.table_page}>
        <h1>GATES</h1>
        <div className={styles.table_wrapper}>
          <Suspense fallback={<h1>Loading...</h1>}>
            {(isLoading || isLinkEditing) && (
              <div className={styles.loader}>
                <Loader darken />
              </div>
            )}
            <Table
              outerClassName={styles.table}
              loading={isLoading}
              columns={column}
              data={data?.data?.gates}
              keyField="_id"
              progressPending={isLoading}
              pagination
              paginationServer
              paginationPerPage={params.limit}
              paginationTotalRows={data?.data.totalGates}
              paginationDefaultPage={params.page}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              paginationComponentOptions={{
                onPageChange: handlePageChange,
              }}
              disabled={isLinkEditing}
            />
          </Suspense>
        </div>
      </div>
    </section>
  );
}
