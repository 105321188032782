import { useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Cart,
  FormWrapper,
  Input,
  SampleFeatureDisplay,
} from "../../components";
import { useWallet } from "../../context/web3.context";
import { useGetGateById } from "../../hooks/gates.hooks";
import styles from "../../styles/Gates/MintGate.module.scss";
import { FUNCTIONS, PAYMENT_TYPE } from "../../types";

export default function PromoteGate(): JSX.Element {
  const navigate = useNavigate();
  const { gate_id } = useParams();
  const { usePromoteGate } = useWallet();

  const [sampleTitle, setSampleTitle] = useState("This is the Title");
  const [sampleDescription, setSampleDescription] = useState(
    "This is the Description This is the Description This is the Description This is the Description This is the Description This is the Description"
  );
  const [imageUrl, setImageUrl] = useState(
    "https://upload.wikimedia.org/wikipedia/commons/f/f9/Phoenicopterus_ruber_in_S%C3%A3o_Paulo_Zoo.jpg"
  );

  const handleTitleChange = (event: any) => {
    if (event.target.value === "") setSampleTitle("This is the Title");
    else setSampleTitle(event.target.value);
  };

  const handleDescriptionChange = (event: any) => {
    if (event.target.value === "")
      setSampleDescription(
        "This is the Title This is the Title This is the Title This is the Title This is the Title This is the Title This is the Title "
      );
    else setSampleDescription(event.target.value);
  };

  const handleImageChange = (event: any) => {
    if (event.target.value === "")
      setImageUrl(
        "https://upload.wikimedia.org/wikipedia/commons/f/f9/Phoenicopterus_ruber_in_S%C3%A3o_Paulo_Zoo.jpg"
      );
    else setImageUrl(event.target.value);
  };

  const { data, isLoading, isError, status } = useGetGateById(gate_id || "");

  const formRef = useRef<HTMLFormElement>(null);
  const form = formRef.current;

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      if (!gate_id) throw new Error("Gate ID is required");
      if (isLoading) throw new Error("Loading gate, please wait...");
      if (isError) throw new Error("Error loading gate");
      if (status !== "success") throw new Error("Gate not found");
      if (!form) return;
      if (!form.checkValidity()) {
        form.reportValidity();
        return;
      }

      const formData = new FormData(form);
      const data = Object.fromEntries(formData.entries());
      let { title, description, image_url } = data;

      if (!usePromoteGate) throw new Error("Wallet is not connected");
      const tx = await usePromoteGate?.mutateAsync({
        payload: [
          title.toString(),
          description.toString(),
          image_url.toString(),
          gate_id,
        ],
        type: PAYMENT_TYPE.NEW,
      });
      toast.success(`Gate Promotion Successful: ${tx?.hash}`);
    } catch (error: any) {
      console.log(error);
    } finally {
      navigate("/promotions")
      form?.reset();
    }
  };

  return (
    <section className={styles.mint_gate}>
      <h1>PROMOTE GATE</h1>
      <div className={styles.mint_body}>
        <div>
          <div className={`${styles.form_wrapper} radial_gradient`}>
            <FormWrapper
              id="promote_gate"
              name="promote_gate"
              innerRef={formRef}
              aria-disabled={usePromoteGate?.isLoading}
            >
              <Input
                onChange={handleTitleChange}
                id="title"
                type="text"
                label="Title:"
                name="title"
                required
              />
              <Input
                type="textarea"
                label="Description:"
                name="description"
                id="description"
                onChange={handleDescriptionChange}
                required
              />
            <Input 
              id="image"
              type="url"
              onChange={handleImageChange}
              label="Image URL:" name="image_url"
              required
              />
          </FormWrapper>

        </div>
        <div className={styles.sample}>
          <h3 className={styles.preview}>PREVIEW</h3>
          <SampleFeatureDisplay proxy_link={data?.data.proxy_link} key={data?.data._id} amount={data?.data.conditions.amount} image={imageUrl} symbol={data?.data.symbol} token={data?.data.token}  attribute={data?.data.conditions.attribute} title={sampleTitle} description={sampleDescription} />
        </div>
        </div>

        <div className={styles.cart_wrapper}>
          <Cart type={FUNCTIONS.PROMOTE} />
          <div>
            <Button onClick={handleSubmit} loading={usePromoteGate?.isLoading}>
              PROMOTE GATE
            </Button>
            <div className={styles.buy_link}>
              Don't have enough? <Link to="/buy">Buy $0XGATR Now!</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
