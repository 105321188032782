import { ethers } from "ethers";
import { useMemo } from "react";
import { useWallet } from "../context/web3.context";
import styles from "../styles/Cart/CartCard.module.scss";
import { FUNCTIONS, Props } from "../types";
import { CartIcon, ExclamatoryIcon } from "./Icons";

export default function Cart(props: Props & { type: FUNCTIONS }): JSX.Element {
  const { userTierData } = useWallet();

  const price = useMemo(() => {
    if (!userTierData) return 0;
    if (props.type === FUNCTIONS.GATE_PAYMENT) {
      return ethers.utils.formatEther(userTierData.gatePrice.toString());
    }
    if (props.type === FUNCTIONS.PROMOTE) {
      return ethers.utils.formatEther(userTierData.promotionPrice.toString());
    }
    return 0;
  }, [userTierData, props.type]);

  return (
    <div className={`${styles.cart} radial_gradient`}>
      <div className={styles.header}>
        <CartIcon />
        <h2>Cart Summary</h2>
      </div>
      <div className={styles.items}>
        <p>Item(s):</p>
        {props.type === FUNCTIONS.GATE_PAYMENT && (
          <div className={styles.item}>
            <p>0xGATR Token-Gated URL</p>
          </div>
        )}
        {props.type === FUNCTIONS.PROMOTE && (
          <div className={styles.item}>
            <p>Gate Promotion</p>
          </div>
        )}
      </div>
      <div className={styles.prices}>
        <p>Fees:</p>
        {userTierData && (
          <>
            {props.type === FUNCTIONS.GATE_PAYMENT && <p>{price} $0XGATR</p>}
            {props.type === FUNCTIONS.PROMOTE && <p>{price} $0XGATR</p>}
          </>
        )}
      </div>
      <div className={styles.total}>
        <p>Fees:</p>
        {props.type === FUNCTIONS.GATE_PAYMENT && (
          <p>
            {price} <span>$0XGATR</span>
          </p>
        )}
        {props.type === FUNCTIONS.PROMOTE && (
          <p>
            {price} <span>$0XGATR</span>
          </p>
        )}
      </div>
      <p>
        <ExclamatoryIcon />
        Hold more 0XGATR for reduced gating fees
      </p>
    </div>
  );
}
