import { BigNumber, BigNumberish, ContractTransaction, ethers } from "ethers";
import { FUNCTIONS, GatePaymentArgs, PAYMENT_TYPE, PromoteArgs, SetTierArgs, SetTiersGateLimitArgs, TIERS, Tier, UserTierData, WithdrawArgs } from "../types";
import { ERC20, GateRegistry } from "../types/contracts";

async function getBalance(address: string, contract: ERC20): Promise<BigNumber> {
  try {
    if (!contract) throw new Error("GatrToken contract not found");
    if (!ethers.utils.isAddress(address)) throw new Error("Invalid address");

    try{
      await contract.callStatic.balanceOf(address);
    }catch (err:any){
      console.error('callStatic failed, trying estimateGas')
      console.error(err);
      if (err.message) {
        const reasonMatch = err.message.match(/reason="([^"]+)"/);
        const match = err.message.match(/errorName="([^"]+)"/);
        const customMatch = err.message.match(/CustomError: (.+)/);
        if (reasonMatch) throw reasonMatch[1];
        else if (match) throw match[1];
        else if (customMatch) throw customMatch[1];
      }
        await contract.estimateGas.balanceOf(address);
    }

    const balance = await contract.balanceOf(address);

    return balance;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function getPaused(contract: GateRegistry): Promise<boolean> {
  if (!contract) throw new Error("Gate Registry contract ");
  return await contract.paused();
}

async function getGATRAddress(contract: GateRegistry): Promise<string> {
  if (!contract) throw new Error("Gate Registry contract ");
  return await contract.gatr();
}

async function getGatrUsd(contract: GateRegistry): Promise<string> {
  if (!contract) throw new Error("Gate Registry contract not found");
  return (await contract.gatrUsd()).toString();
}

async function getTierPriceBasic(contract: GateRegistry): Promise<string> {
  if (!contract) throw new Error("Gate Registry contract not found");
  return (await contract.gateUsdPrices("1")).toString();
}

async function getTierPriceAdvanced(contract: GateRegistry): Promise<string> {
  if (!contract) throw new Error("Gate Registry contract not found");
  return (await contract.gateUsdPrices("2")).toString();
}

async function getTierPricePremium(contract: GateRegistry): Promise<string> {
  if (!contract) throw new Error("Gate Registry contract not found");
  return (await contract.gateUsdPrices("3")).toString();
}

async function getPromotionPrice(contract: GateRegistry): Promise<string> {
  if (!contract) throw new Error("Gate Registry contract not found");
  return (await contract.promotionUsdPrice()).toString();
}

async function getMinTierBasic(contract: GateRegistry): Promise<string> {
  if (!contract) throw new Error("Gate Registry contract not found");
  return (await contract.tiers("1")).toString();
}

async function getMinTierAdvanced(contract: GateRegistry): Promise<string> {
  if (!contract) throw new Error("Gate Registry contract not found");
  return (await contract.tiers("2")).toString();
}

async function getMinTierPremium(contract: GateRegistry): Promise<string> {
  if (!contract) throw new Error("Gate Registry contract not found");
  return (await contract.tiers("3")).toString();
}

async function getTiersGateLimitBasic(contract: GateRegistry): Promise<string> {
  if (!contract) throw new Error("Gate Registry contract not found");
  return (await contract.tiersGateLimit("1")).toString();
}

async function getTiersGateLimitAdvanced(contract: GateRegistry): Promise<string> {
  if (!contract) throw new Error("Gate Registry contract not found");
  return (await contract.tiersGateLimit("2")).toString();
}

async function getTiersGateLimitPremium(contract: GateRegistry): Promise<string> {
  if (!contract) throw new Error("Gate Registry contract not found");
  return (await contract.tiersGateLimit("3")).toString();
}

async function getUserTierData(address: string, contract: GateRegistry): Promise<UserTierData | undefined> {
  if (!contract) throw new Error("Gate Registry contract not found");
  if (!ethers.utils.isAddress(address)) throw new Error("Invalid address");

  try{
    await contract.callStatic.userTier(address);
  }catch (err:any){
      console.error('callStatic failed, trying estimateGas')
      console.error(err);
      if (err.message) {
        const reasonMatch = err.message.match(/reason="([^"]+)"/);
        const match = err.message.match(/errorName="([^"]+)"/);
        const customMatch = err.message.match(/CustomError: (.+)/);
        if (reasonMatch) throw reasonMatch[1];
        else if (match) throw match[1];
        else if (customMatch) throw customMatch[1];
      }
      await contract.estimateGas.userTier(address);
  }

  const tierData = await contract.userTier(address);

  let tier: Tier = "Ineligible";

  switch (tierData._userTier) {
    case TIERS.BASIC:
      tier = "Basic";
      break;

    case TIERS.ADVANCED:
      tier = "Advanced";
      break;

    case TIERS.PREMIUM:
      tier = "Premium";
      break;
  };

  return {
    tier,
    gateLimit: parseFloat(tierData._gateLimit.toBigInt().toString()),
    gatePrice: tierData._gatePrice,
    promotionPrice: tierData._promotionPrice,
  };
}

async function isAdmin(address: string, contract: GateRegistry): Promise<boolean> {
  if (!ethers.utils.isAddress(address)) throw new Error("Invalid address");

  try{
    await contract.callStatic.isAdmin(address);
  }catch (err:any){
    console.error('callStatic failed, trying estimateGas')
    console.error(err);
    if (err.message) {
      const reasonMatch = err.message.match(/reason="([^"]+)"/);
      const match = err.message.match(/errorName="([^"]+)"/);
      const customMatch = err.message.match(/CustomError: (.+)/);
      if (reasonMatch) throw reasonMatch[1];
      else if (match) throw match[1];
      else if (customMatch) throw customMatch[1];
    }
      await contract.estimateGas.isAdmin(address);
  }

  return await contract.isAdmin(address);
}

async function pause(contract: GateRegistry): Promise<ContractTransaction> {
  try {
    try {
      await contract.callStatic.pause();
    } catch (err: any) {
        console.error('callStatic failed, trying estimateGas')
        console.error(err);
        if (err.message) {
          const reasonMatch = err.message.match(/reason="([^"]+)"/);
          const match = err.message.match(/errorName="([^"]+)"/);
          const customMatch = err.message.match(/CustomError: (.+)/);
          if (reasonMatch) throw reasonMatch[1];
          else if (match) throw match[1];
          else if (customMatch) throw customMatch[1];
        }
        await contract.estimateGas.pause();
    }

    const tx = await contract.pause();
    await tx.wait();
    return tx;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function unpause(contract: GateRegistry): Promise<ContractTransaction> {
  try {
    try {
      await contract.callStatic.unpause();
    } catch (err: any) {
        console.error('callStatic failed, trying estimateGas')
        console.error(err);
        if (err.message) {
          const reasonMatch = err.message.match(/reason="([^"]+)"/);
          const match = err.message.match(/errorName="([^"]+)"/);
          const customMatch = err.message.match(/CustomError: (.+)/);
          if (reasonMatch) throw reasonMatch[1];
          else if (match) throw match[1];
          else if (customMatch) throw customMatch[1];
        }
        await contract.estimateGas.unpause();
    }

    const tx = await contract.unpause();
    await tx.wait();
    return tx;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function setAdmin(address: string, isEnabled: boolean, contract: GateRegistry): Promise<ContractTransaction> {
  try {

    try{
      await contract.callStatic.setAdmin(address, isEnabled);
    }catch (err:any){
      console.error('callStatic failed, trying estimateGas')
      console.error(err);
      if (err.message) {
        const reasonMatch = err.message.match(/reason="([^"]+)"/);
        const match = err.message.match(/errorName="([^"]+)"/);
        const customMatch = err.message.match(/CustomError: (.+)/);
        if (reasonMatch) throw reasonMatch[1];
        else if (match) throw match[1];
        else if (customMatch) throw customMatch[1];
      }
        await contract.estimateGas.setAdmin(address, isEnabled);
    }

    const tx = await contract.setAdmin(address, isEnabled);
    await tx.wait();
    return tx;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function setGatrUsd(gatrUsd: BigNumberish, contract: GateRegistry): Promise<ContractTransaction> {
  try {
    try{
      await contract.callStatic.setGatrUsd(gatrUsd);
    }catch (err:any){
      console.error('callStatic failed, trying estimateGas')
      console.error(err);
      if (err.message) {
        const reasonMatch = err.message.match(/reason="([^"]+)"/);
        const match = err.message.match(/errorName="([^"]+)"/);
        const customMatch = err.message.match(/CustomError: (.+)/);
        if (reasonMatch) throw reasonMatch[1];
        else if (match) throw match[1];
        else if (customMatch) throw customMatch[1];
      }
        await contract.estimateGas.setGatrUsd(gatrUsd);
    }

    const tx = await contract.setGatrUsd(gatrUsd);
    await tx.wait();
    return tx;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function setBlacklist(address: string, isEnabled: boolean, contract: GateRegistry): Promise<ContractTransaction> {
  try {

    try{
      await contract.callStatic.setBlacklist(address, isEnabled);
    }catch (err:any){
      console.error('callStatic failed, trying estimateGas')
      console.error(err);
      if (err.message) {
        const reasonMatch = err.message.match(/reason="([^"]+)"/);
        const match = err.message.match(/errorName="([^"]+)"/);
        const customMatch = err.message.match(/CustomError: (.+)/);
        if (reasonMatch) throw reasonMatch[1];
        else if (match) throw match[1];
        else if (customMatch) throw customMatch[1];
      }
        await contract.estimateGas.setBlacklist(address, isEnabled);
    }

    const tx = await contract.setBlacklist(address, isEnabled);
    await tx.wait();
    return tx;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function setTier(tiers: SetTierArgs, contract: GateRegistry): Promise<ContractTransaction> {
  try {

    try{
      await contract.callStatic.setTier(tiers);
    }catch (err:any){
      console.error('callStatic failed, trying estimateGas')
      console.error(err);
      if (err.message) {
        const reasonMatch = err.message.match(/reason="([^"]+)"/);
        const match = err.message.match(/errorName="([^"]+)"/);
        const customMatch = err.message.match(/CustomError: (.+)/);
        if (reasonMatch) throw reasonMatch[1];
        else if (match) throw match[1];
        else if (customMatch) throw customMatch[1];
      }
        await contract.estimateGas.setTier(tiers);
    }

    const tx = await contract.setTier(tiers);
    await tx.wait();
    return tx;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function setTiersGateLimit(tiers: SetTiersGateLimitArgs, contract: GateRegistry): Promise<ContractTransaction> {
  try {
    try {
      await contract.callStatic.setTiersGateLimit(tiers);
    } catch (err: any) {
      console.error('callStatic failed, trying estimateGas')
      console.error(err);
      if (err.message) {
        const reasonMatch = err.message.match(/reason="([^"]+)"/);
        const match = err.message.match(/errorName="([^"]+)"/);
        const customMatch = err.message.match(/CustomError: (.+)/);
        if (reasonMatch) throw reasonMatch[1];
        else if (match) throw match[1];
        else if (customMatch) throw customMatch[1];
      }
      await contract.estimateGas.setTiersGateLimit(tiers);
    }

    const tx = await contract.setTiersGateLimit(tiers);
    await tx.wait();
    return tx;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function setPrice(tiers: SetTierArgs,promotion: string, contract: GateRegistry): Promise<ContractTransaction> {
  try {

    try{
      await contract.callStatic.setPrice(tiers,promotion);
    }catch (err:any){
      console.error('callStatic failed, trying estimateGas')
      console.error(err);
      if (err.message) {
        const reasonMatch = err.message.match(/reason="([^"]+)"/);
        const match = err.message.match(/errorName="([^"]+)"/);
        const customMatch = err.message.match(/CustomError: (.+)/);
        if (reasonMatch) throw reasonMatch[1];
        else if (match) throw match[1];
        else if (customMatch) throw customMatch[1];
      }
        await contract.estimateGas.setPrice(tiers,promotion);
    }

    const tx = await contract.setPrice(tiers,promotion);
    await tx.wait();
    return tx;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function withdraw(data: WithdrawArgs, contract: GateRegistry): Promise<ContractTransaction> {
  try {

    try{
      await contract.callStatic.withdraw(data[0], data[1], data[2]);
    }catch (err:any){
      console.error('callStatic failed, trying estimateGas')
      console.error(err);
      if (err.message) {
        const reasonMatch = err.message.match(/reason="([^"]+)"/);
        const match = err.message.match(/errorName="([^"]+)"/);
        const customMatch = err.message.match(/CustomError: (.+)/);
        if (reasonMatch) throw reasonMatch[1];
        else if (match) throw match[1];
        else if (customMatch) throw customMatch[1];
      }
        await contract.estimateGas.withdraw(data[0], data[1], data[2]);
    }

    const tx = await contract.withdraw(data[0], data[1], data[2]);
    await tx.wait();
    return tx;
  } catch (error: any) {
    throw new Error(error);
  }
}

const argsDataEncoder = {
  [FUNCTIONS.GATE_PAYMENT]: (args: GatePaymentArgs, contract: GateRegistry): string => {
    if (!contract) throw new Error("Gate Registry contract not found");
    if (!args) throw new Error("Arguments not found");
    const encodedData = contract.interface._abiCoder.encode(
      ["string", "address", "uint256", "string", "string"],
      args,
    );

    return encodedData;
  },
  [FUNCTIONS.PROMOTE]: (args: PromoteArgs, contract: GateRegistry): string => {
    if (!contract) throw new Error("Gate Registry contract not found");
    if (!args) throw new Error("Arguments not found");
    const encodedData = contract.interface._abiCoder.encode(
      ["string", "string", "string", "string"],
      args,
    );

    return encodedData;
  },
}

async function mintGate(type: PAYMENT_TYPE, data: GatePaymentArgs, contract: GateRegistry | undefined): Promise<ContractTransaction> {
  try {
    if (!contract) throw new Error("Gate Registry contract not found");

    const encodedData = argsDataEncoder[FUNCTIONS.GATE_PAYMENT](data, contract);

    try{
      await contract.callStatic[FUNCTIONS.GATE_PAYMENT](type, encodedData);
    }catch (err:any){
      console.error('callStatic failed, trying estimateGas')
      console.error(err);
      if (err.message) {
        const reasonMatch = err.message.match(/reason="([^"]+)"/);
        const match = err.message.match(/errorName="([^"]+)"/);
        const customMatch = err.message.match(/CustomError: (.+)/);
        if (reasonMatch) throw reasonMatch[1];
        else if (match) throw match[1];
        else if (customMatch) throw customMatch[1];
      }
        await contract.estimateGas[FUNCTIONS.GATE_PAYMENT](type, encodedData);
    }

    const tx = await contract[FUNCTIONS.GATE_PAYMENT](type, encodedData);
    await tx.wait();
    return tx;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function promoteGate(type: PAYMENT_TYPE, data: PromoteArgs, contract: GateRegistry | undefined): Promise<ContractTransaction> {
  try {
    if (!contract) throw new Error("Gate Registry contract not found");

    const encodedData = argsDataEncoder[FUNCTIONS.PROMOTE](data, contract);

    try{
      await contract.callStatic[FUNCTIONS.PROMOTE](type, encodedData);
    }catch (err:any){
      console.error('callStatic failed, trying estimateGas')
      console.error(err);
      if (err.message) {
        const reasonMatch = err.message.match(/reason="([^"]+)"/);
        const match = err.message.match(/errorName="([^"]+)"/);
        const customMatch = err.message.match(/CustomError: (.+)/);
        if (reasonMatch) throw reasonMatch[1];
        else if (match) throw match[1];
        else if (customMatch) throw customMatch[1];
      }
        await contract.estimateGas[FUNCTIONS.PROMOTE](type, encodedData);
    }

    const tx = await contract[FUNCTIONS.PROMOTE](type, encodedData);
    await tx.wait();
    return tx;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function setAdminToken(address: string, isEnabled: boolean, contract: ERC20): Promise<ContractTransaction> {
  try {

    try{
      await contract.callStatic.setAdmin(address, isEnabled);
    }catch (err:any){
      console.error('callStatic failed, trying estimateGas')
      console.error(err);
      if (err.message) {
        const reasonMatch = err.message.match(/reason="([^"]+)"/);
        const match = err.message.match(/errorName="([^"]+)"/);
        const customMatch = err.message.match(/CustomError: (.+)/);
        if (reasonMatch) throw reasonMatch[1];
        else if (match) throw match[1];
        else if (customMatch) throw customMatch[1];
      }
        await contract.estimateGas.setAdmin(address, isEnabled);
    }

    const tx = await contract.setAdmin(address, isEnabled);
    await tx.wait();
    return tx;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function setBlacklistToken(address: string, isEnabled: boolean, contract: ERC20): Promise<ContractTransaction> {
  try {

    try{
      await contract.callStatic.setBlacklist(address, isEnabled);
    }catch (err:any){
      console.error('callStatic failed, trying estimateGas')
      console.error(err);
      if (err.message) {
        const reasonMatch = err.message.match(/reason="([^"]+)"/);
        const match = err.message.match(/errorName="([^"]+)"/);
        const customMatch = err.message.match(/CustomError: (.+)/);
        if (reasonMatch) throw reasonMatch[1];
        else if (match) throw match[1];
        else if (customMatch) throw customMatch[1];
      }
        await contract.estimateGas.setBlacklist(address, isEnabled);
    }

    const tx = await contract.setBlacklist(address, isEnabled);
    await tx.wait();
    return tx;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function setViolatorToken(address: string, isEnabled: boolean, contract: ERC20): Promise<ContractTransaction> {
  try {
    try{
      await contract.callStatic.setViolator(address, isEnabled);
    }catch (err:any){
      console.error('callStatic failed, trying estimateGas')
      console.error(err);
      if (err.message) {
        const reasonMatch = err.message.match(/reason="([^"]+)"/);
        const match = err.message.match(/errorName="([^"]+)"/);
        const customMatch = err.message.match(/CustomError: (.+)/);
        if (reasonMatch) throw reasonMatch[1];
        else if (match) throw match[1];
        else if (customMatch) throw customMatch[1];
      }
        await contract.estimateGas.setViolator(address, isEnabled);
    }

    const tx = await contract.setViolator(address, isEnabled);
    await tx.wait();
    return tx;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function getSwapEnabled(contract: ERC20): Promise<boolean> {
  if (!contract) throw new Error("GatrToken contract not found");
  return await contract.swapEnabled();
}

async function getSwapThreshold(contract: ERC20): Promise<string> {
  if (!contract) throw new Error("GatrToken contract not found");
  return (await contract.swapThreshold()).toString();
}

async function setSwapThreshold(swapThreshold: BigNumberish, contract: ERC20): Promise<ContractTransaction> {
  try {

    try{
      await contract.callStatic.setSwapThreshold(swapThreshold);
    }catch (err:any){
      console.error('callStatic failed, trying estimateGas')
      console.error(err);
      if (err.message) {
        const reasonMatch = err.message.match(/reason="([^"]+)"/);
        const match = err.message.match(/errorName="([^"]+)"/);
        const customMatch = err.message.match(/CustomError: (.+)/);
        if (reasonMatch) throw reasonMatch[1];
        else if (match) throw match[1];
        else if (customMatch) throw customMatch[1];
      }
        await contract.estimateGas.setSwapThreshold(swapThreshold);
    }

    const tx = await contract.setSwapThreshold(swapThreshold);
    await tx.wait();
    return tx;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function getBuyTax(contract: ERC20): Promise<string> {
  if (!contract) throw new Error("Gate Registry contract");
  return (await contract.buyTax()).toString();
}

async function setBuyTax(buyTax: number, contract: ERC20): Promise<ContractTransaction> {
  try {

    try{
      await contract.callStatic.setBuyTax(buyTax);
    }catch (err:any){
      console.error('callStatic failed, trying estimateGas')
      console.error(err);
      if (err.message) {
        const reasonMatch = err.message.match(/reason="([^"]+)"/);
        const match = err.message.match(/errorName="([^"]+)"/);
        const customMatch = err.message.match(/CustomError: (.+)/);
        if (reasonMatch) throw reasonMatch[1];
        else if (match) throw match[1];
        else if (customMatch) throw customMatch[1];
      }
        await contract.estimateGas.setBuyTax(buyTax);
    }

    const tx = await contract.setBuyTax(buyTax);
    await tx.wait();
    return tx;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function getSellTax(contract: ERC20): Promise<string> {
  if (!contract) throw new Error("Gate Registry contract");
  return (await contract.sellTax()).toString();
}
async function setSellTax(sellTax: number, contract: ERC20): Promise<ContractTransaction> {
  try {

    try{
      await contract.callStatic.setSellTax(sellTax);
    } catch (err:any){
      console.error('callStatic failed, trying estimateGas')
      console.error(err);
      if (err.message) {
        const reasonMatch = err.message.match(/reason="([^"]+)"/);
        const match = err.message.match(/errorName="([^"]+)"/);
        const customMatch = err.message.match(/CustomError: (.+)/);
        if (reasonMatch) throw reasonMatch[1];
        else if (match) throw match[1];
        else if (customMatch) throw customMatch[1];
      }
        await contract.estimateGas.setSellTax(sellTax);
    }

    const tx = await contract.setSellTax(sellTax);
    await tx.wait();
    return tx;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function getMarketingWallet(contract: ERC20): Promise<string> {
  if (!contract) throw new Error("Gate Registry contract");
  return (await contract.marketingWallet()).toString();
}

async function setMarketingWallet(marketingWallet: string, contract: ERC20): Promise<ContractTransaction> {
  try {

    try{
      await contract.callStatic.setMarketingWallet(marketingWallet);
    }catch (err:any){
      console.error('callStatic failed, trying estimateGas')
      console.error(err);
      if (err.message) {
        const reasonMatch = err.message.match(/reason="([^"]+)"/);
        const match = err.message.match(/errorName="([^"]+)"/);
        const customMatch = err.message.match(/CustomError: (.+)/);
        if (reasonMatch) throw reasonMatch[1];
        else if (match) throw match[1];
        else if (customMatch) throw customMatch[1];
      }
        await contract.estimateGas.setMarketingWallet(marketingWallet);
    }

    const tx = await contract.setMarketingWallet(marketingWallet);
    await tx.wait();
    return tx;
  } catch (error: any) {
    throw new Error(error);
  }
}


async function setExcludedFromFees(wallet: string,isExcluded: boolean, contract: ERC20): Promise<ContractTransaction> {
  try {

    try{
      await contract.callStatic.setExcludedFromFees(wallet,isExcluded);
    }catch (err:any){
      console.error('callStatic failed, trying estimateGas')
      console.error(err);
      if (err.message) {
        const reasonMatch = err.message.match(/reason="([^"]+)"/);
        const match = err.message.match(/errorName="([^"]+)"/);
        const customMatch = err.message.match(/CustomError: (.+)/);
        if (reasonMatch) throw reasonMatch[1];
        else if (match) throw match[1];
        else if (customMatch) throw customMatch[1];
      }
        await contract.estimateGas.setExcludedFromFees(wallet,isExcluded);
    }

    const tx = await contract.setExcludedFromFees(wallet,isExcluded);
    await tx.wait();
    return tx;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function withdrawLockedToken(token: string,to: string, contract: ERC20): Promise<ContractTransaction> {
  try {

    try{
      await contract.callStatic.withdrawLockedToken(token,to);
      }
      catch (err:any){
        console.error('callStatic failed, trying estimateGas')
        console.error(err);
        if (err.message) {
          const reasonMatch = err.message.match(/reason="([^"]+)"/);
          const match = err.message.match(/errorName="([^"]+)"/);
          const customMatch = err.message.match(/CustomError: (.+)/);
          if (reasonMatch) throw reasonMatch[1];
          else if (match) throw match[1];
          else if (customMatch) throw customMatch[1];
        }
        await contract.estimateGas.withdrawLockedToken(token,to);
    }

    const tx = await contract.withdrawLockedToken(token,to);
    await tx.wait();
    return tx;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function withdrawLockedEth(to: string, contract: ERC20): Promise<ContractTransaction> {
  try {

    try{
      await contract.callStatic.withdrawLockedEth(to);
    }catch (err:any){
      console.error('callStatic failed, trying estimateGas')
      console.error(err);
      if (err.message) {
        const reasonMatch = err.message.match(/reason="([^"]+)"/);
        const match = err.message.match(/errorName="([^"]+)"/);
        const customMatch = err.message.match(/CustomError: (.+)/);
        if (reasonMatch) throw reasonMatch[1];
        else if (match) throw match[1];
        else if (customMatch) throw customMatch[1];
      }
        await contract.estimateGas.withdrawLockedEth(to);
    }

    const tx = await contract.withdrawLockedEth(to);
    await tx.wait();
    return tx;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function launch(contract: ERC20): Promise<ContractTransaction> {
  try {

    try{
      await contract.callStatic.launch();
    }catch (err:any){
      console.error('callStatic failed, trying estimateGas')
      console.error(err);
      if (err.message) {
        const reasonMatch = err.message.match(/reason="([^"]+)"/);
        const match = err.message.match(/errorName="([^"]+)"/);
        const customMatch = err.message.match(/CustomError: (.+)/);
        if (reasonMatch) throw reasonMatch[1];
        else if (match) throw match[1];
        else if (customMatch) throw customMatch[1];
      }
        await contract.estimateGas.launch();
    }

    const tx = await contract.launch();
    await tx.wait();
    return tx;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function swapFee(contract: ERC20): Promise<ContractTransaction> {
  try {

    try{
      await contract.callStatic.swapFee();
    }catch (err:any){
      console.error('callStatic failed, trying estimateGas')
      console.error(err);
      if (err.message) {
        const reasonMatch = err.message.match(/reason="([^"]+)"/);
        const match = err.message.match(/errorName="([^"]+)"/);
        const customMatch = err.message.match(/CustomError: (.+)/);
        if (reasonMatch) throw reasonMatch[1];
        else if (match) throw match[1];
        else if (customMatch) throw customMatch[1];
      }
        await contract.estimateGas.swapFee();
    }

    const tx = await contract.swapFee();
    await tx.wait();
    return tx;
  } catch (error: any) {
    throw new Error(error);
  }
}


export { argsDataEncoder, getBalance, getPaused, getSwapEnabled, getBuyTax, getGATRAddress, getGatrUsd, getMarketingWallet, getMinTierAdvanced, getMinTierBasic, getMinTierPremium, getTiersGateLimitBasic, getTiersGateLimitAdvanced, getTiersGateLimitPremium, getPromotionPrice, getSellTax, getSwapThreshold, getTierPriceAdvanced, getTierPriceBasic, getTierPricePremium, getUserTierData, isAdmin, pause, unpause, launch, mintGate, promoteGate, setAdmin, setAdminToken, setBlacklist, setBlacklistToken, setBuyTax, setExcludedFromFees, setGatrUsd, setMarketingWallet, setPrice, setSellTax, setSwapThreshold, setTier, setTiersGateLimit, setViolatorToken, swapFee, withdraw, withdrawLockedEth, withdrawLockedToken };
