import { Navigate } from "react-router-dom";
import { useAuth } from "../context/user.context";
import { Home } from "../pages";

export default function ProtectedRoute({
  children,
  admin,
}: {
  children: React.ReactElement;
  admin?: boolean;
  [x: string]: any;
}): React.ReactElement | null {
  const { isAuthenticated, isAuthenticating, role } = useAuth();

  if (isAuthenticating === true) {
    return <Home />;
  }

  return (
    <>
      {isAuthenticated && (admin ? role === "admin" : true) ? (
        children
      ) : (
        <Navigate to="/auth" />
      )}
    </>
  );
}
