import { AxiosResponse } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { getMe, getUsers, updateUserById } from "../api";
import { QueryParams, UserResponse, UserUpdateRequest, UsersResponse } from "../types";

export function useGetMe(enabled: boolean = true) {
    const query = useQuery<AxiosResponse<UserResponse>, Error>(['me'], getMe, {
        onError(error: any) {
            return error;
        },
        enabled,
        retry: false,
        refetchOnWindowFocus: false,
    })

    return query;
}

export function useGetUsers(params: QueryParams) {
    const query = useQuery<AxiosResponse<UsersResponse>, Error>(['users'], () => getUsers(params), {
        onError(error: any) {
            if (error?.reason) toast.error(error.reason, { autoClose: 5000 })
            else if (error?.response?.data) toast.error(error.response.data, { autoClose: 5000 })
            else if (error instanceof Error) toast.error(error?.message, { autoClose: 5000 })
            else {
                toast.error('Errors while fetching users data. Please check and try again.', { autoClose: 5000 })
            }
            return error;
        },
        staleTime: 1000 * 60,
        retry: 3,
        refetchOnWindowFocus: false,
    })

    return query;
}

export function useUpdateUserById() {
    const queryClient = useQueryClient();

    const query = useMutation<AxiosResponse<UserResponse | Error>, Error, UserUpdateRequest & { _id: string; }, any>(async (data) => {
        try {
            const user_id = data._id;
            return await updateUserById(user_id, data);
        } catch (error) {
            throw error;
        }
    }, {
        onSuccess() {
            queryClient.invalidateQueries('users');
        },
        onError(error: any) {
            if (error?.response?.data) toast.error(error.response.data, { autoClose: 5000 })
            else if (error instanceof Error) toast.error(error?.message, { autoClose: 5000 })
            else {
                toast.error('Errors while updating user. Please check and try again.', { autoClose: 5000 })
            }
            return error;
        },
        retry: false,
    });
    return query;
}
