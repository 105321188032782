import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Dropdown,
  FormWrapper,
  Input,
  NoteBox,
} from "../../components";
import { useWallet } from "../../context/web3.context";
import {
  useGateRegistryContract,
  useGatrTokenContract,
} from "../../hooks/contracts.hooks";
import {
  getBuyTax,
  getGatrUsd,
  getMarketingWallet,
  getMinTierAdvanced,
  getMinTierBasic,
  getMinTierPremium,
  getPaused,
  getPromotionPrice,
  getSellTax,
  getSwapEnabled,
  getSwapThreshold,
  getTierPriceAdvanced,
  getTierPriceBasic,
  getTierPricePremium,
  getTiersGateLimitAdvanced,
  getTiersGateLimitBasic,
  getTiersGateLimitPremium,
} from "../../services";
import styles from "../../styles/Admin/Contract.module.scss";

export default function Contract(): JSX.Element {
  const {
    pause,
    unpause,
    setAdmin,
    setBlacklist,
    setGatrUsd,
    setTier,
    setTiersGateLimit,
    withdraw,
    setPrice,
    isLoading,
    isContractAvailable,
  } = useGateRegistryContract();
  const {
    setAdminToken,
    setBlacklistToken,
    setViolatorToken,
    setBuyTax,
    setSellTax,
    setExcludedFromFees,
    setMarketingWallet,
    setSwapThreshold,
    withdrawLockedEth,
    withdrawLockedToken,
    launch,
    swapFee,
    isLoadingToken,
    isTokenContractAvailable,
  } = useGatrTokenContract();
  const [globalLoading, setGlobalLoading] = useState(
    isLoadingToken || isLoading
  );
  const { gateRegistryContract, gatrContract } = useWallet();
  const [_paused, set_Paused] = useState<boolean | undefined>(undefined);
  const [_swapEnabled, set_SwapEnabled] = useState<boolean | undefined>(
    undefined
  );
  const [_gatrUsd, set_GatrUsd] = useState<string | undefined>(undefined);
  const [_buyTax, set_BuyTax] = useState<string | undefined>(undefined);
  const [_sellTax, set_SellTax] = useState<string | undefined>(undefined);
  const [_swapThreshold, set_SwapThreshold] = useState<string | undefined>(
    undefined
  );
  const [_marketingWallet, set_MarketingWallet] = useState<string | undefined>(
    undefined
  );
  const [_tierPriceBasic, set_TierPriceBasic] = useState<string | undefined>(
    undefined
  );
  const [_tierPricePremium, set_TierPricePremium] = useState<
    string | undefined
  >(undefined);
  const [_tierPriceAdvanced, set_TierPriceAdvanced] = useState<
    string | undefined
  >(undefined);
  const [_promotionPrice, set_PromotionPrice] = useState<string | undefined>(
    undefined
  );
  const [_minTierBasic, set_MinTierBasic] = useState<string | undefined>(
    undefined
  );
  const [_minTierPremium, set_MinTierPremium] = useState<string | undefined>(
    undefined
  );
  const [_minTierAdvanced, set_MinTierAdvanced] = useState<string | undefined>(
    undefined
  );
  const [_tiersGateLimitBasic, set_TiersGateLimitBasic] = useState<
    string | undefined
  >(undefined);
  const [_tiersGateLimitPremium, set_TiersGateLimitPremium] = useState<
    string | undefined
  >(undefined);
  const [_tiersGateLimitAdvanced, set_TiersGateLimitAdvanced] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    if (gateRegistryContract) {
      getPaused(gateRegistryContract)
        .then((paused) => {
          set_Paused(paused);
        })
        .catch((err) => console.log(err));

      getGatrUsd(gateRegistryContract)
        .then((gateUsd) => {
          set_GatrUsd(gateUsd);
        })
        .catch((err) => console.log(err));

      getTierPriceBasic(gateRegistryContract)
        .then((basic) => {
          set_TierPriceBasic(basic);
        })
        .catch((err) => console.log(err));

      getTierPricePremium(gateRegistryContract)
        .then((premium) => {
          set_TierPricePremium(premium);
        })
        .catch((err) => console.log(err));

      getTierPriceAdvanced(gateRegistryContract)
        .then((advanced) => {
          set_TierPriceAdvanced(advanced);
        })
        .catch((err) => console.log(err));

      getTiersGateLimitBasic(gateRegistryContract)
        .then((basic) => {
          set_TiersGateLimitBasic(basic);
        })
        .catch((err) => console.log(err));

      getTiersGateLimitPremium(gateRegistryContract)
        .then((premium) => {
          set_TiersGateLimitPremium(premium);
        })
        .catch((err) => console.log(err));

      getTiersGateLimitAdvanced(gateRegistryContract)
        .then((advanced) => {
          set_TiersGateLimitAdvanced(advanced);
        })
        .catch((err) => console.log(err));

      getPromotionPrice(gateRegistryContract)
        .then((promotion) => {
          set_PromotionPrice(promotion);
        })
        .catch((err) => console.log(err));

      getMinTierBasic(gateRegistryContract)
        .then((basic) => {
          set_MinTierBasic(basic);
        })
        .catch((err) => console.log(err));

      getMinTierPremium(gateRegistryContract)
        .then((premium) => {
          set_MinTierPremium(premium);
        })
        .catch((err) => console.log(err));

      getMinTierAdvanced(gateRegistryContract)
        .then((advanced) => {
          set_MinTierAdvanced(advanced);
        })
        .catch((err) => console.log(err));
    }

    if (gatrContract) {
      getSwapEnabled(gatrContract)
        .then((swapEnabled) => {
          set_SwapEnabled(swapEnabled);
        })
        .catch((err) => console.log(err));

      getSwapThreshold(gatrContract)
        .then((swapThreshold) => {
          set_SwapThreshold(swapThreshold);
        })
        .catch((err) => console.log(err));

      getMarketingWallet(gatrContract)
        .then((marketingWallet) => {
          set_MarketingWallet(marketingWallet);
        })
        .catch((err) => console.log(err));

      getBuyTax(gatrContract)
        .then((tax) => {
          set_BuyTax(tax);
        })
        .catch((err) => console.log(err));

      getSellTax(gatrContract)
        .then((tax) => {
          set_SellTax(tax);
        })
        .catch((err) => console.log(err));
    }
  }, [globalLoading, gateRegistryContract, gatrContract]);

  const handlePauseable = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      setGlobalLoading(true);
      const tx = _paused === false ? await pause() : await unpause();
      toast.success(`Pause/Unpause Success: ${tx.hash}`);
      setGlobalLoading(false);
    } catch (error) {
      setGlobalLoading(false);
    }
  };

  const handleSetAdmin = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      setGlobalLoading(true);
      const formData = new FormData(e.target as HTMLFormElement);
      const data = Object.fromEntries(formData.entries());
      const { address, isEnabled } = data;
      const tx = await setAdmin({
        address: address.toString(),
        isEnabled: !!isEnabled,
      });
      toast.success(`Set Admin Success: ${tx.hash}`);
      setGlobalLoading(false);
    } catch (error) {
      setGlobalLoading(false);
    }
  };

  const handleSetExcludedFromFees = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    try {
      e.preventDefault();
      setGlobalLoading(true);
      const formData = new FormData(e.target as HTMLFormElement);
      const data = Object.fromEntries(formData.entries());
      const { wallet, isExcluded } = data;
      const tx = await setExcludedFromFees({
        wallet: wallet.toString(),
        isExcluded: !!isExcluded,
      });
      toast.success(`Set Excluded From Fees Success: ${tx.hash}`);
      setGlobalLoading(false);
    } catch (error) {
      setGlobalLoading(false);
    }
  };

  const handleSetBlacklist = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      setGlobalLoading(true);
      const formData = new FormData(e.target as HTMLFormElement);
      const data = Object.fromEntries(formData.entries());
      const { address, isEnabled } = data;
      const tx = await setBlacklist({
        address: address.toString(),
        isEnabled: !!isEnabled,
      });
      toast.success(`Set Blacklist Success: ${tx.hash}`);
      setGlobalLoading(false);
    } catch (error) {
      setGlobalLoading(false);
    }
  };

  const handleSetAdminToken = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      setGlobalLoading(true);
      const formData = new FormData(e.target as HTMLFormElement);
      const data = Object.fromEntries(formData.entries());
      const { address, isEnabled } = data;
      const tx = await setAdminToken({
        address: address.toString(),
        isEnabled: !!isEnabled,
      });
      toast.success(`Set Admin Success: ${tx.hash}`);
      setGlobalLoading(false);
    } catch (error) {
      setGlobalLoading(false);
    }
  };

  const handleSetBlacklistToken = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    try {
      e.preventDefault();
      setGlobalLoading(true);
      const formData = new FormData(e.target as HTMLFormElement);
      const data = Object.fromEntries(formData.entries());
      const { address, isEnabled } = data;
      const tx = await setBlacklistToken({
        address: address.toString(),
        isEnabled: !!isEnabled,
      });
      toast.success(`Set Blacklist Success: ${tx.hash}`);
      setGlobalLoading(false);
    } catch (error) {
      setGlobalLoading(false);
    }
  };

  const handleSetViolatorToken = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    try {
      e.preventDefault();
      setGlobalLoading(true);
      const formData = new FormData(e.target as HTMLFormElement);
      const data = Object.fromEntries(formData.entries());
      const { address, isEnabled } = data;
      const tx = await setViolatorToken({
        address: address.toString(),
        isEnabled: !!isEnabled,
      });
      toast.success(`Set Violator Success: ${tx.hash}`);
      setGlobalLoading(false);
    } catch (error) {
      setGlobalLoading(false);
    }
  };

  const handleSetMarketingWallet = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    try {
      e.preventDefault();
      setGlobalLoading(true);
      const formData = new FormData(e.target as HTMLFormElement);
      const data = Object.fromEntries(formData.entries());
      const { marketingWallet } = data;
      const tx = await setMarketingWallet({
        marketingWallet: marketingWallet.toString(),
      });
      toast.success(`Setting Marketing Wallet Success: ${tx.hash}`);
      setGlobalLoading(false);
    } catch (error) {
      setGlobalLoading(false);
    }
  };

  const handleWithdrawLockedToken = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    try {
      e.preventDefault();
      setGlobalLoading(true);
      const formData = new FormData(e.target as HTMLFormElement);
      const data = Object.fromEntries(formData.entries());
      const { token, to } = data;
      const tx = await withdrawLockedToken({
        token: token.toString(),
        to: to.toString(),
      });
      toast.success(`Withdrawing Locked token Success: ${tx.hash}`);
      setGlobalLoading(false);
    } catch (error) {
      setGlobalLoading(false);
    }
  };

  const handleWithdrawLockedEth = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    try {
      e.preventDefault();
      setGlobalLoading(true);
      const formData = new FormData(e.target as HTMLFormElement);
      const data = Object.fromEntries(formData.entries());
      const { to } = data;
      const tx = await withdrawLockedEth({
        to: to.toString(),
      });
      toast.success(`Withdrawing Locked Eth Success: ${tx.hash}`);
      setGlobalLoading(false);
    } catch (error) {
      setGlobalLoading(false);
    }
  };

  const handleLaunch = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      setGlobalLoading(true);
      const tx = await launch();
      toast.success(`Successfully Launched: ${tx.hash}`);
      setGlobalLoading(false);
    } catch (error) {
      setGlobalLoading(false);
    }
  };

  const handleSwapFee = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      setGlobalLoading(true);
      const tx = await swapFee();
      toast.success(`Successfully swaped Fee: ${tx.hash}`);
      setGlobalLoading(false);
    } catch (error) {
      setGlobalLoading(false);
    }
  };

  const handleSetSwapThreshold = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    try {
      e.preventDefault();
      setGlobalLoading(true);
      const formData = new FormData(e.target as HTMLFormElement);
      const data = Object.fromEntries(formData.entries());
      const { swapThreshold } = data;
      const tx = await setSwapThreshold({
        swapThreshold: swapThreshold.toString(),
      });
      toast.success(`Swapped Threshold Successfully Success: ${tx.hash}`);
      setGlobalLoading(false);
    } catch (error) {
      setGlobalLoading(false);
    }
  };

  const handleSetBuyTax = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      setGlobalLoading(true);
      const formData = new FormData(e.target as HTMLFormElement);
      const data = Object.fromEntries(formData.entries());
      const { buyTax } = data;
      const tx = await setBuyTax({
        buyTax: parseFloat(buyTax.toString()),
      });
      toast.success(`Setting Buy Tax Success: ${tx.hash}`);
      setGlobalLoading(false);
    } catch (error) {
      setGlobalLoading(false);
    }
  };

  const handleSetSellTax = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      setGlobalLoading(true);
      const formData = new FormData(e.target as HTMLFormElement);
      const data = Object.fromEntries(formData.entries());
      const { buyTax } = data;
      const tx = await setSellTax({
        sellTax: parseFloat(buyTax.toString()),
      });
      toast.success(`Setting Sell Tax Success: ${tx.hash}`);
      setGlobalLoading(false);
    } catch (error) {
      setGlobalLoading(false);
    }
  };

  const handleSetGatrUsd = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      setGlobalLoading(true);
      const formData = new FormData(e.target as HTMLFormElement);
      const data = Object.fromEntries(formData.entries());
      const { gatrUsd } = data;
      const tx = await setGatrUsd({
        gatrUsd: gatrUsd.toString(),
      });
      toast.success(`Set Gatr USD Success: ${tx.hash}`);
      setGlobalLoading(false);
    } catch (error) {
      setGlobalLoading(false);
    }
  };

  const handleSetTier = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      setGlobalLoading(true);
      const formData = new FormData(e.target as HTMLFormElement);
      const data = Object.fromEntries(formData.entries());
      const { basic, premium, advanced } = data;
      const tx = await setTier([
        basic.toString(),
        advanced.toString(),
        premium.toString(),
      ]);
      toast.success(`Set Tier Success: ${tx.hash}`);
      setGlobalLoading(false);
    } catch (error) {
      setGlobalLoading(false);
    }
  };

  const handleSetTiersGateLimit = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    try {
      e.preventDefault();
      setGlobalLoading(true);
      const formData = new FormData(e.target as HTMLFormElement);
      const data = Object.fromEntries(formData.entries());
      const { basic, premium, advanced } = data;
      const tx = await setTiersGateLimit([
        basic.toString(),
        advanced.toString(),
        premium.toString(),
      ]);
      toast.success(`Set Tiers Gate Limit Success: ${tx.hash}`);
      setGlobalLoading(false);
    } catch (error) {
      setGlobalLoading(false);
    }
  };

  const handleSetPrice = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      setGlobalLoading(true);
      const formData = new FormData(e.target as HTMLFormElement);
      const data = Object.fromEntries(formData.entries());
      const { basic, premium, advanced, promotion } = data;
      const tx = await setPrice([
        basic.toString(),
        advanced.toString(),
        premium.toString(),
        promotion.toString(),
      ]);

      toast.success(`Set Price Success: ${tx.hash}`);
      setGlobalLoading(false);
    } catch (error) {
      setGlobalLoading(false);
    }
  };

  const handleWithdraw = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      setGlobalLoading(true);
      const formData = new FormData(e.target as HTMLFormElement);
      const data = Object.fromEntries(formData.entries());
      const { token, amount, wallet } = data;
      const tx = await withdraw([
        token.toString(),
        amount.toString(),
        wallet.toString(),
      ]);
      toast.success(`Withdraw Success: ${tx.hash}`);
      setGlobalLoading(false);
    } catch (error) {
      setGlobalLoading(false);
    }
  };

  return (
    <section className={styles.contract_page}>
      <h1>Gate Registry Contract</h1>

      <div
        className={styles.forms}
        aria-disabled={!isContractAvailable || globalLoading}
      >
        <FormWrapper
          className={`${styles.form} radial_gradient`}
          onSubmit={handlePauseable}
        >
          <h3>Pause/Unpause</h3>
          <NoteBox
            className={styles.note_box}
            notes={[
              "Pause/Unpause contract functions",
              "Callable by Admin/Owner",
            ]}
          />
          <Button
            type="submit"
            loading={globalLoading}
            disabled={_paused === undefined}
          >
            {_paused === undefined
              ? "Loading..."
              : _paused
              ? "Unpause"
              : "Pause"}
          </Button>
        </FormWrapper>

        <FormWrapper
          className={`${styles.form} radial_gradient`}
          onSubmit={handleSetAdmin}
        >
          <h3>Set Admin</h3>
          <NoteBox
            className={styles.note_box}
            notes={[
              "Grant or Revoke Admin Access",
              "@input Address - Address of User",
            ]}
          />
          <Input
            name="address"
            label="Address"
            className={styles.input_box}
            required
          />
          <Dropdown
            name="isEnabled"
            label="Set"
            defaultValue="true"
            className={styles.input_box}
            required
          >
            <option value="true">Admin</option>
            <option value="false">Not Admin</option>
          </Dropdown>
          <Button type="submit" loading={globalLoading}>
            SET
          </Button>
        </FormWrapper>

        <FormWrapper
          className={`${styles.form} radial_gradient`}
          onSubmit={handleSetBlacklist}
        >
          <h3>Set Blacklist</h3>
          <NoteBox
            className={styles.note_box}
            notes={[
              "Blacklist or Whitelist a user",
              "@input Address - Address of User",
            ]}
          />
          <Input
            name="address"
            label="Address"
            className={styles.input_box}
            required
          />
          <Dropdown
            name="isEnabled"
            label="Set"
            defaultValue="true"
            className={styles.input_box}
            required
          >
            <option value="true">Blacklist</option>
            <option value="false">Remove Blacklist</option>
          </Dropdown>
          <Button type="submit" loading={globalLoading}>
            SET
          </Button>
        </FormWrapper>

        <FormWrapper
          className={`${styles.form} radial_gradient`}
          onSubmit={handleSetGatrUsd}
        >
          <h3>Set Gatr USD</h3>
          <NoteBox
            className={styles.note_box}
            notes={[
              "Set USD value of 1 0XGATR",
              "Callable only by admin/owner",
              "This function will be used to periodically update the USD equivalent of 1 0XGATR",
              "@input Gatr USD Amount - The USD value of $0XGATR (in ethers)",
            ]}
          />
          {_gatrUsd && <p>Current: {_gatrUsd}</p>}
          <Input
            name="gatrUsd"
            type="number"
            min={0}
            step="any"
            label="Gatr USD Amount"
            className={styles.input_box}
            required
          />
          <Button type="submit" loading={globalLoading}>
            SET
          </Button>
        </FormWrapper>

        <FormWrapper
          className={`${styles.form} radial_gradient`}
          onSubmit={handleSetTier}
        >
          <h3>Set Tier</h3>
          <NoteBox
            className={styles.note_box}
            notes={[
              "Set minimum balance requirement for tiers",
              "Callable only by owner",
              "@input 3 Tiers - Array of minimum USD balance for each tier (in ethers)",
            ]}
          />
          {
            <p>
              Basic: {_minTierBasic} Advanced: {_minTierAdvanced} Premium:{" "}
              {_minTierPremium}
            </p>
          }
          <Input
            name="basic"
            type="number"
            min={0}
            step="any"
            label="Basic Tier"
            className={styles.input_box}
            required
          />
          <Input
            name="advanced"
            type="number"
            min={0}
            label="Advanced Tier"
            className={styles.input_box}
            required
          />
          <Input
            name="premium"
            type="number"
            min={0}
            label="Premium Tier"
            className={styles.input_box}
            required
          />
          <Button type="submit" loading={globalLoading}>
            SET
          </Button>
        </FormWrapper>

        <FormWrapper
          className={`${styles.form} radial_gradient`}
          onSubmit={handleSetTiersGateLimit}
        >
          <h3>Set Tiers Gate Limit</h3>
          <NoteBox
            className={styles.note_box}
            notes={[
              "Set maximum gate limits for tiers",
              "Callable only by owner",
              "@input 3 Tiers - Array of maximum purchasable gates for each tier",
            ]}
          />
          {
            <p>
              Basic: {_tiersGateLimitBasic} Advanced: {_tiersGateLimitAdvanced}{" "}
              Premium: {_tiersGateLimitPremium}
            </p>
          }
          <Input
            name="basic"
            type="number"
            min={0}
            step="any"
            label="Basic Tier"
            className={styles.input_box}
            required
          />
          <Input
            name="advanced"
            type="number"
            min={0}
            label="Advanced Tier"
            className={styles.input_box}
            required
          />
          <Input
            name="premium"
            type="number"
            min={0}
            label="Premium Tier"
            className={styles.input_box}
            required
          />
          <Button type="submit" loading={globalLoading}>
            SET
          </Button>
        </FormWrapper>

        <FormWrapper
          className={`${styles.form} radial_gradient`}
          onSubmit={handleSetPrice}
        >
          <h3>Set Price</h3>
          <NoteBox
            className={styles.note_box}
            notes={[
              "Set Gate & Promotion price",
              "Callable only by owner",
              "@input 3 USD Prices - Array of USD price per Gate for each tier (in ethers)",
              "@input Promotion - USD price per Promotion (18 ethers)",
            ]}
          />
          {
            <p>
              BasicPrice: {_tierPriceBasic} AdvancedPrice: {_tierPriceAdvanced}{" "}
              PremiumPrice: {_tierPricePremium} PromotionPrice:{" "}
              {_promotionPrice}
            </p>
          }
          <Input
            name="basic"
            type="number"
            min={0}
            step="any"
            label="Basic Price"
            className={styles.input_box}
            required
          />
          <Input
            name="advanced"
            type="number"
            min={0}
            label="Advanced Price"
            className={styles.input_box}
            required
          />
          <Input
            name="premium"
            type="number"
            min={0}
            label="Premium Price"
            className={styles.input_box}
            required
          />
          <Input
            name="promotion"
            type="number"
            min={0}
            label="Promotion"
            className={styles.input_box}
            required
          />
          <Button type="submit" loading={globalLoading}>
            SET
          </Button>
        </FormWrapper>

        <FormWrapper
          className={`${styles.form} radial_gradient`}
          onSubmit={handleWithdraw}
        >
          <h3>Withdraw</h3>
          <NoteBox
            className={styles.note_box}
            notes={[
              "Withdraw tokens from contract",
              "Callable by owner",
              "@input Token - Address of token to withdraw",
              "@input Amount - Amount of token to withdraw",
              "@input Wallet - Address to withdraw amount to",
            ]}
          />
          <Input
            name="token"
            type="text"
            label="Token"
            className={styles.input_box}
            required
          />
          <Input
            name="amount"
            type="decimal"
            min={0}
            step="any"
            label="Amount"
            className={styles.input_box}
            required
          />
          <Input
            name="wallet"
            type="text"
            label="Wallet"
            className={styles.input_box}
            required
          />
          <Button type="submit" loading={globalLoading}>
            WITHDRAW
          </Button>
        </FormWrapper>
      </div>

      <h1>Gatr Token Contract</h1>

      <div
        className={styles.forms}
        aria-disabled={!isTokenContractAvailable || globalLoading}
      >
        <FormWrapper
          className={`${styles.form} radial_gradient`}
          onSubmit={handleLaunch}
        >
          <h3>Launch</h3>
          <NoteBox
            className={styles.note_box}
            notes={[
              "Enable trading",
              "Callable by owner",
              "Callable only once",
            ]}
          />
          <Button
            type="submit"
            loading={globalLoading}
            disabled={_swapEnabled || _swapEnabled === undefined}
          >
            {_swapEnabled === undefined
              ? "Loading..."
              : _swapEnabled
              ? "Launched"
              : "Launch"}
          </Button>
        </FormWrapper>

        <FormWrapper
          className={`${styles.form} radial_gradient`}
          onSubmit={handleSwapFee}
        >
          <h3>Swap Fee</h3>
          <NoteBox
            className={styles.note_box}
            notes={[
              "Manually swap the collected token fees/taxes to ETH",
              "Callable by owner",
            ]}
          />
          <Button type="submit" loading={globalLoading}>
            Swap
          </Button>
        </FormWrapper>

        <FormWrapper
          className={`${styles.form} radial_gradient`}
          onSubmit={handleSetAdminToken}
        >
          <h3>Set Admin</h3>
          <NoteBox
            className={styles.note_box}
            notes={[
              "Grant or Revoke Admin Access",
              "@input Address - Address of User",
            ]}
          />
          <Input
            name="address"
            label="Address"
            className={styles.input_box}
            required
          />
          <Dropdown
            name="isEnabled"
            label="Set"
            defaultValue="true"
            className={styles.input_box}
            required
          >
            <option value="true">Admin</option>
            <option value="false">Not Admin</option>
          </Dropdown>
          <Button type="submit" loading={globalLoading}>
            SET
          </Button>
        </FormWrapper>

        <FormWrapper
          className={`${styles.form} radial_gradient`}
          onSubmit={handleSetBlacklistToken}
        >
          <h3>Set Blacklist</h3>
          <NoteBox
            className={styles.note_box}
            notes={[
              "Blacklist or Whitelist a user",
              "@input Address - Address of User",
            ]}
          />
          <Input
            name="address"
            label="Address"
            className={styles.input_box}
            required
          />
          <Dropdown
            name="isEnabled"
            label="Set"
            defaultValue="true"
            className={styles.input_box}
            required
          >
            <option value="true">Blacklist</option>
            <option value="false">Remove Blacklist</option>
          </Dropdown>
          <Button type="submit" loading={globalLoading}>
            SET
          </Button>
        </FormWrapper>

        <FormWrapper
          className={`${styles.form} radial_gradient`}
          onSubmit={handleSetViolatorToken}
        >
          <h3>Set Violator</h3>
          <NoteBox
            className={styles.note_box}
            notes={[
              "Set/Unset user as Violator",
              "@input Address - Address of User",
              "@input Set - isViolator user?",
            ]}
          />
          <Input
            name="address"
            label="Address"
            className={styles.input_box}
            required
          />
          <Dropdown
            name="isEnabled"
            label="Set"
            defaultValue="true"
            className={styles.input_box}
            required
          >
            <option value="true">Violator</option>
            <option value="false">Remove Violator</option>
          </Dropdown>
          <Button type="submit" loading={globalLoading}>
            SET
          </Button>
        </FormWrapper>

        <FormWrapper
          className={`${styles.form} radial_gradient`}
          onSubmit={handleSetExcludedFromFees}
        >
          <h3>Set Excluded From Fees</h3>
          <NoteBox
            className={styles.note_box}
            notes={[
              "Exclude/Include addresses from fees/taxes",
              "Callable by owner",
              "@input Wallet - Wallet Address to exclude/include",
              "@input Set - isExcluded Should exclude from fee/tax?",
            ]}
          />
          <Input
            name="wallet"
            label="wallet"
            className={styles.input_box}
            required
          />
          <Dropdown
            name="isExcluded"
            label="Set"
            defaultValue="true"
            className={styles.input_box}
            required
          >
            <option value="true">Exclude</option>
            <option value="false">Include</option>
          </Dropdown>
          <Button type="submit" loading={globalLoading}>
            SET
          </Button>
        </FormWrapper>

        <FormWrapper
          className={`${styles.form} radial_gradient`}
          onSubmit={handleWithdrawLockedToken}
        >
          <h3>Withdraw Locked Token</h3>
          <NoteBox
            className={styles.note_box}
            notes={[
              "Withdraw locked/stuck ERC20 tokens",
              "Callable by owner",
              "@input Token - Address of token to withdraw",
              "@input To - Address to withdraw tokens to",
            ]}
          />
          <Input
            name="token"
            label="Token"
            className={styles.input_box}
            required
          />

          <Input name="to" label="To" className={styles.input_box} required />
          <Button type="submit" loading={globalLoading}>
            WITHDRAW
          </Button>
        </FormWrapper>

        <FormWrapper
          className={`${styles.form} radial_gradient`}
          onSubmit={handleWithdrawLockedEth}
        >
          <h3>Withdraw Locked Eth</h3>
          <NoteBox
            className={styles.note_box}
            notes={[
              "Withdraw locked/stuck ETH tokens",
              "Callable by owner",
              "@input Address - Address to withdraw ETH to",
            ]}
          />
          <Input name="to" label="To" className={styles.input_box} required />
          <Button type="submit" loading={globalLoading}>
            WITHDRAW
          </Button>
        </FormWrapper>

        <FormWrapper
          className={`${styles.form} radial_gradient`}
          onSubmit={handleSetMarketingWallet}
        >
          <h3>Set Marketing Wallet</h3>
          <NoteBox
            className={styles.note_box}
            notes={[
              "Set the `marketingWallet`",
              "Callable by owner",
              "@input wallet - 0XGATR marketing wallet address",
            ]}
          />
          {_marketingWallet && <p>Current: {_marketingWallet}</p>}
          <Input
            name="marketingWallet"
            label="wallet"
            className={styles.input_box}
            required
          />
          <Button type="submit" loading={globalLoading}>
            SET
          </Button>
        </FormWrapper>

        <FormWrapper
          className={`${styles.form} radial_gradient`}
          onSubmit={handleSetSwapThreshold}
        >
          <h3>Set Swap Threshold</h3>
          <NoteBox
            className={styles.note_box}
            notes={[
              "Set the `swapThreshold`",
              "Callable by owner",
              "Swap Threshold must be within limits",
              "@input Swap Threshold - Contract balance requirement before swapping fees to ETH",
            ]}
          />
          {_swapThreshold && <p>Current: {_swapThreshold}</p>}
          <Input
            name="swapThreshold"
            type="number"
            min={0}
            step="any"
            label="Swap Threshold"
            className={styles.input_box}
            required
          />
          <Button type="submit" loading={globalLoading}>
            SET
          </Button>
        </FormWrapper>

        <FormWrapper
          className={`${styles.form} radial_gradient`}
          onSubmit={handleSetBuyTax}
        >
          <h3>Set Buy Tax</h3>
          <NoteBox
            className={styles.note_box}
            notes={[
              "Set the `buyTax`",
              "Callable by owner",
              "Buy tax must be within limits",
              "@input Buy tax - Buy tax (1 decimal)",
            ]}
          />
          {_buyTax && <p>Current: {_buyTax}</p>}
          <Input
            name="buyTax"
            type="number"
            min={0}
            step="any"
            label="Buy tax"
            className={styles.input_box}
            required
          />
          <Button type="submit" loading={globalLoading}>
            SET
          </Button>
        </FormWrapper>

        <FormWrapper
          className={`${styles.form} radial_gradient`}
          onSubmit={handleSetSellTax}
        >
          <h3>Set Sell Tax</h3>
          <NoteBox
            className={styles.note_box}
            notes={[
              "Set the `sellTax`",
              "Callable by owner",
              "Sell tax must be within limits",
              "@input Sell tax - Sell tax (1 decimal)",
            ]}
          />
          {_sellTax && <p>Current: {_sellTax}</p>}
          <Input
            name="sellTax"
            type="number"
            min={0}
            step="any"
            label="Sell tax"
            className={styles.input_box}
            required
          />
          <Button type="submit" loading={globalLoading}>
            SET
          </Button>
        </FormWrapper>
      </div>
    </section>
  );
}
