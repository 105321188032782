import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { getComplaints, postComplaint, updateComplaintsById } from "../api";
import { AxiosResponse } from "axios";
import { ComplaintsResponse, ComplaintsUpdateRequest } from "../types/api.types";

export function usePostComplaint() {
    const { mutateAsync, isLoading } = useMutation(postComplaint, {
        onSuccess() {
            toast.success('Complaint Registered!', { autoClose: 5000 })
        },
        onError(error: any) {
            if (error.response.data) toast.error(error.response.data, { autoClose: 5000 })
            else if (error instanceof Error) toast.error(error.message, { autoClose: 5000 })
            else {
                toast.error('Errors while registering. Please check and try again.', { autoClose: 5000 })
            }
        },
    });
    return { post: mutateAsync, isLoading };
};

export function useGetComplaints(isLoading: any,isComplaintsLoading:any,params:any) {
    const query = useQuery<AxiosResponse<ComplaintsResponse>, Error>(['complaints_admin',isLoading,isComplaintsLoading,params], () => getComplaints(params), {
        onError(error: any) {
            if (error?.response?.data) toast.error(error.response.data, { autoClose: 5000 })
            else if (error instanceof Error) toast.error(error?.message, { autoClose: 5000 })
            else {
                toast.error('Errors while fetching promotion data. Please check and try again.', { autoClose: 5000 })
            }
            return error;
        },
        // enabled: !!gate_id,
        retry: 3,
        refetchOnWindowFocus: false,
    })

    return query;
}

export function useUpdateComplaintById() {
    const queryClient = useQueryClient();

    const query = useMutation<AxiosResponse<ComplaintsUpdateRequest | Error>, Error, ComplaintsUpdateRequest & { complaint_id: string; }, any>(async (data) => {
        try {
            const complaint_id = data.complaint_id;
            return await updateComplaintsById(complaint_id, data);
        } catch (error) {
            throw error;
        }
    }, {
        onSuccess() {
            queryClient.invalidateQueries('featured');
        },
        onError(error: any) {
            if (error?.response?.data) toast.error(error.response.data, { autoClose: 5000 })
            else if (error instanceof Error) toast.error(error?.message, { autoClose: 5000 })
            else {
                toast.error('Errors while updating featured. Please check and try again.', { autoClose: 5000 })
            }
            return error;
        },
        retry: false,
    },);

    return query;
}

