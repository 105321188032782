import React from 'react'
import styles from '../../styles/Gates/SampleFeatureDisplay.module.scss'
import { ExternalLinkIcon } from '../Icons'
import { PromotionProps } from '@/types/api.types';



const SampleFeatureDisplay = (props:PromotionProps) => {
    const [key,value] : any = props.attribute? props.attribute.split(":"): ["y","y"];

  return (
    <div className={`${styles.box}`}>
        <div className={styles.heading}>
            <img src={props.image} alt={'gate'} className={styles.image}/>

            <div className={styles.token}>
                <div>{props.token}</div>
                <div>${props.symbol}</div>
            </div>
            <div className={styles.balance}>
                <p className={styles.top}>BALANCE</p>
                <p className={styles.bottom}>&gt;={props.amount}</p>
            </div>
            { props.attribute && 
                <div className={styles.type}>
                <p className={styles.top}>{key}</p>
                <p className={styles.bottom}>{value}</p>
            </div>
            }
            
        </div>

        <div className={`${styles.body}`}>
            <h3>{props.title}</h3>
            <p>{props.description}</p>
        </div>

        <p className={`${styles.link}`}>VIEW CONTENT<ExternalLinkIcon /></p>
    </div>
  )
}

export default SampleFeatureDisplay