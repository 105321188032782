import { Suspense, useMemo, useState } from "react";
import { ExpanderComponentProps, TableColumn } from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Table,
} from "../../components";
import { BlockedIcon, CopyIcon, EditIcon, EyeCloseIcon, EyeIcon, UnBlockedIcon } from "../../components/Icons";
import { useGetComplaints, useUpdateComplaintById } from "../../hooks/complaints.hooks";
import { useUpdateGateById } from "../../hooks/gates.hooks";
import styles from "../../styles/Gates/ViewGates.module.scss";
import { ComplaintResponse } from "../../types/api.types";



export default function ComplaintsAdmin(): JSX.Element {
  const navigate = useNavigate();
  const { mutateAsync: updateLink, isLoading: isLinkEditing } =
useUpdateGateById();
const { mutateAsync: updateComplaint, isLoading: isComplaintUpdating } =
useUpdateComplaintById();
const [params,setParams] = useState({page:1, limit:10})
const { data, isLoading } = useGetComplaints(isLinkEditing,isComplaintUpdating,params);

const handlePageChange = (page:any) => {
  const limit = params.limit;
  setParams({page:page,limit:limit});

}

const ExpandedComponent: React.FC<ExpanderComponentProps<ComplaintResponse>> = ({data}) => {
  return (
    <div className={styles.expandedDiv}>
      <p><span>Email: </span>{data.email}</p>
      <p><span>Subject: </span>{data.subject}</p>
      <p><span>Description: </span>{data.message}</p>
    </div>
  )
}


const handlePerRowsChange = (newPerPage:any, page:any) => {
  setParams({page:page,limit:newPerPage})
};

function copyLink(text: string, prefix?: string) {
  navigator.clipboard.writeText(`${prefix || ""}${text}`);
  toast.success("Copied to clipboard");
}

  const column = useMemo(
    (): TableColumn<ComplaintResponse>[] => [
      {
        name: "Complaint ID",
        selector: (row) => row["_id"],
        maxWidth: "140px",
      },
      {
        name: "Status",
        selector: (row) => row["status"],
        width: "100px",
        format: (row) =>
          row["status"]==="pending" ? (
            <p className={`${styles.status} ${styles.blocked}`}>Pending</p>
          ) : (
            <p className={`${styles.status} green`}>Resolved</p>
          ),
      },
      {
        selector: (row) => row["_id"],
        cell: (row) => (
          <div
            className={styles.actions}
          >
            <button
              className={styles.edits}
              onClick={async () => {
                await updateComplaint({ complaint_id: row["_id"], status: row["status"]==="pending"?"resolved": "pending" });
              }}
              disabled={isComplaintUpdating}
            >
              {row["status"]==="resolved" ? <EyeCloseIcon /> : <EyeIcon />}
              {isComplaintUpdating}
            </button>
          </div>
        ),
        minWidth: "30px",
        maxWidth: "30px",
      },
      {
        name: "Gated Link",
        selector: (row) => row["gate"]["proxy_link"],
        maxWidth: "140px",
        format: (row) => (
          <Link to={`${process.env.REACT_APP_PROXY_URL}/${row["gate"]["proxy_link"]}`} target="_blank" className={styles.link}>
            {row["gate"]["proxy_link"]}
          </Link>
        ),
      },

      {
        name: "Email",
        selector: (row) => row["email"],
        format: (row) => (
          <>
            <div className={styles.actions}>
              <p className={styles.text}>{row["email"]}</p>
              <>
                <button
                  onClick={() => copyLink(row["email"])}
                  title="Copy to clipboard"
                  className={styles.edits}
                  disabled={isLoading}
                >
                  <CopyIcon />
                </button>
              </>
            </div>
          </>
        ),
        maxWidth: "320px",
      },

      {
        name: "Subject",
        selector: (row) => row["subject"],
        maxWidth: "240px",
      },

      {
        name: "Description",
        selector: (row) => row["message"],
        maxWidth: "340px",
      },
      {
        name: "Content URL",
        selector: (row) => row["gate"]["link"],
        format: (row) => (
          <Link to={`/gates/${row["gate"]["_id"]}/site`} className={styles.link}>
            Content Site
          </Link>
        ),
        maxWidth: "120px",
        wrap: true,
        style: {
          fontSize: "12px",
          wordBreak: "break-all",
        },
      },
      {
        name: "Gate Status",
        selector: (row) => row["gate"]["status"],
        width: "120px",
        format: (row) =>
        row["gate"]["status"] === "live" ? (
          <p className={`${styles.status} green`}>Live</p>
        ) : row["gate"]["status"] === "blocked" ? (
          <p className={`${styles.status} ${styles.blocked}`}>Blocked</p>
        ) : <p className={`${styles.status} orange`}>Disabled</p>
    },
      {
        selector: (row) => row["gate"]["_id"],
        cell: (row) => (
          <div
            className={styles.actions}
          >
            <button
              className={styles.edits}
              onClick={() => navigate(`/gates/update/${row["gate"]["_id"]}`)}
              disabled={isLinkEditing || row["gate"]["status"] === "blocked"}

            >
              <EditIcon />
            </button>
            <button
              className={styles.edits}
              onClick={async () => {
                await updateLink({ gate_id: row["gate"]["_id"], status: row["gate"]["status"]==="live"?"disabled": "live" });
              }}
              disabled={isLinkEditing || row["gate"]["status"] === "blocked"}
            >
              {row["gate"]["status"]==="live" ? <EyeCloseIcon /> : <EyeIcon />}
              {isLinkEditing}
            </button>
            <button
              className={styles.edits}
              onClick={async () => {
                await updateLink({ gate_id: row["gate"]["_id"], status: row["gate"]["status"]!=="blocked"?"blocked": "live" });
              }}
              disabled={isLinkEditing}
            >
              {row["gate"]["status"]!=="blocked" ? <UnBlockedIcon /> : <BlockedIcon />}
              {/* {isLinkEditing} */}
            </button>
          </div>
        ),
        minWidth: "140px",
        maxWidth: "340px",
      },

    ],
    [isLinkEditing,updateComplaint,isComplaintUpdating,updateLink,navigate]
  );

  return (
    <section className={styles.section}>
      <div className={styles.table_page}>
        <h1>COMPLAINTS</h1>
        <div className={styles.table_wrapper}>
          <Suspense fallback={<h1>Loading...</h1>}>
            <Table
              outerClassName={styles.table}
              loading={isLoading}
              columns={column}
              data={data?.data.complaints}
              keyField="_id"
              pagination
              paginationServer
              paginationPerPage={params.limit}
              paginationTotalRows={data?.data.totalComplaints}
              paginationDefaultPage={params.page}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              paginationComponentOptions={{
                onPageChange: handlePageChange,
              }}
              expandableRows
              expandableRowsComponent={ExpandedComponent}
            />
          </Suspense>

        </div>
      </div>
    </section>
  );
}
