import styles from "../../styles/Forms/Forms.module.scss";
import Loader from "../Loader";

export default function Dropdown(props: any): JSX.Element {
  const {
    children,
    extraChildren,
    className,
    disabled,
    icon,
    innerRef,
    label,
    loading,
    name,
    ...rest
  } = props;
  return (
    <div className={`${styles.input_group} ${className || ""}`}>
      {(label || icon) && (
        <label htmlFor={name} aria-disabled={disabled || loading}>
          {label && <p>{label}</p>}
          {icon && <i>{icon}</i>}
        </label>
      )}
      <div className={styles.dropdown_content}>
        <select
          id={name}
          name={name}
          {...rest}
          disabled={disabled || loading}
          ref={innerRef && innerRef}
        >
          {children}
        </select>
        {extraChildren}
      </div>
      {loading && <Loader />}
    </div>
  );
}
