import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { WagmiConfig } from "wagmi";
import App from "./App";
import { wagmiClient } from "./config/web3config";
import AuthProvider from "./context/user.context";
import WalletProvider from "./context/web3.context";
import reportWebVitals from "./reportWebVitals";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <WagmiConfig client={wagmiClient}>
        <WalletProvider>
          <AuthProvider> 
              <App /> 
          </AuthProvider>
        </WalletProvider>
      </WagmiConfig>
    </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals(console.log);
