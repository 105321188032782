import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "../../styles/Table.module.scss";
import { CopyIcon, CrossIcon, EditIcon, TickIcon } from "../Icons";
import Loader from "../Loader";

export default function EditableText(props: any): JSX.Element {
  const { className, loading, disabled, prefix, ...rest } = props;
  const originalText = props.text;
  const [editing, setEditing] = useState(false);
  const [text, setText] = useState(props.text);

  function handleCancel() {
    setEditing(false);
    setText(originalText);
  }

  async function handleSave() {
    try {
      if (text === originalText) return setEditing(false);
      if (text.length < 3)
        return toast.error("Text must be at least 3 characters long");
      setEditing(false);
      await props.onSave(text);
    } catch (error) {
      console.log(error);
      toast.error(`Error saving ${props.label}`);
    }
  }

  function copyLink() {
    navigator.clipboard.writeText(`${prefix || ""}${props.text}`);
    toast.success("Copied to clipboard");
  }

  return (
    <div className={`${styles.editable_text} ${className || ""}`} {...rest}>
      {editing ? (
        <>
          <input
            type="text"
            value={text}
            onChange={(e) => setText(e.target.value.replace(/\s+/g, '-'))}
            className={styles.text}
          />
          {props.loading && <Loader className={styles.loader} />}
        </>
      ) : (
        <Link
          className={styles.text}
          to={`${prefix}${text}`}
          target="_blank"
          aria-disabled={loading}
        >{prefix ? `/${text}` : text}</Link>
      )}
      <div className={styles.icons}>
        {editing ? (
          <>
            <button
              onClick={handleSave}
              className={styles.icon}
              disabled={loading || disabled}
            >
              <TickIcon />
            </button>
            <button
              onClick={handleCancel}
              className={`${styles.icon} ${styles.cross_red}`}
              disabled={loading || disabled}
            >
              <CrossIcon />
            </button>
          </>
        ) : (
          <>
            <button
              className={styles.icon}
              onClick={() => setEditing(true)}
              title="Edit"
              disabled={loading || disabled}
            >
              <EditIcon />
            </button>
            <button
              onClick={copyLink}
              title="Copy to clipboard"
              className={styles.icon}
              disabled={loading}
            >
              <CopyIcon />
            </button>
          </>
        )}
      </div>
    </div>
  );
}
