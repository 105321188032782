import { Suspense, useMemo, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { toast } from "react-toastify";
import { getUsers } from "../../api";
import {
  Button,
  EditableDropdown,
  EditableText,
  Loader,
  Table,
} from "../../components";
import { EyeCloseIcon, EyeIcon } from "../../components/Icons";
import { useGetUsers, useUpdateUserById } from "../../hooks/users.hooks";
import styles from "../../styles/Gates/ViewGates.module.scss";
import { UserResponse } from "../../types";

export default function Users(): JSX.Element {
  const [page, setPage] = useState(1);
  const {
    isFetching,
    data,
    refetch: refetchUsers,
  } = useGetUsers({ page, limit: 10 });

  const { isLoading: isEmailEditing, mutateAsync: updateEmail } =
    useUpdateUserById();
  const { isLoading: isAddressEditing, mutateAsync: updateAddress } =
    useUpdateUserById();
  const { isLoading: isRoleEditing, mutateAsync: updateUseRole } =
    useUpdateUserById();
  const { isLoading: isBlockedLoading, mutateAsync: updateBlocked } =
    useUpdateUserById();

  const column = useMemo(
    (): TableColumn<UserResponse>[] => [
      {
        name: "User ID",
        selector: (row) => row["_id"],
        width: "200px",
      },
      {
        name: "Email",
        selector: (row) => row["email"],
        maxWidth: "340px",
        format: (row) => (
          <EditableText
            className={styles.link_edit}
            text={row["email"]}
            onSave={async (text: string) => {
              await updateEmail({ _id: row["_id"], email: text });
            }}
            label="Email"
            loading={isEmailEditing}
            disabled={isAddressEditing || isRoleEditing || isBlockedLoading}
          />
        ),
      },
      {
        name: "Address",
        selector: (row) => row["address"],
        maxWidth: "400px",
        // cell: (row) => (
        //   <EditableText
        //     className={styles.link_edit}
        //     text={row["address"]}
        //     onSave={async (text: string) => {
        //       await updateAddress({ _id: row["_id"], address: text });
        //     }}
        //     label="Address"
        //     prefix=""
        //     loading={isAddressEditing}
        //     disabled={isEmailEditing || isRoleEditing || isBlockedLoading}
        //   />
        // ),
      },
      {
        name: "Role",
        width: "160px",
        selector: (row) => row["role"],
        format: (row) => (
          <EditableDropdown
            className={styles.link_edit}
            text={row["role"]}
            onSave={async (text: "admin" | "user") => {
              await updateUseRole({ _id: row["_id"], role: text });
            }}
            loading={isRoleEditing}
            disabled={isEmailEditing || isAddressEditing || isBlockedLoading}
          >
            <option value="admin">Admin</option>
            <option value="user">User</option>
          </EditableDropdown>
        ),
      },
      {
        name: "Blocked",
        width: "120px",
        selector: (row) => row["blocked"],
        cell: (row) => (
          <div className={styles.actions}>
            {!row["blocked"] ? (
              <p className={`${styles.status} green`}>Active</p>
            ) : (
              <p className={`${styles.status} ${styles.blocked}`}>Blocked</p>
            )}
            <button
              className={styles.edits}
              onClick={async () => {
                await updateBlocked({
                  _id: row["_id"],
                  blocked: !row["blocked"],
                });
              }}
              disabled={isEmailEditing || isAddressEditing || isRoleEditing}
            >
              {row["blocked"] ? <EyeCloseIcon /> : <EyeIcon />}
              {isBlockedLoading && <Loader className={styles.edit_loader} />}
            </button>
          </div>
        ),
        minWidth: "240px",
        maxWidth: "540px",
      },
      {
        name: "Created At",
        width: "200px",
        selector: (row) => row["createdAt"],
        sortable: true,
      },
    ],
    [
      isAddressEditing,
      isEmailEditing,
      isRoleEditing,
      updateAddress,
      updateEmail,
      updateUseRole,
      updateBlocked,
      isBlockedLoading,
    ]
  );

  const handlePageChange = async (page: number) => {
    setPage(page);
    await refetchUsers();
  };

  const handleDownload = async (e: React.MouseEvent<HTMLButtonElement>) => {
    try {
      e.preventDefault();
      const res = (await getUsers({
        file: "csv",
      })) as any;
      const url = window.URL.createObjectURL(new Blob([res?.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "users.csv");
      document.body.appendChild(link);
      link.click();
    } catch (error: any) {
      console.log(error);
      toast.error(error.message);
    }
  };

  return (
    <section className={styles.section}>
      <div className={styles.table_page}>
        <h1>USERS</h1>
        <div className={styles.table_wrapper}>
          <Suspense fallback={<h1>Loading...</h1>}>
            <Table
              outerClassName={styles.table}
              loading={isFetching}
              columns={column}
              data={data?.data.users}
              keyField="_id"
              pagination
              paginationServer
              paginationTotalRows={data?.data.totalUsers || 0}
              paginationDefaultPage={page}
              paginationPerPage={10}
              onChangePage={handlePageChange}
            />
          </Suspense>
          <div className={styles.extra}>
            <Button
              className={styles.new_gate_btn}
              onClick={handleDownload}
              loading={isFetching}
              disabled={!data?.data}
            >
              DOWNLOAD
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}
