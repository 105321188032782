import styles from "../../styles/Forms/Forms.module.scss";
import Loader from "../Loader";

export default function Input(props: any): JSX.Element {
  const { className, loading, disabled, innerRef, error, ...rest } = props;
  return (
    <div className={`${styles.input_group} ${className || ""}`}>
      <label htmlFor={rest?.name} aria-disabled={disabled || loading}>
        <div className={styles.label}>
          {rest?.label && <p>{rest.label}</p>}
          {rest?.icon && <i>{rest?.icon}</i>}
        </div>
      </label>
      {rest?.type === "textarea" ? (
        <textarea
          id={rest?.name}
          {...rest}
          ref={innerRef && innerRef}
          aria-errormessage={error}
          disabled={disabled || loading}
        />
      ) : (
        <input
          id={rest?.name}
          {...rest}
          ref={innerRef && innerRef}
          aria-errormessage={error}
          disabled={disabled || loading}
        />
      )}
      {loading && <Loader />}
    </div>
  );
}
