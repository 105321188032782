import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { checkTokenAddress, getDriveDownloadLink, getNFTAttributes } from "../api";

export function useTokenCheck(address: string, token: "ERC20" | "ERC721") {
    const { isFetching, data, isError, status, refetch } = useQuery({
        queryKey: ['tokenCheck', address, token],
        queryFn: () => checkTokenAddress(address, token),
        retry: 2,
        enabled: false,
        keepPreviousData: true,
        staleTime: 1000 * 60 * 60 * 24 * 7,
        refetchOnWindowFocus: false,
    });
    return { isOk: data, isCheckLoading: isFetching, check: refetch, isTokenError: isError, tokenStatus: status };
}

export function useNFTAttributes(address: string, trait_value?: string) {
    const { isLoading, data, refetch } = useQuery({
        queryKey: ['nftAttributes', address, trait_value],
        queryFn: () => getNFTAttributes(address, trait_value),
        onError(error: any) {
            if (error?.response?.data) toast.error(error.response.data, { autoClose: 5000 })
            else if (error instanceof Error) toast.error(error?.message, { autoClose: 5000 })
            else {
                toast.error('Errors while fetching token attributes. Please check and try again.', { autoClose: 5000 })
            }
        },
        enabled: false,
        retry: 1,
        refetchOnWindowFocus: false,
    });
    return { data, isLoading, refetch };
}

export function useDriveDownloadLink(link: string, enabled: boolean = true) {
    const { isLoading, data, refetch, isError } = useQuery({
        queryKey: ['driveDownloadLink', link],
        queryFn: () => getDriveDownloadLink(link),
        enabled,
        retry: 1,
        cacheTime: 1000 * 60 * 60 * 24 * 7,
        staleTime: 1000 * 60 * 60 * 24 * 7,
        refetchOnWindowFocus: false,
    });
    return { data, isLoading, refetch, isError };
}
