import DataTable, {
  TableStyles,
  Theme,
  createTheme,
} from "react-data-table-component";

const theme: Theme = createTheme(
  "table_dark",
  {
    text: {
      primary: "rgba(255, 255, 255, 1)",
      secondary: "rgba(248, 248, 248, 1)",
    },
    background: {
      default: "rgba(146, 184, 98, 0.01)",
    },
    context: {
      text: "rgba(248, 248, 248, 1)",
    },
    button: {
      color: "rgba(248, 248, 248, 1)",
      fill: "rgba(248, 248, 248, 1)",
      "&:focus": {
        backgroundColor: "rgba(114, 255, 28, 1)",
      },
    },
    highlightOnHover: {
      default: "rgba(114, 255, 28, 0.2)",
      text: "rgba(248, 248, 248, 1)",
    },
    striped: {
      default: "rgba(114, 255, 28, 0.2)",
      text: "rgba(248, 248, 248, 1)",
    },
  },
  "dark"
);

const customStyles: TableStyles = {
  table: {
    style: {
      color: theme.text.primary,
      borderRadius: "8px",
    },
  },
  tableWrapper: {
    style: {
      display: "table",
      borderRadius: "8px",
    },
  },
  header: {
    style: {
      marginBottom: "4px",
      borderRadius: "8px",

    },
  },
  head: {
    style: {
      color: theme.text.primary,
      fontSize: "16px",
      fontWeight: 500,
      borderRadius: "8px",
      backgroundColor: "#2e4a27",
      zIndex:2
    },
  },
  headRow: {
    style: {
      minHeight: "56px",
      border: "none",
      borderTopLeftRadius: "8px",
      borderTopRightRadius: "8px",
    },
    denseStyle: {
      minHeight: "32px",
    },
  },
  headCells: {
    style: {
      justifyContent: "center",
    },
    draggingStyle: {
      cursor: "move",
    },
  },
  rows: {
    style: {
      border: "none",
      borderRadius: "8px",
      backgroundColor: "rgba(114, 255, 28, 0.02)",
      marginTop: "4px",
      "&:not(:first-of-type)": {
        marginTop: "2px",
      },
      "&:not(:last-of-type)": {
        borderBottomRadius: "8px",
        borderBottom: "none",
      },
      "&:(:last-of-type)": {
        borderBottomLeftRadius: "0px",
        borderBottomRightRadius: "0px",
      },
    },
  },
  cells: {
    style: {
      justifyContent: "center",
      alignItems: "center",
      wordBreak: "none",
    },
  },
  noData: {
    style: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: theme.text.primary,
      backgroundColor: theme.background.default,
    },
  },
  progress: {
    style: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: theme.text.primary,
      backgroundColor: theme.background.default,
    },
  },
};

export default function Table(props: any) {
  return (
    <div className={props.outerClassName || ""}>
      <DataTable
        {...props}
        fixedHeader
        fixedHeaderScrollHeight="400px"
        highlightOnHover
        theme="table_dark"
        progressPending={props.loading}
        customStyles={customStyles}
      />
    </div>
  );
}
