import { Outlet } from "react-router-dom";

export default function Admin(): JSX.Element {
  return (
    <>
      <Outlet />
    </>
  );
}

export { default as Contract } from "./Contract";
export { default as PromotionsAdmin } from "./Promotions";
export { default as Users } from "./Users";
export { default as ViewGatesAdmin } from "./ViewGates";
export {default as ComplaintsAdmin} from "./Complaints"
