import { Suspense, useEffect, useMemo, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  ButtonWithTier,
  EditableText,
  Loader,
  Table,
} from "../../components";
import { EditIcon, EyeCloseIcon, EyeIcon } from "../../components/Icons";
import { useWallet } from "../../context/web3.context";
import { useGetMyGates, useUpdateGateById } from "../../hooks/gates.hooks";
import { useGetPromotionByGate } from "../../hooks/promotions.hooks";
import styles from "../../styles/Gates/ViewGates.module.scss";
import { GateResponse } from "../../types";

export default function ViewGates(): JSX.Element {
  const navigate = useNavigate();
  const [gateId, setGateId] = useState<string | undefined>(undefined);
  const { userTierData } = useWallet();
  const [params, setParams] = useState({ page: 1, limit: 10 });
  const { mutateAsync: updateLink, isLoading: isLinkEditing } =
    useUpdateGateById();
  const {
    data,
    isFetching: isLoading,
    refetch: refetchMyGates,
  } = useGetMyGates(params, isLinkEditing);
  const promotionQuery = useGetPromotionByGate(gateId!);

  const handlePageChange = (page: any) => {
    const limit = params.limit;
    setParams({ page: page, limit: limit });
  };

  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setParams({ page: page, limit: newPerPage });
  };

  useEffect(() => {
    if (gateId) {
      if (promotionQuery?.data && promotionQuery?.isSuccess)
        navigate(`/gates/promote/update/${gateId}`);
      else if (promotionQuery?.error?.response?.status === 404)
        navigate(`/gates/promote/${gateId}`);
    }
  }, [promotionQuery]);

  const handlePromote = (id: string) => {
    setGateId(id);
  };

  const column = useMemo(
    (): TableColumn<GateResponse>[] => [
      {
        name: "Gate ID",
        selector: (row) => row["_id"],
        maxWidth: "140px",
      },
      {
        name: "Status",
        selector: (row) => row["status"],
        width: "180px",
        cell: (row) => (
          <div className={styles.actions}>
            <p className={styles.value}>
              {row["status"] === "live" ? (
                <p className={`${styles.status} green`}>Live</p>
              ) : row["status"] === "blocked" ? (
                <p className={`${styles.status} ${styles.blocked}`}>Blocked</p>
              ) : (
                <p className={`${styles.status} orange`}>Unpublished</p>
              )}
            </p>
            <button
              className={styles.edits}
              onClick={async () => {
                try {
                  await updateLink({
                    gate_id: row["_id"],
                    status: row["status"] === "live" ? "disabled" : "live",
                  });
                } catch (err) {
                  console.log(err);
                }
              }}
              disabled={
                !userTierData || isLinkEditing || row["status"] === "blocked"
              }
            >
              {row["status"] === "live" ? <EyeCloseIcon /> : <EyeIcon />}
            </button>
          </div>
        ),
      },
      {
        name: "Gated Link",
        selector: (row) => row["proxy_link"],
        maxWidth: "200px",
        style: {
          fontWeight: "500",
        },
        cell: (row) => (
          <EditableText
            className={styles.link_edit}
            text={row["proxy_link"]}
            onSave={async (text: string) => {
              await updateLink({ gate_id: row["_id"], proxy_link: text });
              await refetchMyGates();
            }}
            label="Gated Link"
            prefix={`${process.env.REACT_APP_PROXY_URL}/`}
            loading={isLinkEditing}
            disabled={
              !userTierData ||
              userTierData.tier === "Basic" ||
              userTierData.tier === "Ineligible" ||
              row["status"] === "blocked"
            }
          />
        ),
      },
      {
        name: "Token",
        selector: (row) => row["token"],
        maxWidth: "180px",
        cell: (row) => (
          <div className={styles.actions}>
            <p className={styles.text}>
              <Link
                to={`https://${
                  process.env.REACT_APP_NODE_ENV === "development"
                    ? "sepolia."
                    : ""
                }etherscan.io/address/${row.contract}`}
                target="_blank"
                className={styles.link}
              >
                {row.symbol}
              </Link>{" "}
              ({row.token})
            </p>
            <button
              className={styles.edits}
              onClick={() => navigate(`/gates/update/${row["_id"]}`)}
            >
              <EditIcon />
            </button>
          </div>
        ),
      },
      {
        name: "Minimum",
        selector: (row) =>
          row["conditions"]["amount"]?.toLocaleString("en", {
            useGrouping: true,
          }),
        maxWidth: "120px",
        cell: (row) => (
          <div className={styles.actions}>
            <p className={styles.value}>
              {row["conditions"]["amount"]?.toLocaleString("en", {
                useGrouping: true,
              })}
            </p>
            <button
              className={styles.edits}
              onClick={() => navigate(`/gates/update/${row["_id"]}`)}
            >
              <EditIcon />
            </button>
          </div>
        ),
      },
      {
        name: "Attribute",
        selector: (row) => row["conditions"]["attribute"] || "NA",
        maxWidth: "180px",
        wrap: true,
        style: {
          textTransform: "capitalize",
        },
      },
      {
        name: "Content",
        selector: (row) => row["_id"],
        format: (row) => (
          <Link
            to={`/gates/${row._id}/site`}
            className={styles.link}
            aria-disabled={row["status"] === "blocked"}
          >
            Create/Edit
          </Link>
        ),
        maxWidth: "140px",
        wrap: true,
        style: {
          fontSize: "12px",
          wordBreak: "break-all",
        },
      },
      {
        selector: (row) => row["_id"],
        cell: (row) => (
          <div className={styles.actions} aria-disabled={!userTierData}>
            <ButtonWithTier
              tier="Premium"
              value="PROMOTE"
              className={styles.promote}
              onClick={() => handlePromote(row["_id"])}
              disabled={
                !userTierData?.tier ||
                userTierData?.tier === ("Basic" || "Ineligible") ||
                row["status"] !== "live"
              }
            />
          </div>
        ),
        minWidth: "100px",
        maxWidth: "540px",
      },
    ],
    [isLinkEditing, updateLink, navigate, userTierData]
  );

  return (
    <section className={styles.section}>
      <div className={styles.table_page}>
        <h1>GATES</h1>
        <div className={styles.table_wrapper}>
          <Suspense fallback={<h1>Loading...</h1>}>
            {(promotionQuery.isLoading || isLinkEditing) && (
              <div className={styles.loader}>
                <Loader darken />
              </div>
            )}
            <Table
              outerClassName={styles.table}
              loading={isLoading}
              columns={column}
              data={data?.data?.gates}
              keyField="_id"
              pagination
              paginationServer
              paginationPerPage={params.limit}
              paginationTotalRows={data?.data.totalGates}
              paginationDefaultPage={params.page}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              paginationComponentOptions={{
                onPageChange: handlePageChange,
              }}
              disabled={promotionQuery.isLoading || isLinkEditing}
            />
          </Suspense>
          <div className={styles.extra}>
            <div className={styles.stats}>
              <div className={styles.stat_card}>
                <span>GATES CREATED</span>
                <p>{data?.data ? data?.data.gates.length : 0}</p>
              </div>
              <div className={styles.stat_card}>
                <span>GATES LIMIT</span>
                <p className="green">
                  {userTierData
                    ? userTierData.gateLimit > 10
                      ? "Unlimited"
                      : userTierData.gateLimit.toLocaleString()
                    : "Loading..."}
                </p>
              </div>
            </div>
            <Button
              className={styles.new_gate_btn}
              onClick={() => navigate("/gates/mint")}
              loading={isLoading}
              disabled={
                !userTierData || !data?.data
                  ? true
                  : data?.data.gates.length >= userTierData.gateLimit
              }
            >
              NEW GATE
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}
