import { BigNumber } from "ethers";
import { NODE_ENV } from "./react.types";

enum TYPES {
    GATE_REGISTRY = "GateRegistry",
    GATR_TOKEN = "GatrToken"
};

enum TOKEN_TYPES {
    ERC20 = "ERC20",
    ERC721 = "ERC721",
};

enum TIERS { INELIGIBLE, BASIC, ADVANCED, PREMIUM };

type Tier = "Ineligible" | "Basic" | "Advanced" | "Premium";

type UserTierData = {
    tier: Tier;
    gateLimit: number;
    gatePrice: string | BigNumber;
    promotionPrice: string | BigNumber;
}

type Token = {
    "id": number;
    "contract": string;
    "decimal"?: number;
    "gasLimit"?: number;
};

type Chains = "bsc" | "eth";

type Contracts = {
    [key in NODE_ENV]: {
        [key in TYPES]: {
            [key in Chains]: Token;
        };
    };
};

type SetAdminArgs = {
    address: string;
    isEnabled: boolean;
};

type SetTierArgs = [
    basic: string,
    premium: string,
    advanced: string,
];

type SetTiersGateLimitArgs = [
    basic: string,
    premium: string,
    advanced: string,
];

type SetPriceArgs = [
    basic: string,
    premium: string,
    advanced: string,
    _promotion: string
]

type WithdrawArgs = [
    token: string,
    amount: string,
    wallet: string,
];

type GatePaymentArgs = [
    token: string,
    contract: string,
    amount: number,
    attribute: string,
    gateId: string,
];

type PromoteArgs = [
    title: string,
    description: string,
    image_url: string,
    gateId: string,
];

enum FUNCTIONS {
    GATE_PAYMENT = "gatePayment",
    PROMOTE = "promotionPayment",
};

enum PAYMENT_TYPE { NEW, UPDATE };

export { Chains, Contracts, FUNCTIONS, GatePaymentArgs, PAYMENT_TYPE, PromoteArgs, SetAdminArgs, SetTierArgs, SetTiersGateLimitArgs, SetPriceArgs, TIERS, TOKEN_TYPES, TYPES, Tier, Token, UserTierData, WithdrawArgs };
