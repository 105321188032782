import { Web3Modal } from "@web3modal/react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Footer, Navbar, ProtectedRoute } from "./components";
import { ethereumClient } from "./config/web3config";
import {
  Admin,
  Auth,
  Contract,
  CreateSite,
  Gates,
  Home,
  MintGate,
  PromoteGate,
  Promotions,
  SitePreview,
  UpdateGate,
  UpdatePromoteGate,
  Users,
  ViewGates,
  ViewGatesAdmin,
} from "./pages";
import { Login, SignUp } from "./pages/Auth";
import "./styles/fonts.scss";
import "./styles/globals.scss";
import { EclipseLeft, EclipseRight } from "./components/Shapes";
import { ComplaintsAdmin, PromotionsAdmin } from "./pages/Admin";

function App() {
  return (
    <>
      <Navbar />
      <EclipseLeft />
      <EclipseRight />
      <div className="bg_gradient"></div>
      <main>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/gates"
            element={<ProtectedRoute children={<Gates />} />}
          >
            <Route path="" element={<ViewGates />} />
            <Route path="mint" element={<MintGate />} />
            <Route path="promote/:gate_id" element={<PromoteGate />} />
            <Route
              path="promote/update/:gate_id"
              element={<UpdatePromoteGate />}
            />
            <Route path="update/:gate_id" element={<UpdateGate />} />
            <Route path=":gate_id/site" element={<CreateSite />} />
            <Route path=":gate_id/site/preview" element={<SitePreview />} />
          </Route>
          <Route path="/promotions" element={<Promotions />} />
          <Route
            path="/admin"
            element={<ProtectedRoute admin children={<Admin />} />}
          >
            <Route path="contract" element={<Contract />} />
            <Route path="gates" element={<ViewGatesAdmin />} />
            <Route path="users" element={<Users />} />
            <Route path="promotions" element={<PromotionsAdmin />} />
            <Route path="complaints" element={<ComplaintsAdmin />} />


          </Route>
          <Route path="/auth" element={<Auth />}>
            <Route path="" element={<Login />} />
            <Route path="sign-up" element={<SignUp />} />
          </Route>
        </Routes>
      </main>
      <Footer />

      <ToastContainer />

      <Web3Modal
        projectId={process.env.REACT_APP_WEB3_PROJECT_ID as string}
        ethereumClient={ethereumClient}
        themeVariables={{
          "--w3m-accent-color": "#72ff1c",
          "--w3m-accent-fill-color": "#000000",
          "--w3m-logo-image-url": "/0xgatr-icon.png",
        }}
      />
    </>
  );
}

export default App;
