import { useState } from "react";
import { toast } from "react-toastify";
import styles from "../../styles/Table.module.scss";
import { CrossIcon, EditIcon, TickIcon } from "../Icons";
import Loader from "../Loader";

export default function EditableDropdown(props: any): JSX.Element {
  const { className, loading, disabled, prefix, options, children, ...rest } =
    props;
  const originalText = props.text;
  const [editing, setEditing] = useState(false);
  const [text, setText] = useState(props.text);

  function handleChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setText(e.target.value);
  }

  function handleCancel() {
    setEditing(false);
    setText(originalText);
  }

  async function handleSave() {
    try {
      if (text === originalText) return setEditing(false);
      if (text.length < 3)
        return toast.error("Text must be at least 3 characters long");
      setEditing(false);
      await props.onSave(text);
    } catch (error) {
      console.log(error);
      toast.error(`Error saving ${props.label}`);
    }
  }

  return (
    <div className={`${styles.editable_text} ${className || ""}`} {...rest}>
      {editing ? (
        <>
          <select
            value={text}
            onChange={handleChange}
            disabled={disabled}
            className={styles.dropdown}
          >
            {options
              ? options.map((option: any) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))
              : children}
          </select>
          {loading && <Loader className={styles.loader} />}
        </>
      ) : (
        <p className={styles.text} aria-disabled={loading}>
          {text}
        </p>
      )}
      <div className={styles.icons}>
        {editing ? (
          <>
            <button
              onClick={handleSave}
              className={styles.icon}
              disabled={loading || disabled}
            >
              <TickIcon />
            </button>
            <button
              onClick={handleCancel}
              className={`${styles.icon} ${styles.cross_red}`}
              disabled={loading || disabled}
            >
              <CrossIcon />
            </button>
          </>
        ) : (
          <button
            className={styles.icon}
            onClick={() => setEditing(true)}
            title="Edit"
            disabled={loading || disabled}
          >
            <EditIcon />
          </button>
        )}
      </div>
    </div>
  );
}
