import styles from "../styles/Shapes.module.scss";

export function EclipseHero1({
  className = "",
  key = "",
  id = "",
  onClick,
}: {
  className?: string;
  key?: string;
  id?: string;
  onClick?: () => void | undefined;
}): JSX.Element {
  return (
    <svg
      className={styles.eclipseHero1}
      width="70vw"
      height="58vw"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="50%"
        cy="50%"
        rx="35vw"
        ry="29vw"
        strokeWidth="2"
        stroke="url(#eclipseBorderGradient)"
        fill="none"
      />
      <defs>
        <linearGradient
          id="eclipseBorderGradient"
          x1="73"
          y1="6.50001"
          x2="956.5"
          y2="705.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#015828" />
          <stop offset="1" stopColor="#71C93D" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function EclipseHero2({
  className = "",
  key = "",
  id = "",
  onClick,
}: {
  className?: string;
  key?: string;
  id?: string;
  onClick?: () => void | undefined;
}): JSX.Element {
  return (
    <svg
      className={styles.eclipseHero2}
      width="68vw"
      height="52vw"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="50%"
        cy="50%"
        rx="34vw"
        ry="26vw"
        strokeWidth="2"
        stroke="url(#eclipseBorderGradient)"
        fill="none"
      />
      <defs>
        <linearGradient
          id="eclipseBorderGradient"
          x1="73"
          y1="6.50001"
          x2="956.5"
          y2="705.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#015828" />
          <stop offset="1" stopColor="#71C93D" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function EclipseLeft({
  className = "",
  key = "",
  id = "",
  onClick,
}: {
  className?: string;
  key?: string;
  id?: string;
  onClick?: () => void | undefined;
}): JSX.Element {
  return (
    <svg
      className={styles.eclipseLeft}
      width="505"
      height="988"
      viewBox="0 0 505 988"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <path
          d="M-637.528 563.896C-760.167 330.518 -654.028 33.1511 -400.222 -100.223C-146.416 -233.596 158.689 -152.336 281.328 81.0417C403.967 314.42 297.828 611.786 44.0225 745.16C-209.783 878.534 -514.889 797.274 -637.528 563.896Z"
          stroke="url(#paint0_linear_60_395)"
          strokeWidth="2"
        />
        <path
          d="M-435.17 506.134C-546.817 293.673 -472.383 34.8487 -269.039 -72.0072C-65.6959 -178.863 189.684 -93.3542 301.331 119.106C412.978 331.566 338.543 590.391 135.2 697.247C-68.1434 804.103 -323.523 718.594 -435.17 506.134Z"
          stroke="url(#paint1_linear_60_395)"
          strokeWidth="2"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_60_395"
          x1="238.96"
          y1="419.612"
          x2="-868.288"
          y2="211.829"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#015828" />
          <stop offset="1" stopColor="#71C93D" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_60_395"
          x1="281.625"
          y1="417.343"
          x2="-656.036"
          y2="299.574"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#015828" />
          <stop offset="1" stopColor="#71C93D" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function EclipseRight({
  className = "",
  key = "",
  id = "",
  onClick,
}: {
  className?: string;
  key?: string;
  id?: string;
  onClick?: () => void | undefined;
}): JSX.Element {
  return (
    <svg
      className={styles.eclipseRight}
      width="745"
      height="870"
      viewBox="0 0 745 870"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <path
          d="M223.472 906.896C100.833 673.518 206.972 376.151 460.778 242.777C714.584 109.404 1019.69 190.664 1142.33 424.042C1264.97 657.42 1158.83 954.786 905.023 1088.16C651.217 1221.53 346.111 1140.27 223.472 906.896Z"
          stroke="url(#paint0_linear_60_392)"
          strokeWidth="2"
        />
        <path
          d="M425.83 849.134C314.183 636.673 388.617 377.849 591.961 270.993C795.304 164.137 1050.68 249.646 1162.33 462.106C1273.98 674.566 1199.54 933.391 996.2 1040.25C792.857 1147.1 537.477 1061.59 425.83 849.134Z"
          stroke="url(#paint1_linear_60_392)"
          strokeWidth="2"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_60_392"
          x1="1099.96"
          y1="762.612"
          x2="-7.2882"
          y2="554.829"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#015828" />
          <stop offset="1" stopColor="#71C93D" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_60_392"
          x1="1142.63"
          y1="760.343"
          x2="204.964"
          y2="642.574"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#015828" />
          <stop offset="1" stopColor="#71C93D" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function EclipseTiersLeft({
  className = "",
  key = "",
  id = "",
  onClick,
}: {
  className?: string;
  key?: string;
  id?: string;
  onClick?: () => void | undefined;
}): JSX.Element {
  return (
    <svg
      className={styles.eclipseTiersLeft}
      width="505"
      height="988"
      viewBox="0 0 505 988"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <path
          d="M-637.528 563.896C-760.167 330.518 -654.028 33.1513 -400.222 -100.222C-146.416 -233.596 158.689 -152.336 281.328 81.0419C403.967 314.42 297.828 611.787 44.0225 745.16C-209.783 878.534 -514.889 797.274 -637.528 563.896Z"
          stroke="url(#paint0_linear_60_432)"
          strokeWidth="2"
        />
        <path
          d="M-435.17 506.134C-546.817 293.674 -472.383 34.849 -269.039 -72.0069C-65.6959 -178.863 189.684 -93.354 301.331 119.106C412.978 331.567 338.543 590.391 135.2 697.247C-68.1434 804.103 -323.523 718.594 -435.17 506.134Z"
          stroke="url(#paint1_linear_60_432)"
          strokeWidth="2"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_60_432"
          x1="238.96"
          y1="419.612"
          x2="-868.288"
          y2="211.83"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#015828" />
          <stop offset="1" stopColor="#71C93D" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_60_432"
          x1="281.625"
          y1="417.343"
          x2="-656.036"
          y2="299.575"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#015828" />
          <stop offset="1" stopColor="#71C93D" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function EclipseTiersRight({
  className = "",
  key = "",
  id = "",
  onClick,
}: {
  className?: string;
  key?: string;
  id?: string;
  onClick?: () => void | undefined;
}): JSX.Element {
  return (
    <svg
      className={styles.eclipseTiersRight}
      width="745"
      height="896"
      viewBox="0 0 745 896"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <path
          d="M223.472 906.896C100.833 673.518 206.972 376.151 460.778 242.778C714.584 109.404 1019.69 190.664 1142.33 424.042C1264.97 657.42 1158.83 954.787 905.023 1088.16C651.217 1221.53 346.111 1140.27 223.472 906.896Z"
          stroke="url(#paint0_linear_60_429)"
          strokeWidth="2"
        />
        <path
          d="M425.83 849.134C314.183 636.674 388.617 377.849 591.961 270.993C795.304 164.137 1050.68 249.646 1162.33 462.106C1273.98 674.567 1199.54 933.391 996.2 1040.25C792.857 1147.1 537.477 1061.59 425.83 849.134Z"
          stroke="url(#paint1_linear_60_429)"
          strokeWidth="2"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_60_429"
          x1="1099.96"
          y1="762.612"
          x2="-7.2882"
          y2="554.83"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#015828" />
          <stop offset="1" stopColor="#71C93D" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_60_429"
          x1="1142.63"
          y1="760.343"
          x2="204.964"
          y2="642.575"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#015828" />
          <stop offset="1" stopColor="#71C93D" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function EclipseContentLeft({
  className = "",
  key = "",
  id = "",
  onClick,
}: {
  className?: string;
  key?: string;
  id?: string;
  onClick?: () => void | undefined;
}): JSX.Element {
  return (
    <svg
      className={styles.eclipseContentLeft}
      width="505"
      height="988"
      viewBox="0 0 505 988"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <path
          d="M-637.528 563.896C-760.167 330.518 -654.028 33.1513 -400.222 -100.222C-146.416 -233.596 158.689 -152.336 281.328 81.0419C403.967 314.42 297.828 611.787 44.0225 745.16C-209.783 878.534 -514.889 797.274 -637.528 563.896Z"
          stroke="url(#paint0_linear_60_588)"
          strokeWidth="2"
        />
        <path
          d="M-435.17 506.134C-546.817 293.674 -472.383 34.849 -269.039 -72.0069C-65.6959 -178.863 189.684 -93.354 301.331 119.106C412.978 331.567 338.543 590.391 135.2 697.247C-68.1434 804.103 -323.523 718.594 -435.17 506.134Z"
          stroke="url(#paint1_linear_60_588)"
          strokeWidth="2"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_60_588"
          x1="238.96"
          y1="419.612"
          x2="-868.288"
          y2="211.83"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#015828" />
          <stop offset="1" stopColor="#71C93D" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_60_588"
          x1="281.625"
          y1="417.343"
          x2="-656.036"
          y2="299.575"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#015828" />
          <stop offset="1" stopColor="#71C93D" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function EclipseContentRight({
  className = "",
  key = "",
  id = "",
  onClick,
}: {
  className?: string;
  key?: string;
  id?: string;
  onClick?: () => void | undefined;
}): JSX.Element {
  return (
    <svg
      className={styles.eclipseContentRight}
      width="745"
      height="907"
      viewBox="0 0 745 907"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <path
          d="M223.472 906.896C100.833 673.518 206.972 376.151 460.778 242.778C714.584 109.404 1019.69 190.664 1142.33 424.042C1264.97 657.42 1158.83 954.787 905.023 1088.16C651.217 1221.53 346.111 1140.27 223.472 906.896Z"
          stroke="url(#paint0_linear_60_585)"
          strokeWidth="2"
        />
        <path
          d="M425.83 849.134C314.183 636.674 388.617 377.849 591.961 270.993C795.304 164.137 1050.68 249.646 1162.33 462.106C1273.98 674.567 1199.54 933.391 996.2 1040.25C792.857 1147.1 537.477 1061.59 425.83 849.134Z"
          stroke="url(#paint1_linear_60_585)"
          strokeWidth="2"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_60_585"
          x1="1099.96"
          y1="762.612"
          x2="-7.2882"
          y2="554.83"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#015828" />
          <stop offset="1" stopColor="#71C93D" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_60_585"
          x1="1142.63"
          y1="760.343"
          x2="204.964"
          y2="642.575"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#015828" />
          <stop offset="1" stopColor="#71C93D" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function EclipseFeaturedLeft({
  className = "",
  key = "",
  id = "",
  onClick,
}: {
  className?: string;
  key?: string;
  id?: string;
  onClick?: () => void | undefined;
}): JSX.Element {
  return (
    <svg
      className={styles.eclipseFeaturedLeft}
      width="505"
      height="1157"
      viewBox="0 0 505 1157"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <path
          d="M-637.552 678.68C-760.244 415.654 -654.026 80.6367 -400.264 -69.5894C-146.503 -219.815 158.661 -128.335 281.353 134.691C404.045 397.716 297.826 732.734 44.065 882.96C-209.697 1033.19 -514.86 941.705 -637.552 678.68Z"
          stroke="url(#paint0_linear_83_260)"
          strokeWidth="2"
        />
        <path
          d="M-435.195 613.608C-546.891 374.154 -472.387 82.553 -269.082 -37.8033C-65.7761 -158.16 189.659 -61.8815 301.356 177.572C413.052 417.025 338.548 708.627 135.242 828.983C-68.0632 949.339 -323.499 853.061 -435.195 613.608Z"
          stroke="url(#paint1_linear_83_260)"
          strokeWidth="2"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_83_260"
          x1="238.96"
          y1="516.122"
          x2="-876.333"
          y2="330.34"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#015828" />
          <stop offset="1" stopColor="#71C93D" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_83_260"
          x1="281.625"
          y1="513.566"
          x2="-659.134"
          y2="408.681"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#015828" />
          <stop offset="1" stopColor="#71C93D" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function EclipseFeaturedRight({
  className = "",
  key = "",
  id = "",
  onClick,
}: {
  className?: string;
  key?: string;
  id?: string;
  onClick?: () => void | undefined;
}): JSX.Element {
  return (
    <svg
      className={styles.eclipseFeaturedRight}
      width="745"
      height="1500"
      viewBox="0 0 745 1500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <path
          d="M223.448 1022.31C100.756 759.285 206.974 424.268 460.736 274.041C714.497 123.815 1019.66 215.296 1142.35 478.321C1265.04 741.347 1158.83 1076.36 905.065 1226.59C651.303 1376.82 346.14 1285.34 223.448 1022.31Z"
          stroke="url(#paint0_linear_83_257)"
          strokeWidth="2"
        />
        <path
          d="M425.805 957.238C314.109 717.785 388.613 426.184 591.918 305.828C795.224 185.471 1050.66 281.749 1162.36 521.203C1274.05 760.656 1199.55 1052.26 996.242 1172.61C792.937 1292.97 537.501 1196.69 425.805 957.238Z"
          stroke="url(#paint1_linear_83_257)"
          strokeWidth="2"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_83_257"
          x1="1099.96"
          y1="859.753"
          x2="-15.3332"
          y2="673.971"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#015828" />
          <stop offset="1" stopColor="#71C93D" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_83_257"
          x1="1142.63"
          y1="857.197"
          x2="201.866"
          y2="752.312"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#015828" />
          <stop offset="1" stopColor="#71C93D" />
        </linearGradient>
      </defs>
    </svg>
  );
}
