import { useEffect, useMemo, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import defaultBrokenImage from "../../assets/images/defaultBrokenImage.png";
import { FileViewer, Linkify } from "../../components";
import { WarningIcon } from "../../components/Icons";
import { detectWrapping, handleDriveLinks } from "../../services";
import styles from "../../styles/Sites/Preview.module.scss";
import { SiteRequest } from "../../types";

export default function SitePreview(): JSX.Element {
  const { state }: { state: SiteRequest } = useLocation();
  const navigate = useNavigate();
  const { gate_id } = useParams();
  const headerImageRef = useRef<HTMLImageElement>(null);
  const headerTitleRef = useRef<HTMLHeadingElement>(null);
  const contentRef = useRef<HTMLParagraphElement[]>([]);
  const section1ImagesContainerRef = useRef<HTMLDivElement>(null);
  const section2ImagesContainerRef = useRef<HTMLDivElement>(null);
  const section3ImagesContainerRef = useRef<HTMLDivElement>(null);
  const section4ImagesContainerRef = useRef<HTMLDivElement>(null);
  const section5ImagesContainerRef = useRef<HTMLDivElement>(null);
  const section6ImagesContainerRef = useRef<HTMLDivElement>(null);
  const section1ImagesRef = useRef<HTMLImageElement[]>([]);
  const section2ImagesRef = useRef<HTMLImageElement[]>([]);
  const section3ImagesRef = useRef<HTMLImageElement[]>([]);
  const section4ImagesRef = useRef<HTMLImageElement[]>([]);
  const section5ImagesRef = useRef<HTMLImageElement[]>([]);
  const section6ImagesRef = useRef<HTMLImageElement[]>([]);

  const handleBrokenImage = (e: React.SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.src = defaultBrokenImage;
  };

  const handleReturn = (e: React.SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    navigate(`/gates/${gate_id}/site`);
  };

  const handleContextMenu = (event: MouseEvent) => {
    event.preventDefault();
  };

  const titleContent = useMemo(() => {
    if (!state.title) {
      return <h1></h1>;
    }
    const titles = state.title.split("\n");
    return titles.map((title) => <h1>{title}</h1>);
  }, [state.title]);

  const mainContent = useMemo(() => {
    if (!state.main_content) {
      return (
        <p
          ref={(el) => (contentRef.current[0] = el as HTMLParagraphElement)}
        ></p>
      );
    }
    const paragraphs = state.main_content.split("\n");
    return (
      <p
        ref={(el) => (contentRef.current[0] = el as HTMLParagraphElement)}
        className={styles.content}
      >
        {paragraphs.map((paragraph, index) => (
          <span key={index}>
            <Linkify>{paragraph}</Linkify>
            <br />
          </span>
        ))}
      </p>
    );
  }, [state.main_content]);

  const section1Images = useMemo(() => {
    if (!state.section_1_images) {
      return <></>;
    }
    return state.section_1_images
      .filter((image) => !!image)
      .map((image, index) => (
        <img
          key={index}
          src={handleDriveLinks(image)}
          alt="Section 1"
          onError={handleBrokenImage}
          ref={(el) =>
            (section1ImagesRef.current[index] = el as HTMLImageElement)
          }
        />
      ));
  }, [state.section_1_images]);

  const section2Images = useMemo(() => {
    if (!state.section_2_images) {
      return <></>;
    }
    return state.section_2_images
      .filter((image) => !!image)
      .map((image, index) => (
        <img
          key={index}
          src={handleDriveLinks(image)}
          alt="Section 2"
          onError={handleBrokenImage}
          ref={(el) =>
            (section2ImagesRef.current[index] = el as HTMLImageElement)
          }
        />
      ));
  }, [state.section_2_images]);

  const section1Content = useMemo(() => {
    if (!state.section_1_content) {
      return (
        <p
          ref={(el) => (contentRef.current[1] = el as HTMLParagraphElement)}
        ></p>
      );
    }
    const paragraphs = state.section_1_content.split("\n");
    return (
      <p
        ref={(el) => (contentRef.current[1] = el as HTMLParagraphElement)}
        className={styles.content}
      >
        {paragraphs.map((paragraph, index) => (
          <span key={index}>
            <Linkify>{paragraph}</Linkify>
            <br />
          </span>
        ))}
      </p>
    );
  }, [state.section_1_content]);

  const section2Content = useMemo(() => {
    if (!state.section_2_content) {
      return (
        <p
          ref={(el) => (contentRef.current[2] = el as HTMLParagraphElement)}
        ></p>
      );
    }
    const paragraphs = state.section_2_content.split("\n");
    return (
      <p
        ref={(el) => (contentRef.current[2] = el as HTMLParagraphElement)}
        className={styles.content}
      >
        {paragraphs.map((paragraph, index) => (
          <span key={index}>
            <Linkify>{paragraph}</Linkify>
            <br />
          </span>
        ))}
      </p>
    );
  }, [state.section_2_content]);

  const section3Images = useMemo(() => {
    if (!state.section_3_images) {
      return <></>;
    }
    return state.section_3_images
      .filter((image) => !!image)
      .map((image, index) => (
        <img
          key={index}
          src={handleDriveLinks(image)}
          alt="Section 3"
          onError={handleBrokenImage}
          ref={(el) =>
            (section3ImagesRef.current[index] = el as HTMLImageElement)
          }
        />
      ));
  }, [state.section_3_images]);

  const section4Images = useMemo(() => {
    if (!state.section_4_images) {
      return <></>;
    }
    return state.section_4_images
      .filter((image) => !!image)
      .map((image, index) => (
        <img
          key={index}
          src={handleDriveLinks(image)}
          alt="Section 4"
          onError={handleBrokenImage}
          ref={(el) =>
            (section4ImagesRef.current[index] = el as HTMLImageElement)
          }
        />
      ));
  }, [state.section_4_images]);

  const section3Content = useMemo(() => {
    if (!state.section_3_content) {
      return (
        <p
          ref={(el) => (contentRef.current[3] = el as HTMLParagraphElement)}
        ></p>
      );
    }
    const paragraphs = state.section_3_content.split("\n");
    return (
      <p
        ref={(el) => (contentRef.current[3] = el as HTMLParagraphElement)}
        className={styles.content}
      >
        {paragraphs.map((paragraph, index) => (
          <span key={index}>
            <Linkify>{paragraph}</Linkify>
            <br />
          </span>
        ))}
      </p>
    );
  }, [state.section_3_content]);

  const section4Content = useMemo(() => {
    if (!state.section_4_content) {
      return (
        <p
          ref={(el) => (contentRef.current[4] = el as HTMLParagraphElement)}
        ></p>
      );
    }
    const paragraphs = state.section_4_content.split("\n");
    return (
      <p
        ref={(el) => (contentRef.current[4] = el as HTMLParagraphElement)}
        className={styles.content}
      >
        {paragraphs.map((paragraph, index) => (
          <span key={index}>
            <Linkify>{paragraph}</Linkify>
            <br />
          </span>
        ))}
      </p>
    );
  }, [state.section_4_content]);

  const section5Images = useMemo(() => {
    if (!state.section_5_images) {
      return <></>;
    }
    return state.section_5_images
      .filter((image) => !!image)
      .map((image, index) => (
        <img
          key={index}
          src={handleDriveLinks(image)}
          alt="Section 5"
          onError={handleBrokenImage}
          ref={(el) =>
            (section5ImagesRef.current[index] = el as HTMLImageElement)
          }
        />
      ));
  }, [state.section_5_images]);

  const section6Images = useMemo(() => {
    if (!state.section_6_images) {
      return <></>;
    }
    return state.section_6_images
      .filter((image) => !!image)
      .map((image, index) => (
        <img
          key={index}
          src={handleDriveLinks(image)}
          alt="Section 6"
          onError={handleBrokenImage}
          ref={(el) =>
            (section6ImagesRef.current[index] = el as HTMLImageElement)
          }
        />
      ));
  }, [state.section_6_images]);

  const section5Content = useMemo(() => {
    if (!state.section_5_content) {
      return (
        <p
          ref={(el) => (contentRef.current[5] = el as HTMLParagraphElement)}
        ></p>
      );
    }
    const paragraphs = state.section_5_content.split("\n");
    return (
      <p
        ref={(el) => (contentRef.current[5] = el as HTMLParagraphElement)}
        className={styles.content}
      >
        {paragraphs.map((paragraph, index) => (
          <span key={index}>
            <Linkify>{paragraph}</Linkify>
            <br />
          </span>
        ))}
      </p>
    );
  }, [state.section_5_content]);

  const section6Content = useMemo(() => {
    if (!state.section_6_content) {
      return (
        <p
          ref={(el) => (contentRef.current[6] = el as HTMLParagraphElement)}
        ></p>
      );
    }
    const paragraphs = state.section_6_content.split("\n");
    return (
      <p
        ref={(el) => (contentRef.current[6] = el as HTMLParagraphElement)}
        className={styles.content}
      >
        {paragraphs.map((paragraph, index) => (
          <span key={index}>
            <Linkify>{paragraph}</Linkify>
            <br />
          </span>
        ))}
      </p>
    );
  }, [state.section_6_content]);

  useEffect(() => {
    if (headerImageRef.current && state.header_image_size) {
      headerImageRef.current.style.width = `${state.header_image_size}%`;
    }

    if (headerTitleRef.current && state.title_size) {
      headerTitleRef.current.style.fontSize = `${state.title_size}px`;
    }

    const _headerImageCurrent = headerImageRef.current;
    const _section1ImagesCurrent = section1ImagesRef.current;
    const _section2ImagesCurrent = section2ImagesRef.current;
    const _section3ImagesCurrent = section3ImagesRef.current;
    const _section4ImagesCurrent = section4ImagesRef.current;
    const _section5ImagesCurrent = section5ImagesRef.current;
    const _section6ImagesCurrent = section6ImagesRef.current;

    if (_headerImageCurrent && state.header_image_blocked === "true") {
      _headerImageCurrent.addEventListener("contextmenu", handleContextMenu);
      _headerImageCurrent.classList.add("blocked");
    }

    for (let i = 0; i < contentRef.current.length; i++) {
      if (contentRef.current[i] && state.content_size) {
        contentRef.current[i].style.fontSize = `${state.content_size}px`;
      }
      if (contentRef.current[i] && state.content_alignment) {
        contentRef.current[i].style.textAlign = state.content_alignment;
      }
    }

    for (let i = 0; i < _section1ImagesCurrent.length; i++) {
      if (
        _section1ImagesCurrent[i] &&
        state.section_1_image_sizes &&
        state.section_1_image_sizes[i]
      ) {
        _section1ImagesCurrent[
          i
        ].style.width = `${state.section_1_image_sizes[i]}%`;
      }
      if (
        _section1ImagesCurrent[i] &&
        state.section_1_image_blocks[i] === "true"
      ) {
        _section1ImagesCurrent[i].addEventListener(
          "contextmenu",
          handleContextMenu
        );
        _section1ImagesCurrent[i].classList.add("blocked");
      }
    }

    for (let i = 0; i < _section2ImagesCurrent.length; i++) {
      if (
        _section2ImagesCurrent[i] &&
        state.section_2_image_sizes &&
        state.section_2_image_sizes[i]
      ) {
        _section2ImagesCurrent[
          i
        ].style.width = `${state.section_2_image_sizes[i]}%`;
      }
      if (
        _section2ImagesCurrent[i] &&
        state.section_2_image_blocks[i] === "true"
      ) {
        _section2ImagesCurrent[i].addEventListener(
          "contextmenu",
          handleContextMenu
        );
        _section2ImagesCurrent[i].classList.add("blocked");
      }
    }

    for (let i = 0; i < _section3ImagesCurrent.length; i++) {
      if (
        _section3ImagesCurrent[i] &&
        state.section_3_image_sizes &&
        state.section_3_image_sizes[i]
      ) {
        _section3ImagesCurrent[
          i
        ].style.width = `${state.section_3_image_sizes[i]}%`;
      }
      if (
        _section3ImagesCurrent[i] &&
        state.section_3_image_blocks[i] === "true"
      ) {
        _section3ImagesCurrent[i].addEventListener(
          "contextmenu",
          handleContextMenu
        );
        _section3ImagesCurrent[i].classList.add("blocked");
      }
    }

    for (let i = 0; i < _section4ImagesCurrent.length; i++) {
      if (
        _section4ImagesCurrent[i] &&
        state.section_4_image_sizes &&
        state.section_4_image_sizes[i]
      ) {
        _section4ImagesCurrent[
          i
        ].style.width = `${state.section_4_image_sizes[i]}%`;
      }
      if (
        _section4ImagesCurrent[i] &&
        state.section_4_image_blocks[i] === "true"
      ) {
        _section4ImagesCurrent[i].addEventListener(
          "contextmenu",
          handleContextMenu
        );
        _section4ImagesCurrent[i].classList.add("blocked");
      }
    }

    for (let i = 0; i < _section5ImagesCurrent.length; i++) {
      if (
        _section5ImagesCurrent[i] &&
        state.section_5_image_sizes &&
        state.section_5_image_sizes[i]
      ) {
        _section5ImagesCurrent[
          i
        ].style.width = `${state.section_5_image_sizes[i]}%`;
      }
      if (
        _section5ImagesCurrent[i] &&
        state.section_5_image_blocks[i] === "true"
      ) {
        _section5ImagesCurrent[i].addEventListener(
          "contextmenu",
          handleContextMenu
        );
        _section5ImagesCurrent[i].classList.add("blocked");
      }
    }

    for (let i = 0; i < _section6ImagesCurrent.length; i++) {
      if (
        _section6ImagesCurrent[i] &&
        state.section_6_image_sizes &&
        state.section_6_image_sizes[i]
      ) {
        _section6ImagesCurrent[
          i
        ].style.width = `${state.section_6_image_sizes[i]}%`;
      }
      if (
        _section6ImagesCurrent[i] &&
        state.section_6_image_blocks[i] === "true"
      ) {
        _section6ImagesCurrent[i].addEventListener(
          "contextmenu",
          handleContextMenu
        );
        _section6ImagesCurrent[i].classList.add("blocked");
      }
    }

    return () => {
      if (
        _headerImageCurrent &&
        _headerImageCurrent &&
        state.header_image_blocked === "true"
      ) {
        _headerImageCurrent.removeEventListener(
          "contextmenu",
          handleContextMenu
        );
      }

      for (let i = 0; i < _section1ImagesCurrent.length; i++) {
        if (
          _section1ImagesCurrent &&
          _section1ImagesCurrent[i] &&
          state.section_1_image_blocks[i]
        ) {
          _section1ImagesCurrent[i].removeEventListener(
            "contextmenu",
            handleContextMenu
          );
        }
      }

      for (let i = 0; i < _section2ImagesCurrent.length; i++) {
        if (
          _section2ImagesCurrent &&
          _section2ImagesCurrent[i] &&
          state.section_2_image_blocks[i] === "true"
        ) {
          _section2ImagesCurrent[i].removeEventListener(
            "contextmenu",
            handleContextMenu
          );
        }
      }

      for (let i = 0; i < _section3ImagesCurrent.length; i++) {
        if (
          _section3ImagesCurrent &&
          _section3ImagesCurrent[i] &&
          state.section_3_image_blocks[i] === "true"
        ) {
          _section3ImagesCurrent[i].removeEventListener(
            "contextmenu",
            handleContextMenu
          );
        }
      }

      for (let i = 0; i < _section4ImagesCurrent.length; i++) {
        if (
          _section4ImagesCurrent &&
          _section4ImagesCurrent[i] &&
          state.section_4_image_blocks[i] === "true"
        ) {
          _section4ImagesCurrent[i].removeEventListener(
            "contextmenu",
            handleContextMenu
          );
        }
      }

      for (let i = 0; i < _section5ImagesCurrent.length; i++) {
        if (
          _section5ImagesCurrent &&
          _section5ImagesCurrent[i] &&
          state.section_5_image_blocks[i] === "true"
        ) {
          _section5ImagesCurrent[i].removeEventListener(
            "contextmenu",
            handleContextMenu
          );
        }
      }

      for (let i = 0; i < _section6ImagesCurrent.length; i++) {
        if (
          _section6ImagesCurrent &&
          _section6ImagesCurrent[i] &&
          state.section_6_image_blocks[i] === "true"
        ) {
          _section6ImagesCurrent[i].removeEventListener(
            "contextmenu",
            handleContextMenu
          );
        }
      }
    };
  }, [state]);

  useEffect(() => {
    if (section1ImagesContainerRef.current) {
      if (detectWrapping(section1ImagesContainerRef.current)) {
        section1ImagesContainerRef.current.style.justifyContent = "center";
      }
    }

    if (section2ImagesContainerRef.current) {
      if (detectWrapping(section2ImagesContainerRef.current)) {
        section2ImagesContainerRef.current.style.justifyContent = "center";
      }
    }

    if (section3ImagesContainerRef.current) {
      if (detectWrapping(section3ImagesContainerRef.current)) {
        section3ImagesContainerRef.current.style.justifyContent = "center";
      }
    }

    if (section4ImagesContainerRef.current) {
      if (detectWrapping(section4ImagesContainerRef.current)) {
        section4ImagesContainerRef.current.style.justifyContent = "center";
      }
    }

    if (section5ImagesContainerRef.current) {
      if (detectWrapping(section5ImagesContainerRef.current)) {
        section5ImagesContainerRef.current.style.justifyContent = "center";
      }
    }

    if (section6ImagesContainerRef.current) {
      if (detectWrapping(section6ImagesContainerRef.current)) {
        section6ImagesContainerRef.current.style.justifyContent = "center";
      }
    }
  }, []);

  if (!state) {
    return (
      <div className={styles.page}>
        <aside onClick={handleReturn}>Return/Edit</aside>
        <section className={styles.preview_page}>
          <p>There is nothing to preview.</p>
        </section>
        <aside onClick={handleReturn}>Return/Edit</aside>
      </div>
    );
  }

  return (
    <div className={`${styles.page} ${styles[state.theme]}`}>
      <aside onClick={handleReturn}>Return/Edit</aside>
      <div className={styles.header_banner}>
        To save content to your device, right mouse-click and select "download."
      </div>
      <section className={styles.preview_wrapper}>
        <section className={styles.preview_page}>
          {state.header_image ? (
            <img
              ref={headerImageRef}
              src={handleDriveLinks(state.header_image)}
              alt="Header"
              className={styles.header_image}
              onError={handleBrokenImage}
            />
          ) : (
            <></>
          )}
          {state.title ? (
            <div className={styles.title_container} ref={headerTitleRef}>
              {titleContent}
            </div>
          ) : (
            <></>
          )}
          {mainContent}
          <div
            className={styles.section_images}
            ref={section1ImagesContainerRef}
          >
            {section1Images}
          </div>
          {section1Content}
          <div
            className={styles.section_images}
            ref={section2ImagesContainerRef}
          >
            {section2Images}
          </div>
          {section2Content}
          {state.attachment_link_1 && state.attachment_type_1 ? (
            <FileViewer
              type={state.attachment_type_1}
              file={state.attachment_link_1}
              download={state.attachment_1_blocked === "false"}
              className={styles.attachment}
            />
          ) : (
            <></>
          )}
          <div
            className={styles.section_images}
            ref={section3ImagesContainerRef}
          >
            {section3Images}
          </div>
          {section3Content}
          <div
            className={styles.section_images}
            ref={section4ImagesContainerRef}
          >
            {section4Images}
          </div>
          {section4Content}
          {state.attachment_link_2 && state.attachment_type_2 ? (
            <FileViewer
              type={state.attachment_type_2}
              file={state.attachment_link_2}
              download={state.attachment_2_blocked === "false"}
              className={styles.attachment}
            />
          ) : (
            <></>
          )}
          <div
            className={styles.section_images}
            ref={section5ImagesContainerRef}
          >
            {section5Images}
          </div>
          {section5Content}
          <div
            className={styles.section_images}
            ref={section6ImagesContainerRef}
          >
            {section6Images}
          </div>
          {section6Content}
          {state.attachment_link_3 && state.attachment_type_3 ? (
            <FileViewer
              type={state.attachment_type_3}
              file={state.attachment_link_3}
              download={state.attachment_3_blocked === "false"}
              className={styles.attachment}
            />
          ) : (
            <></>
          )}
        </section>
      </section>
      <div className={`${styles.header_banner} ${styles.footer}`}>
        <span>
          Token-gating provided by
          <a href="https://0xGatr.io" target="_blank" rel="noreferrer">
            0xGatr.io
          </a>
          .
        </span>
        <a href={`/`} className={styles.report_link}>
          <WarningIcon />
          Report Page
        </a>
      </div>
      <aside onClick={handleReturn}>Return/Edit</aside>
    </div>
  );
}
