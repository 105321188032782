import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import defaultBrokenImage from "../../assets/images/defaultBrokenImage.png";
import {
  Button,
  Divider,
  Dropdown,
  FormWrapper,
  Input,
  InputWithChildren,
  InputWithPreview,
  RadioInput,
} from "../../components";
import {
  CenterAlignIcon,
  HollowBoxIcon,
  JustifyAlignIcon,
  LeftAlignIcon,
  PublishIcon,
  RightAlignIcon,
  SaveIcon,
  UnpublishIcon,
} from "../../components/Icons";
import { useGetGateById } from "../../hooks/gates.hooks";
import { useSiteByGate, useUpdateSite } from "../../hooks/sites.hooks";
import { handleDriveLinks } from "../../services";
import styles from "../../styles/Sites/Site.module.scss";
import { Booleans } from "../../types";

export default function CreateSite(): JSX.Element {
  const [formState, setFormState] = useState({
    title: "",
    title_size: 28.8,
    main_content: "",
    header_image: "",
    header_image_size: 60,
    header_image_blocked: "false",
    section_1_images_1: "",
    section_1_images_1_size: 30,
    section_1_images_1_blocked: "false",
    section_1_images_2: "",
    section_1_images_2_size: 30,
    section_1_images_2_blocked: "false",
    section_1_images_3: "",
    section_1_images_3_size: 30,
    section_1_images_3_blocked: "false",
    section_1_content: "",
    section_2_images_1: "",
    section_2_images_1_size: 30,
    section_2_images_1_blocked: "false",
    section_2_images_2: "",
    section_2_images_2_size: 30,
    section_2_images_2_blocked: "false",
    section_2_images_3: "",
    section_2_images_3_size: 30,
    section_2_images_3_blocked: "false",
    section_2_content: "",
    section_3_images_1: "",
    section_3_images_1_size: 30,
    section_3_images_1_blocked: "false",
    section_3_images_2: "",
    section_3_images_2_size: 30,
    section_3_images_2_blocked: "false",
    section_3_images_3: "",
    section_3_images_3_size: 30,
    section_3_images_3_blocked: "false",
    section_3_content: "",
    section_4_images_1: "",
    section_4_images_1_size: 30,
    section_4_images_1_blocked: "false",
    section_4_images_2: "",
    section_4_images_2_size: 30,
    section_4_images_2_blocked: "false",
    section_4_images_3: "",
    section_4_images_3_size: 30,
    section_4_images_3_blocked: "false",
    section_4_content: "",
    section_5_images_1: "",
    section_5_images_1_size: 30,
    section_5_images_1_blocked: "false",
    section_5_images_2: "",
    section_5_images_2_size: 30,
    section_5_images_2_blocked: "false",
    section_5_images_3: "",
    section_5_images_3_size: 30,
    section_5_images_3_blocked: "false",
    section_5_content: "",
    section_6_images_1: "",
    section_6_images_1_size: 30,
    section_6_images_1_blocked: "false",
    section_6_images_2: "",
    section_6_images_2_size: 30,
    section_6_images_2_blocked: "false",
    section_6_images_3: "",
    section_6_images_3_size: 30,
    section_6_images_3_blocked: "false",
    section_6_content: "",
    content_size: 19.2,
    content_alignment: "",
    attachment_link_1: "",
    attachment_link_2: "",
    attachment_link_3: "",
    attachment_type_1: "",
    attachment_type_2: "",
    attachment_type_3: "",
    attachment_1_blocked: "false",
    attachment_2_blocked: "false",
    attachment_3_blocked: "false",
    theme: "light",
    status: "draft",
  });
  const [isLoading, setIsLoading] = useState(false);
  const { gate_id } = useParams();
  const navigate = useNavigate();
  const { isError, isLoading: isGateLoading } = useGetGateById(gate_id || "");
  const { data: savedSiteData, isLoading: isSiteLoading } = useSiteByGate(
    gate_id || ""
  );
  const { mutateAsync: saveSite, isLoading: isSavingSite } = useUpdateSite(
    gate_id || ""
  );

  const handleChange = (e: any) => {
    const { name, value, type } = e.target;
    let _value: any = value;
    if (type === "radio") {
      if (name === "theme") {
        _value = value === formState.theme ? "light" : value;
      } else {
        _value =
          formState[name as keyof typeof formState] === value ? "false" : value;
      }
    }
    setFormState((prevState) => ({ ...prevState, [name]: _value }));
  };

  const handleBrokenImage = (e: React.SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.src = defaultBrokenImage;
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>,
    action?: "publish" | "unpublish",
    status: "draft" | "ready" = "draft"
  ) => {
    try {
      e.preventDefault();
      if (e.currentTarget.checkValidity() === false) {
        e.stopPropagation();
        return;
      }
      setIsLoading(true);
      const response = {
        title: formState.title,
        title_size: formState.title_size,
        main_content: formState.main_content,
        header_image: formState.header_image,
        header_image_blocked: formState.header_image_blocked as Booleans,
        header_image_size: formState.header_image_size,
        section_1_images: [
          formState.section_1_images_1,
          formState.section_1_images_2,
          formState.section_1_images_3,
        ],
        section_1_image_sizes: [
          formState.section_1_images_1_size,
          formState.section_1_images_2_size,
          formState.section_1_images_3_size,
        ],
        section_1_image_blocks: [
          formState.section_1_images_1_blocked,
          formState.section_1_images_2_blocked,
          formState.section_1_images_3_blocked,
        ] as Booleans[],
        section_1_content: formState.section_1_content,
        section_2_images: [
          formState.section_2_images_1,
          formState.section_2_images_2,
          formState.section_2_images_3,
        ],
        section_2_image_sizes: [
          formState.section_2_images_1_size,
          formState.section_2_images_2_size,
          formState.section_2_images_3_size,
        ],
        section_2_image_blocks: [
          formState.section_2_images_1_blocked,
          formState.section_2_images_2_blocked,
          formState.section_2_images_3_blocked,
        ] as Booleans[],
        section_2_content: formState.section_2_content,
        section_3_images: [
          formState.section_3_images_1,
          formState.section_3_images_2,
          formState.section_3_images_3,
        ],
        section_3_image_sizes: [
          formState.section_3_images_1_size,
          formState.section_3_images_2_size,
          formState.section_3_images_3_size,
        ],
        section_3_image_blocks: [
          formState.section_3_images_1_blocked,
          formState.section_3_images_2_blocked,
          formState.section_3_images_3_blocked,
        ] as Booleans[],
        section_3_content: formState.section_3_content,
        section_4_images: [
          formState.section_4_images_1,
          formState.section_4_images_2,
          formState.section_4_images_3,
        ],
        section_4_image_sizes: [
          formState.section_4_images_1_size,
          formState.section_4_images_2_size,
          formState.section_4_images_3_size,
        ],
        section_4_image_blocks: [
          formState.section_4_images_1_blocked,
          formState.section_4_images_2_blocked,
          formState.section_4_images_3_blocked,
        ] as Booleans[],
        section_4_content: formState.section_4_content,
        section_5_images: [
          formState.section_5_images_1,
          formState.section_5_images_2,
          formState.section_5_images_3,
        ],
        section_5_image_sizes: [
          formState.section_5_images_1_size,
          formState.section_5_images_2_size,
          formState.section_5_images_3_size,
        ],
        section_5_image_blocks: [
          formState.section_5_images_1_blocked,
          formState.section_5_images_2_blocked,
          formState.section_5_images_3_blocked,
        ] as Booleans[],
        section_5_content: formState.section_5_content,
        section_6_images: [
          formState.section_6_images_1,
          formState.section_6_images_2,
          formState.section_6_images_3,
        ],
        section_6_image_sizes: [
          formState.section_6_images_1_size,
          formState.section_6_images_2_size,
          formState.section_6_images_3_size,
        ],
        section_6_image_blocks: [
          formState.section_6_images_1_blocked,
          formState.section_6_images_2_blocked,
          formState.section_6_images_3_blocked,
        ] as Booleans[],
        section_6_content: formState.section_6_content,
        content_size: formState.content_size,
        content_alignment: formState.content_alignment as
          | "left"
          | "center"
          | "right"
          | "justify",
        attachment_link_1: formState.attachment_link_1,
        attachment_type_1: formState.attachment_type_1 as any,
        attachment_link_2: formState.attachment_link_2,
        attachment_type_2: formState.attachment_type_2 as any,
        attachment_link_3: formState.attachment_link_3,
        attachment_type_3: formState.attachment_type_3 as any,
        attachment_1_blocked: formState.attachment_1_blocked as Booleans,
        attachment_2_blocked: formState.attachment_2_blocked as Booleans,
        attachment_3_blocked: formState.attachment_3_blocked as Booleans,
        theme: formState.theme as "light" | "dark" | "pink",
        status:
          action === "unpublish"
            ? (formState.status as "draft" | "ready")
            : action === "publish"
            ? "ready"
            : status,
        action,
      };
      await saveSite(response);
      setIsLoading(false);
      toast.success("Site saved successfully");
      if (action === "publish" || action === "unpublish") {
        navigate("/gates");
      }
    } catch (error: any) {
      setIsLoading(false);
      toast.error(error.message);
    }
  };

  const handlePreview = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      const response = {
        title: formState.title,
        title_size: formState.title_size,
        main_content: formState.main_content,
        header_image: formState.header_image,
        header_image_blocked: formState.header_image_blocked as Booleans,
        header_image_size: formState.header_image_size,
        section_1_images: [
          formState.section_1_images_1,
          formState.section_1_images_2,
          formState.section_1_images_3,
        ],
        section_1_image_sizes: [
          formState.section_1_images_1_size,
          formState.section_1_images_2_size,
          formState.section_1_images_3_size,
        ],
        section_1_image_blocks: [
          formState.section_1_images_1_blocked,
          formState.section_1_images_2_blocked,
          formState.section_1_images_3_blocked,
        ] as Booleans[],
        section_1_content: formState.section_1_content,
        section_2_images: [
          formState.section_2_images_1,
          formState.section_2_images_2,
          formState.section_2_images_3,
        ],
        section_2_image_sizes: [
          formState.section_2_images_1_size,
          formState.section_2_images_2_size,
          formState.section_2_images_3_size,
        ],
        section_2_image_blocks: [
          formState.section_2_images_1_blocked,
          formState.section_2_images_2_blocked,
          formState.section_2_images_3_blocked,
        ] as Booleans[],
        section_2_content: formState.section_2_content,
        section_3_images: [
          formState.section_3_images_1,
          formState.section_3_images_2,
          formState.section_3_images_3,
        ],
        section_3_image_sizes: [
          formState.section_3_images_1_size,
          formState.section_3_images_2_size,
          formState.section_3_images_3_size,
        ],
        section_3_image_blocks: [
          formState.section_3_images_1_blocked,
          formState.section_3_images_2_blocked,
          formState.section_3_images_3_blocked,
        ] as Booleans[],
        section_3_content: formState.section_3_content,
        section_4_images: [
          formState.section_4_images_1,
          formState.section_4_images_2,
          formState.section_4_images_3,
        ],
        section_4_image_sizes: [
          formState.section_4_images_1_size,
          formState.section_4_images_2_size,
          formState.section_4_images_3_size,
        ],
        section_4_image_blocks: [
          formState.section_4_images_1_blocked,
          formState.section_4_images_2_blocked,
          formState.section_4_images_3_blocked,
        ] as Booleans[],
        section_4_content: formState.section_4_content,
        section_5_images: [
          formState.section_5_images_1,
          formState.section_5_images_2,
          formState.section_5_images_3,
        ],
        section_5_image_sizes: [
          formState.section_5_images_1_size,
          formState.section_5_images_2_size,
          formState.section_5_images_3_size,
        ],
        section_5_image_blocks: [
          formState.section_5_images_1_blocked,
          formState.section_5_images_2_blocked,
          formState.section_5_images_3_blocked,
        ] as Booleans[],
        section_5_content: formState.section_5_content,
        section_6_images: [
          formState.section_6_images_1,
          formState.section_6_images_2,
          formState.section_6_images_3,
        ],
        section_6_image_sizes: [
          formState.section_6_images_1_size,
          formState.section_6_images_2_size,
          formState.section_6_images_3_size,
        ],
        section_6_image_blocks: [
          formState.section_6_images_1_blocked,
          formState.section_6_images_2_blocked,
          formState.section_6_images_3_blocked,
        ] as Booleans[],
        section_6_content: formState.section_6_content,
        content_size: formState.content_size,
        content_alignment: formState.content_alignment as
          | "left"
          | "center"
          | "right"
          | "justify",
        attachment_link_1: formState.attachment_link_1,
        attachment_type_1: formState.attachment_type_1 as any,
        attachment_link_2: formState.attachment_link_2,
        attachment_type_2: formState.attachment_type_2 as any,
        attachment_link_3: formState.attachment_link_3,
        attachment_type_3: formState.attachment_type_3 as any,
        attachment_1_blocked: formState.attachment_1_blocked as Booleans,
        attachment_2_blocked: formState.attachment_2_blocked as Booleans,
        attachment_3_blocked: formState.attachment_3_blocked as Booleans,
        theme: formState.theme as "light" | "dark" | "pink",
      };
      await saveSite({ ...response, status: "draft" });
      toast.success("Site draft saved successfully");
      navigate(`/gates/${gate_id}/site/preview`, { state: response });
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    const data = savedSiteData?.data;
    if (data) {
      setFormState({
        title: data.title || "",
        title_size: data?.title_size || 28.8,
        main_content: data.main_content || "",
        header_image: data.header_image || "",
        header_image_size: data?.header_image_size || 60,
        header_image_blocked: `${data?.header_image_blocked}` || "false",
        section_1_images_1: data?.section_1_images[0] || "",
        section_1_images_1_size: data?.section_1_image_sizes[0] || 30,
        section_1_images_1_blocked:
          `${data?.section_1_image_blocks[0]}` || "false",
        section_1_images_2: data.section_1_images[1] || "",
        section_1_images_2_size: data?.section_1_image_sizes[1] || 30,
        section_1_images_2_blocked:
          `${data?.section_1_image_blocks[1]}` || "false",
        section_1_images_3: data.section_1_images[2] || "",
        section_1_images_3_size: data?.section_1_image_sizes[2] || 30,
        section_1_images_3_blocked:
          `${data?.section_1_image_blocks[2]}` || "false",
        section_1_content: data.section_1_content || "",
        section_2_images_1: data.section_2_images[0] || "",
        section_2_images_1_size: data?.section_2_image_sizes[0] || 30,
        section_2_images_1_blocked:
          `${data?.section_2_image_blocks[0]}` || "false",
        section_2_images_2: data.section_2_images[1] || "",
        section_2_images_2_size: data?.section_2_image_sizes[1] || 30,
        section_2_images_2_blocked:
          `${data?.section_2_image_blocks[1]}` || "false",
        section_2_images_3: data.section_2_images[2] || "",
        section_2_images_3_size: data?.section_2_image_sizes[2] || 30,
        section_2_images_3_blocked:
          `${data?.section_2_image_blocks[2]}` || "false",
        section_2_content: data.section_2_content || "",
        section_3_images_1: data.section_3_images[0] || "",
        section_3_images_1_size: data?.section_3_image_sizes[0] || 30,
        section_3_images_1_blocked:
          `${data?.section_3_image_blocks[0]}` || "false",
        section_3_images_2: data.section_3_images[1] || "",
        section_3_images_2_size: data?.section_3_image_sizes[1] || 30,
        section_3_images_2_blocked:
          `${data?.section_3_image_blocks[1]}` || "false",
        section_3_images_3: data.section_3_images[2] || "",
        section_3_images_3_size: data?.section_3_image_sizes[2] || 30,
        section_3_images_3_blocked:
          `${data?.section_3_image_blocks[2]}` || "false",
        section_3_content: data.section_3_content || "",
        section_4_images_1: data.section_4_images[0] || "",
        section_4_images_1_size: data?.section_4_image_sizes[0] || 30,
        section_4_images_1_blocked:
          `${data?.section_4_image_blocks[0]}` || "false",
        section_4_images_2: data.section_4_images[1] || "",
        section_4_images_2_size: data?.section_4_image_sizes[1] || 30,
        section_4_images_2_blocked:
          `${data?.section_4_image_blocks[1]}` || "false",
        section_4_images_3: data.section_4_images[2] || "",
        section_4_images_3_size: data?.section_4_image_sizes[2] || 30,
        section_4_images_3_blocked:
          `${data?.section_4_image_blocks[2]}` || "false",
        section_4_content: data.section_4_content || "",
        section_5_images_1: data.section_5_images[0] || "",
        section_5_images_1_size: data?.section_5_image_sizes[0] || 30,
        section_5_images_1_blocked:
          `${data?.section_5_image_blocks[0]}` || "false",
        section_5_images_2: data.section_5_images[1] || "",
        section_5_images_2_size: data?.section_5_image_sizes[1] || 30,
        section_5_images_2_blocked:
          `${data?.section_5_image_blocks[1]}` || "false",
        section_5_images_3: data.section_5_images[2] || "",
        section_5_images_3_size: data?.section_5_image_sizes[2] || 30,
        section_5_images_3_blocked:
          `${data?.section_5_image_blocks[2]}` || "false",
        section_5_content: data.section_5_content || "",
        section_6_images_1: data.section_6_images[0] || "",
        section_6_images_1_size: data?.section_6_image_sizes[0] || 30,
        section_6_images_1_blocked:
          `${data?.section_6_image_blocks[0]}` || "false",
        section_6_images_2: data.section_6_images[1] || "",
        section_6_images_2_size: data?.section_6_image_sizes[1] || 30,
        section_6_images_2_blocked:
          `${data?.section_6_image_blocks[1]}` || "false",
        section_6_images_3: data.section_6_images[2] || "",
        section_6_images_3_size: data?.section_6_image_sizes[2] || 30,
        section_6_images_3_blocked:
          `${data?.section_6_image_blocks[2]}` || "false",
        section_6_content: data.section_6_content || "",
        content_size: data.content_size || 19.2,
        content_alignment: data.content_alignment || "center",
        attachment_link_1: data.attachment_link_1 || "",
        attachment_link_2: data.attachment_link_2 || "",
        attachment_link_3: data.attachment_link_3 || "",
        attachment_type_1: data.attachment_type_1 || "",
        attachment_type_2: data.attachment_type_2 || "",
        attachment_type_3: data.attachment_type_3 || "",
        attachment_1_blocked: `${data.attachment_1_blocked}` || "false",
        attachment_2_blocked: `${data.attachment_2_blocked}` || "false",
        attachment_3_blocked: `${data.attachment_3_blocked}` || "false",
        theme: data.theme || "light",
        status: data.status || "draft",
      });
    }
  }, [savedSiteData]);

  return (
    <section className={styles.site_page}>
      <h1>Create/Edit Gated Content Page</h1>
      <div className={styles.site_body}>
        <div className={`${styles.form_wrapper} radial_gradient`}>
          <FormWrapper
            id="site_form"
            name="site_form"
            aria-disabled={isGateLoading || isLoading}
          >
            {isError ? <h3>Invalid URL accessed...</h3> : <></>}
            {isGateLoading || isSiteLoading ? (
              <h3>Loading Site Details...</h3>
            ) : (
              <>
                <div className={styles.header_image}>
                  <label htmlFor="header_image">
                    <p>Header Image Preview</p>
                  </label>
                  <img
                    src={handleDriveLinks(formState.header_image)}
                    alt="Header Preview"
                    onError={handleBrokenImage}
                  />
                </div>
                <Input
                  className={styles.text_input}
                  type="url"
                  label="Image URL:"
                  name="header_image"
                  value={formState.header_image}
                  onChange={handleChange}
                />
                <InputWithChildren
                  className={styles.input_with_children}
                  type="number"
                  label="Header Image Size (%):"
                  name="header_image_size"
                  placeholder="60"
                  max="100"
                  value={formState.header_image_size}
                  onChange={handleChange}
                >
                  <div className={styles.radio_input}>
                    <div className={styles.single_radio}>
                      <label htmlFor="header_image_blocked">No Download:</label>
                      <input
                        type="radio"
                        id="header_image_blocked"
                        name="header_image_blocked"
                        value={true as any}
                        checked={formState.header_image_blocked === "true"}
                        onChange={handleChange}
                        onClick={handleChange}
                      />
                    </div>
                  </div>
                </InputWithChildren>
                <Input
                  className={styles.text_input}
                  type="textarea"
                  label="Title:"
                  name="title"
                  value={formState.title}
                  onChange={handleChange}
                />
                <Input
                  className={styles.text_input}
                  type="number"
                  label="Title Size (pixel):"
                  step=".01"
                  name="title_size"
                  placeholder="28.8"
                  max="100"
                  value={formState.title_size}
                  onChange={handleChange}
                />
                <Input
                  className={styles.text_input}
                  type="textarea"
                  rows={9}
                  label="Content:"
                  name="main_content"
                  value={formState.main_content}
                  onChange={handleChange}
                />
                <InputWithChildren
                  className={styles.input_with_children}
                  type="number"
                  step=".01"
                  label="Content Size (pixel):"
                  name="content_size"
                  placeholder="19.2"
                  max="100"
                  value={formState.content_size}
                  onChange={handleChange}
                >
                  <div className={styles.radio_input}>
                    <div className={styles.radio} title="Left">
                      <input
                        type="radio"
                        name="content_alignment"
                        value="left"
                        checked={formState.content_alignment === "left"}
                        onChange={handleChange}
                      />
                      <LeftAlignIcon
                        onClick={() =>
                          handleChange({
                            target: {
                              name: "content_alignment",
                              value: "left",
                            },
                          } as any)
                        }
                      />
                    </div>
                    <div className={styles.radio} title="Center">
                      <input
                        type="radio"
                        name="content_alignment"
                        value="center"
                        checked={formState.content_alignment === "center"}
                        onChange={handleChange}
                      />
                      <CenterAlignIcon
                        onClick={() =>
                          handleChange({
                            target: {
                              name: "content_alignment",
                              value: "center",
                            },
                          } as any)
                        }
                      />
                    </div>
                    <div className={styles.radio} title="Right">
                      <input
                        type="radio"
                        name="content_alignment"
                        value="right"
                        checked={formState.content_alignment === "right"}
                        onChange={handleChange}
                      />
                      <RightAlignIcon
                        onClick={() =>
                          handleChange({
                            target: {
                              name: "content_alignment",
                              value: "right",
                            },
                          } as any)
                        }
                      />
                    </div>
                    <div className={styles.radio} title="Justify">
                      <input
                        type="radio"
                        name="content_alignment"
                        value="justify"
                        checked={formState.content_alignment === "justify"}
                        onChange={handleChange}
                      />
                      <JustifyAlignIcon
                        onClick={() =>
                          handleChange({
                            target: {
                              name: "content_alignment",
                              value: "justify",
                            },
                          } as any)
                        }
                      />
                    </div>
                  </div>
                  {/* <div
                    className={`${styles.radio_input} ${styles.theme_input}`}
                  >
                    <div className={styles.single_radio}>
                      <label htmlFor="dark_theme">Dark mode:</label>
                      <input
                        type="radio"
                        id="dark_theme"
                        name="theme"
                        radioGroup="theme"
                        value="dark"
                        checked={formState.theme === "dark"}
                        onChange={handleChange}
                      />
                    </div>
                    <div className={styles.single_radio}>
                      <label htmlFor="pink_theme">Pink mode:</label>
                      <input
                        type="radio"
                        id="pink_theme"
                        name="theme"
                        radioGroup="theme"
                        value="pink"
                        checked={formState.theme === "pink"}
                        onChange={handleChange}
                        onClick={handleChange}
                      />
                    </div>
                  </div> */}
                </InputWithChildren>
                <RadioInput
                  className={`${styles.input_with_children} ${styles.theme_input}`}
                  label="Theme"
                >
                  <div className={styles.radio_input}>
                    <div className={styles.single_radio}>
                      <label htmlFor="dark_theme">Dark mode:</label>
                      <input
                        type="radio"
                        id="dark_theme"
                        name="theme"
                        radioGroup="theme"
                        value="dark"
                        checked={formState.theme === "dark"}
                        onChange={handleChange}
                        onClick={handleChange}
                      />
                    </div>
                    <div className={styles.single_radio}>
                      <label htmlFor="pink_theme">Pink mode:</label>
                      <input
                        type="radio"
                        id="pink_theme"
                        name="theme"
                        radioGroup="theme"
                        value="pink"
                        checked={formState.theme === "pink"}
                        onChange={handleChange}
                        onClick={handleChange}
                      />
                    </div>
                  </div>
                </RadioInput>
                <Divider />
                <div>
                  <label>
                    <p>SECTION-01</p>
                  </label>
                </div>
                <div className={styles.section_images}>
                  <InputWithPreview
                    className={styles.section_image}
                    label1="Image URL"
                    label2="Image Size (%):"
                    label3="No Download:"
                    name1="section_1_images_1"
                    name2="section_1_images_1_size"
                    name3="section_1_images_1_blocked"
                    placeholder="30"
                    max="100"
                    value1={formState.section_1_images_1}
                    value2={formState.section_1_images_1_size}
                    value3={formState.section_1_images_1_blocked}
                    onChange={handleChange}
                    onError={handleBrokenImage}
                  />
                  <InputWithPreview
                    className={styles.section_image}
                    label1="Image URL"
                    label2="Image Size (%):"
                    label3="No Download:"
                    name1="section_1_images_2"
                    name2="section_1_images_2_size"
                    name3="section_1_images_2_blocked"
                    placeholder="30"
                    max="100"
                    value1={formState.section_1_images_2}
                    value2={formState.section_1_images_2_size}
                    value3={formState.section_1_images_2_blocked}
                    onChange={handleChange}
                    onError={handleBrokenImage}
                  />
                  <InputWithPreview
                    className={styles.section_image}
                    label1="Image URL"
                    label2="Image Size (%):"
                    label3="No Download:"
                    name1="section_1_images_3"
                    name2="section_1_images_3_size"
                    name3="section_1_images_3_blocked"
                    placeholder="30"
                    max="100"
                    value1={formState.section_1_images_3}
                    value2={formState.section_1_images_3_size}
                    value3={formState.section_1_images_3_blocked}
                    onChange={handleChange}
                    onError={handleBrokenImage}
                  />
                </div>
                <Input
                  className={styles.text_input}
                  type="textarea"
                  rows={9}
                  label="Content:"
                  name="section_1_content"
                  value={formState.section_1_content}
                  onChange={handleChange}
                />
                <Divider />
                <div>
                  <label>
                    <p>SECTION-02</p>
                  </label>
                </div>
                <div className={styles.section_images}>
                  <InputWithPreview
                    className={styles.section_image}
                    label1="Image URL"
                    label2="Image Size (%):"
                    label3="No Download:"
                    name1="section_2_images_1"
                    name2="section_2_images_1_size"
                    name3="section_2_images_1_blocked"
                    placeholder="30"
                    max="100"
                    value1={formState.section_2_images_1}
                    value2={formState.section_2_images_1_size}
                    value3={formState.section_2_images_1_blocked}
                    onChange={handleChange}
                    onError={handleBrokenImage}
                  />
                  <InputWithPreview
                    className={styles.section_image}
                    label1="Image URL"
                    label2="Image Size (%):"
                    label3="No Download:"
                    name1="section_2_images_2"
                    name2="section_2_images_2_size"
                    name3="section_2_images_2_blocked"
                    placeholder="30"
                    max="100"
                    value1={formState.section_2_images_2}
                    value2={formState.section_2_images_2_size}
                    value3={formState.section_2_images_2_blocked}
                    onChange={handleChange}
                    onError={handleBrokenImage}
                  />
                  <InputWithPreview
                    className={styles.section_image}
                    label1="Image URL"
                    label2="Image Size (%):"
                    label3="No Download:"
                    name1="section_2_images_3"
                    name2="section_2_images_3_size"
                    name3="section_2_images_3_blocked"
                    placeholder="30"
                    max="100"
                    value1={formState.section_2_images_3}
                    value2={formState.section_2_images_3_size}
                    value3={formState.section_2_images_3_blocked}
                    onChange={handleChange}
                    onError={handleBrokenImage}
                  />
                </div>
                <Input
                  className={styles.text_input}
                  type="textarea"
                  rows={9}
                  label="Content:"
                  name="section_2_content"
                  value={formState.section_2_content}
                  onChange={handleChange}
                />
                <Divider />
                <div>
                  <label>
                    <p>ATTACHMENT-01</p>
                  </label>
                </div>
                <Input
                  className={styles.text_input}
                  type="textarea"
                  label="Attachment Link:"
                  name="attachment_link_1"
                  value={formState.attachment_link_1}
                  onChange={handleChange}
                />
                <Dropdown
                  className={styles.text_input}
                  label="Attachment Type:"
                  name="attachment_type_1"
                  onChange={handleChange}
                  value={formState.attachment_type_1}
                  required={formState.attachment_link_1 !== ""}
                  extraChildren={
                    <div className={styles.radio_input}>
                      <div className={styles.single_radio}>
                        <label htmlFor="attachment_1_blocked">
                          No Download:
                        </label>
                        <input
                          type="radio"
                          id="attachment_1_blocked"
                          name="attachment_1_blocked"
                          value={true as any}
                          checked={formState.attachment_1_blocked === "true"}
                          onChange={handleChange}
                          onClick={handleChange}
                        />
                      </div>
                    </div>
                  }
                >
                  <option value="" disabled>
                    Select Attachment Type
                  </option>
                  <option value="HTML" key="html">
                    EMBED HTML
                  </option>
                  <option value="DOCX" key="docx">
                    DOCX
                  </option>
                  <option value="JPG" key="jpg">
                    JPG
                  </option>
                  <option value="PNG" key="png">
                    PNG
                  </option>
                  <option value="MP3" key="mp3">
                    MP3
                  </option>
                  <option value="MP4" key="mp4">
                    MP4
                  </option>
                  <option value="PDF" key="pdf">
                    PDF
                  </option>
                  <option value="APK" key="apk">
                    APK
                  </option>
                  <option value="GLB" key="glb">
                    GLB
                  </option>
                  <option value="ZIP" key="zip">
                    ZIP
                  </option>
                </Dropdown>
                <p className={styles.attachment_note}>
                  Supported URL formats: HTML, DOCX, JPG, PNG, MP3, MP4, PDF,
                  APK, GLB, ZIP
                </p>
                <Divider />
                <div>
                  <label>
                    <p>SECTION-03</p>
                  </label>
                </div>
                <div className={styles.section_images}>
                  <InputWithPreview
                    className={styles.section_image}
                    label1="Image URL"
                    label2="Image Size (%):"
                    label3="No Download:"
                    name1="section_3_images_1"
                    name2="section_3_images_1_size"
                    name3="section_3_images_1_blocked"
                    placeholder="30"
                    max="100"
                    value1={formState.section_3_images_1}
                    value2={formState.section_3_images_1_size}
                    value3={formState.section_3_images_1_blocked}
                    onChange={handleChange}
                    onError={handleBrokenImage}
                  />
                  <InputWithPreview
                    className={styles.section_image}
                    label1="Image URL"
                    label2="Image Size (%):"
                    label3="No Download:"
                    name1="section_3_images_2"
                    name2="section_3_images_2_size"
                    name3="section_3_images_2_blocked"
                    placeholder="30"
                    max="100"
                    value1={formState.section_3_images_2}
                    value2={formState.section_3_images_2_size}
                    value3={formState.section_3_images_2_blocked}
                    onChange={handleChange}
                    onError={handleBrokenImage}
                  />
                  <InputWithPreview
                    className={styles.section_image}
                    label1="Image URL"
                    label2="Image Size (%):"
                    label3="No Download:"
                    name1="section_3_images_3"
                    name2="section_3_images_3_size"
                    name3="section_3_images_3_blocked"
                    placeholder="30"
                    max="100"
                    value1={formState.section_3_images_3}
                    value2={formState.section_3_images_3_size}
                    value3={formState.section_3_images_3_blocked}
                    onChange={handleChange}
                    onError={handleBrokenImage}
                  />
                </div>
                <Input
                  className={styles.text_input}
                  type="textarea"
                  rows={9}
                  label="Content:"
                  name="section_3_content"
                  value={formState.section_3_content}
                  onChange={handleChange}
                />
                <Divider />
                <div>
                  <label>
                    <p>SECTION-04</p>
                  </label>
                </div>
                <div className={styles.section_images}>
                  <InputWithPreview
                    className={styles.section_image}
                    label1="Image URL"
                    label2="Image Size (%):"
                    label3="No Download:"
                    name1="section_4_images_1"
                    name2="section_4_images_1_size"
                    name3="section_4_images_1_blocked"
                    placeholder="30"
                    max="100"
                    value1={formState.section_4_images_1}
                    value2={formState.section_4_images_1_size}
                    value3={formState.section_4_images_1_blocked}
                    onChange={handleChange}
                    onError={handleBrokenImage}
                  />
                  <InputWithPreview
                    className={styles.section_image}
                    label1="Image URL"
                    label2="Image Size (%):"
                    label3="No Download:"
                    name1="section_4_images_2"
                    name2="section_4_images_2_size"
                    name3="section_4_images_2_blocked"
                    placeholder="30"
                    max="100"
                    value1={formState.section_4_images_2}
                    value2={formState.section_4_images_2_size}
                    value3={formState.section_4_images_2_blocked}
                    onChange={handleChange}
                    onError={handleBrokenImage}
                  />
                  <InputWithPreview
                    className={styles.section_image}
                    label1="Image URL"
                    label2="Image Size (%):"
                    label3="No Download:"
                    name1="section_4_images_3"
                    name2="section_4_images_3_size"
                    name3="section_4_images_3_blocked"
                    placeholder="30"
                    max="100"
                    value1={formState.section_4_images_3}
                    value2={formState.section_4_images_3_size}
                    value3={formState.section_4_images_3_blocked}
                    onChange={handleChange}
                    onError={handleBrokenImage}
                  />
                </div>
                <Input
                  className={styles.text_input}
                  type="textarea"
                  rows={9}
                  label="Content:"
                  name="section_4_content"
                  value={formState.section_4_content}
                  onChange={handleChange}
                />
                <Divider />
                <div>
                  <label>
                    <p>ATTACHMENT-02</p>
                  </label>
                </div>
                <Input
                  className={styles.text_input}
                  type="textarea"
                  label="Attachment Link:"
                  name="attachment_link_2"
                  value={formState.attachment_link_2}
                  onChange={handleChange}
                />
                <Dropdown
                  className={styles.text_input}
                  label="Attachment Type:"
                  name="attachment_type_2"
                  onChange={handleChange}
                  value={formState.attachment_type_2}
                  required={formState.attachment_link_2 !== ""}
                  extraChildren={
                    <div className={styles.radio_input}>
                      <div className={styles.single_radio}>
                        <label htmlFor="attachment_2_blocked">
                          No Download:
                        </label>
                        <input
                          type="radio"
                          id="attachment_2_blocked"
                          name="attachment_2_blocked"
                          value={true as any}
                          checked={formState.attachment_2_blocked === "true"}
                          onChange={handleChange}
                          onClick={handleChange}
                        />
                      </div>
                    </div>
                  }
                >
                  <option value="" disabled>
                    Select Attachment Type
                  </option>
                  <option value="HTML" key="html">
                    EMBED HTML
                  </option>
                  <option value="DOCX" key="docx">
                    DOCX
                  </option>
                  <option value="JPG" key="jpg">
                    JPG
                  </option>
                  <option value="PNG" key="png">
                    PNG
                  </option>
                  <option value="MP3" key="mp3">
                    MP3
                  </option>
                  <option value="MP4" key="mp4">
                    MP4
                  </option>
                  <option value="PDF" key="pdf">
                    PDF
                  </option>
                  <option value="APK" key="apk">
                    APK
                  </option>
                  <option value="GLB" key="glb">
                    GLB
                  </option>
                  <option value="ZIP" key="zip">
                    ZIP
                  </option>
                </Dropdown>
                <p className={styles.attachment_note}>
                  Supported URL formats: HTML, DOCX, JPG, PNG, MP3, MP4, PDF,
                  APK, GLB, ZIP
                </p>
                <Divider />
                <div>
                  <label>
                    <p>SECTION-05</p>
                  </label>
                </div>
                <div className={styles.section_images}>
                  <InputWithPreview
                    className={styles.section_image}
                    label1="Image URL"
                    label2="Image Size (%):"
                    label3="No Download:"
                    name1="section_5_images_1"
                    name2="section_5_images_1_size"
                    name3="section_1_images_1_blocked"
                    placeholder="30"
                    max="100"
                    value1={formState.section_5_images_1}
                    value2={formState.section_5_images_1_size}
                    value3={formState.section_5_images_1_blocked}
                    onChange={handleChange}
                    onError={handleBrokenImage}
                  />
                  <InputWithPreview
                    className={styles.section_image}
                    label1="Image URL"
                    label2="Image Size (%):"
                    label3="No Download:"
                    name1="section_5_images_2"
                    name2="section_5_images_2_size"
                    name3="section_5_images_2_blocked"
                    placeholder="30"
                    max="100"
                    value1={formState.section_5_images_2}
                    value2={formState.section_5_images_2_size}
                    value3={formState.section_5_images_2_blocked}
                    onChange={handleChange}
                    onError={handleBrokenImage}
                  />
                  <InputWithPreview
                    className={styles.section_image}
                    label1="Image URL"
                    label2="Image Size (%):"
                    label3="No Download:"
                    name1="section_5_images_3"
                    name2="section_5_images_3_size"
                    name3="section_5_images_3_blocked"
                    placeholder="30"
                    max="100"
                    value1={formState.section_5_images_3}
                    value2={formState.section_5_images_3_size}
                    value3={formState.section_5_images_3_blocked}
                    onChange={handleChange}
                    onError={handleBrokenImage}
                  />
                </div>
                <Input
                  className={styles.text_input}
                  type="textarea"
                  rows={9}
                  label="Content:"
                  name="section_5_content"
                  value={formState.section_5_content}
                  onChange={handleChange}
                />
                <Divider />
                <div>
                  <label>
                    <p>SECTION-06</p>
                  </label>
                </div>
                <div className={styles.section_images}>
                  <InputWithPreview
                    className={styles.section_image}
                    label1="Image URL"
                    label2="Image Size (%):"
                    label3="No Download:"
                    name1="section_6_images_1"
                    name2="section_6_images_1_size"
                    name3="section_6_images_1_blocked"
                    placeholder="30"
                    max="100"
                    value1={formState.section_6_images_1}
                    value2={formState.section_6_images_1_size}
                    value3={formState.section_6_images_1_blocked}
                    onChange={handleChange}
                    onError={handleBrokenImage}
                  />
                  <InputWithPreview
                    className={styles.section_image}
                    label1="Image URL"
                    label2="Image Size (%):"
                    label3="No Download:"
                    name1="section_6_images_2"
                    name2="section_6_images_2_size"
                    name3="section_6_images_2_blocked"
                    placeholder="30"
                    max="100"
                    value1={formState.section_6_images_2}
                    value2={formState.section_6_images_2_size}
                    value3={formState.section_6_images_2_blocked}
                    onChange={handleChange}
                    onError={handleBrokenImage}
                  />
                  <InputWithPreview
                    className={styles.section_image}
                    label1="Image URL"
                    label2="Image Size (%):"
                    label3="No Download:"
                    name1="section_6_images_3"
                    name2="section_6_images_3_size"
                    name3="section_6_images_3_blocked"
                    placeholder="30"
                    max="100"
                    value1={formState.section_6_images_3}
                    value2={formState.section_6_images_3_size}
                    value3={formState.section_6_images_3_blocked}
                    onChange={handleChange}
                    onError={handleBrokenImage}
                  />
                </div>
                <Input
                  className={styles.text_input}
                  type="textarea"
                  rows={9}
                  label="Content:"
                  name="section_6_content"
                  value={formState.section_6_content}
                  onChange={handleChange}
                />
                <Divider />
                <div>
                  <label>
                    <p>ATTACHMENT-03</p>
                  </label>
                </div>
                <Input
                  className={styles.text_input}
                  type="textarea"
                  label="Attachment Link:"
                  name="attachment_link_3"
                  value={formState.attachment_link_3}
                  onChange={handleChange}
                />
                <Dropdown
                  className={styles.text_input}
                  label="Attachment Type:"
                  name="attachment_type_3"
                  onChange={handleChange}
                  value={formState.attachment_type_3}
                  required={formState.attachment_link_3 !== ""}
                  extraChildren={
                    <div className={styles.radio_input}>
                      <div className={styles.single_radio}>
                        <label htmlFor="attachment_3_blocked">
                          No Download:
                        </label>
                        <input
                          type="radio"
                          id="attachment_3_blocked"
                          name="attachment_3_blocked"
                          value={true as any}
                          checked={formState.attachment_3_blocked === "true"}
                          onChange={handleChange}
                          onClick={handleChange}
                        />
                      </div>
                    </div>
                  }
                >
                  <option value="" disabled>
                    Select Attachment Type
                  </option>
                  <option value="HTML" key="html">
                    EMBED HTML
                  </option>
                  <option value="DOCX" key="docx">
                    DOCX
                  </option>
                  <option value="JPG" key="jpg">
                    JPG
                  </option>
                  <option value="PNG" key="png">
                    PNG
                  </option>
                  <option value="MP3" key="mp3">
                    MP3
                  </option>
                  <option value="MP4" key="mp4">
                    MP4
                  </option>
                  <option value="PDF" key="pdf">
                    PDF
                  </option>
                  <option value="APK" key="apk">
                    APK
                  </option>
                  <option value="GLB" key="glb">
                    GLB
                  </option>
                  <option value="ZIP" key="zip">
                    ZIP
                  </option>
                </Dropdown>
                <p className={styles.attachment_note}>
                  Supported URL formats: HTML, DOCX, JPG, PNG, MP3, MP4, PDF,
                  APK, GLB, ZIP
                </p>
                <div className={styles.buttons}>
                  <Button
                    onClick={(e: React.FormEvent<HTMLFormElement>) =>
                      handleSubmit(e)
                    }
                    type="submit"
                    loading={isSavingSite}
                    className={styles.button}
                  >
                    <SaveIcon />
                    <span>SAVE</span>
                  </Button>
                  <Button
                    onClick={handlePreview}
                    loading={isLoading}
                    className={styles.button}
                  >
                    <HollowBoxIcon />
                    <span>PREVIEW</span>
                    <p>(Please save first)</p>
                  </Button>
                  <Button
                    onClick={(e: React.FormEvent<HTMLFormElement>) =>
                      handleSubmit(e, "publish")
                    }
                    type="submit"
                    loading={isSavingSite}
                    className={styles.button}
                  >
                    <PublishIcon />
                    <span>PUBLISH</span>
                  </Button>
                  <Button
                    onClick={(e: React.FormEvent<HTMLFormElement>) =>
                      handleSubmit(e, "unpublish")
                    }
                    loading={isSavingSite}
                    className={styles.button}
                  >
                    <UnpublishIcon />
                    <span>UNPUBLISH</span>
                  </Button>
                </div>
              </>
            )}
          </FormWrapper>
        </div>
      </div>
    </section>
  );
}
